import Dashboard from '../pages/Dashboard/Dashboard';
import Profile from '../pages/Profile';
import SchoolPage from '../pages/schools/School';
import SchoolAnalytics from '../pages/schools/schools-blog/analytics/SchoolAnalytics';
import BlogView from '../pages/schools/schools-blog/blogs/BlogView';
import CreateBlog from '../pages/schools/schools-blog/blogs/CreateBlog';
import SchoolDetailView from '../pages/schools/schools-blog/school-list/school-view/SchoolBlogView';
import Users from '../pages/users/Users';
import Newsletter from '../pages/newsletter/Newsletter';
import CreateNewsLetter from '../pages/newsletter/CreateNewsLetter/CreateNewsLetter';
import LivePage from '../pages/LivePage';
import CreateLiveSessionPage from '../pages/LivePage/CreateLiveSessionPage';
import LiveDetailsView from '../pages/LivePage/LiveDetailsView';
import CreateSessionPage from '../pages/LivePage/CreateSessionPage';
import BroadcastPage from '../pages/BroadcastPage';
import BroadCastMessages from '../pages/BroadcastPage/BroadCastMessages';
import AllUser from '../components/AllUser';
import CreateBroadcastMessage from '../pages/BroadcastPage/CreateBroadcastMessage';

const coreRoutes = [
  // {
  //   path: '/dashboard',
  //   title: 'Dashboard',
  //   component: Dashboard,
  // },
  {
    path: 'school',
    title: 'SchoolPage',
    component: SchoolPage,
  },
  {
    path: '/broadcast',
    title: 'BroadcastPage',
    component: BroadcastPage,
  },
  {
    path: '/broadcast/create-message',
    title: 'BroadcastPage',
    component: CreateBroadcastMessage,
  },
  {
    path: '/broadcast/edit-message/:campaignId',
    title: 'Edit BroadcastPage',
    component: CreateBroadcastMessage,
  },
  {
    path: '/broadcast/messages/:campaignId',
    title: 'Broadcast Messages',
    component: BroadCastMessages,
  },

  {
    path: 'school/schoolblog-view/:id',
    title: 'SchoolBlogView',
    component: SchoolDetailView,
  },
  {
    path: 'school/schoolblog-view/:id/create-blogs/:optionalid?',
    title: 'CreateBlog',
    component: CreateBlog,
  },
  {
    path: 'school/schoolblog-view/:id/blogsview/:optionalid?',
    title: 'blogsview',
    component: BlogView,
  },

  {
    path: 'school/schoolblog-view/:id/analytics',
    title: 'SchoolAnalytics',
    component: SchoolAnalytics,
  },
  {
    path: '/newsLetters',
    title: 'NewsLetter',
    component: Newsletter,
  },
  {
    path: '/newsLetters/create',
    title: 'CreateNewsLetter',
    component: CreateNewsLetter,
  },

  {
    path: '/live-session',
    title: 'Live Session',
    component: LivePage,
  },
  {
    path: '/users',
    title: 'Users',
    component: AllUser,
  },

  {
    path: '/live-session/:id',
    title: 'Live Session Details',
    component: LiveDetailsView,
  },
  {
    path: '/create-session/:id',
    title: 'Create Session',
    component: CreateSessionPage,
  },
  {
    path: '/create-session/:id/:sessionId',
    title: 'Edit Session',
    component: CreateSessionPage,
  },
  {
    path: '/live-session/edit-session/:id',
    title: 'Edit Live Session',
    component: CreateLiveSessionPage,
  },
  {
    path: '/live-session/create-session',
    title: 'Create Live Session',
    component: CreateLiveSessionPage,
  },

  {
    path: '/profile',
    title: 'Profile',
    component: Profile,
  },

  {
    path: 'users',
    title: 'all-users',
    component: Users,
  },

  //   {
  //     path: "/rankings/product-views/:id",
  //   title: 'product-views',
  //   component: ProductsView,
  // },
];

const routes = [...coreRoutes];
export default routes;
