import { formateErrorObject } from '../components/basic/formateError';
import { Endpoints } from '../services/httpendpoints';
import apiClient from '../services/httpservices';

export const getLoginApiCall = async (data: any): Promise<any> => {
  const response = await apiClient().post(`${Endpoints.userLogin}`, data);
  return response;
};

export const getAbilitiesApiCall = async () => {
  const response = await apiClient().get(`${Endpoints.abilities}`);
  return response;
};

export const appWriteUser = async (zl_uid: string) => {
  try {
    const response = await apiClient().post(Endpoints.appWriteUser, { zl_uid });
    return response?.data?.data;
  } catch (error: any) {
    const message = formateErrorObject(error);

    console.log(message);
  }
};

export const getUserSignUp = async (data: {
  name: string;
  email: string;
  password: string;
}): Promise<any> => {
  const response = await apiClient().post(`${Endpoints.userSignUp}`, data);
  return response;
};

export const forgotPasswordSendEmail = async ({ email }: { email: string }) => {
  const response = await apiClient().post(
    `${Endpoints.forgotPasswordSendEmail}`,
    { email }
  );

  return response?.data;
};

export const forgotPasswordCheckOtp = async ({ otp }: { otp: number }) => {
  const response = await apiClient().post(
    `${Endpoints.forgotPasswordCheckOtp}`,
    {
      verification_code: otp,
    }
  );

  return response?.data;
};

export const forgotPasswordResetPassword = async ({
  verification_code,
  password,
}: {
  verification_code: number;
  password: string;
}) => {
  const response = await apiClient().post(
    `${Endpoints.forgotPasswordResetPassword}`,
    {
      verification_code,
      password,
    }
  );

  return response?.data;
};
