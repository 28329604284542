/* eslint-disable react-hooks/exhaustive-deps */
import { XIcon } from '@heroicons/react/outline';
import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { handleSeo, handleSeoBlogs } from '../../../../services/apiservices';
import { ShouldRender } from '../../../basic/ShouldRender';
import { formateErrorObject } from '../../../basic/formateError';
import { notify } from '../../../basic/notity';
import { useRecoilState } from 'recoil';
import { triggerChange } from '../../../../atoms/triggerChange/triggerChange';

interface Props {
  visible: boolean;
  closeModal: () => void;
  id: number;
  isPost?: boolean;
  seoData: {
    title: string;
    meta_description: string;
    keywords: string;
    author?: string;
    slug: string;
    canonical?: string;
  };
}

const SeoModal: React.FC<Props> = ({
  closeModal,
  visible,
  id,
  isPost,
  seoData,
}) => {
  const [form] = Form.useForm();
  const [triggerChanges, setTriggerChange] = useRecoilState(triggerChange);
  const [formData, setFormData] = useState<{
    title: string;
    meta_description: string;
    keywords: string;
    author?: string;
    slug: string;
    canonical?: string;
  }>({
    title: '',
    meta_description: '',
    keywords: '',
    author: '',
    slug: '',
    canonical: '',
  });

  const onFinish = async () => {
    try {
      const isSlugMatch = formData.slug === seoData.slug;

      if (isPost) {
        if (isSlugMatch) formData.slug = '';
        await handleSeoBlogs(formData, id);
      } else {
        if (isSlugMatch) formData.slug = '';
        await handleSeo(formData, id);
      }
      setTriggerChange(!triggerChanges);
      handleClose();
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const handleClose = () => {
    setFormData({
      title: '',
      meta_description: '',
      keywords: '',
      author: '',
      slug: '',
      canonical: '',
    });
    form.resetFields();
    closeModal();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      onFinish();
    } catch (error) {
      console.error('Validation error:', error);
    }
  };

  const handleInputChange = (name: string, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData(seoData);
  }, [seoData]);

  // const handleKeyWords = useCallback(
  //   (fieldName: string) =>
  //     (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //       const { value } = e.target;
  //       const cleanedValue = value.replace(/\s+/g, ',');
  //       setFormData({ ...formData, [fieldName]: cleanedValue });
  //     },
  //   [formData]
  // );

  return (
    <Modal
      visible={visible}
      title={
        <div className="flex justify-between pb-2 border-b border-[#a8a6a6]">
          <span>SEO</span>{' '}
          <div className="flex justify-end">
            <XIcon
              onClick={() => handleClose()}
              className="w-5 h-5 text-gray-800 hover:text-gray-600 cursor-pointer"
            />
          </div>
        </div>
      }
      maskClosable={false}
      closable={false}
      footer={null}
      onCancel={handleClose}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={formData}
        className="flex flex-col gap-y-2"
      >
        <ShouldRender check={!isPost}>
          <Form.Item
            label={<span className="font-medium">Author Name</span>}
            name="author"
            labelAlign="left"
            // rules={[{ required: true, message: 'Please enter the Author Name' }]}
          >
            <Input
              className=""
              placeholder="Please provide Author Name"
              size="large"
              value={formData.author}
              onChange={(e) => handleInputChange('author', e.target.value)}
            />
          </Form.Item>
        </ShouldRender>

        <Form.Item
          label={
            <span className="font-medium flex gap-x-1 items-center">
              Meta Title{' '}
            </span>
          }
          name="title"
          labelAlign="left"
        >
          <Input
            className=""
            showCount
            placeholder="Please provide meta title"
            size="large"
            value={formData.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="font-medium flex gap-x-1 items-center">
              Meta Descriptions{' '}
            </span>
          }
          name="meta_description"
        >
          <Input.TextArea
            showCount
            placeholder="Please provide meta description"
            className="text-base focus:border-primary hover:border-primary"
            value={formData.meta_description}
            onChange={(e) =>
              handleInputChange('meta_description', e.target.value)
            }
          />
        </Form.Item>
        <Form.Item
          label={<span className="font-medium">Keywords</span>}
          name="keywords"
          // rules={[{ required: true, message: 'Please enter the keywords' }]}
        >
          <Input
            placeholder="Please provide keywords"
            size="large"
            className="text-base focus:border-primary hover:border-primary"
            value={formData.keywords}
            onChange={(e) => handleInputChange('keywords', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={<span className="font-medium">Slug</span>}
          name="slug"
          // rules={[{ required: true, message: 'Please enter the keywords' }]}
        >
          <Input
            placeholder="Please provide slug"
            size="large"
            className="text-base focus:border-primary hover:border-primary"
            value={formData.slug}
            onChange={(e) => handleInputChange('slug', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={<span className="font-medium">Canonical Url</span>}
          name="canonical"
        >
          <Input
            placeholder="Please provide canonical url"
            size="large"
            className="text-base focus:border-primary hover:border-primary"
            value={formData.canonical}
            onChange={(e) => handleInputChange('canonical', e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <span className="flex justify-end">
            <Button onClick={handleClose} className="w-min p-1 border-primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="primary"
              className="w-min p-1 text-white hover:text-white "
            >
              Submit
            </Button>
          </span>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SeoModal;
