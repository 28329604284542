import { ICondition } from '../utills/interfaces/BrodcastMessages';
import { Endpoints } from './httpendpoints';
import apiClient from './httpservices';

interface ICreateCampaign {
  author_id: number;
  title: string;
}

export interface ICreateCampaignBody {
  message_title?: string;
  message_body?: string;
  scheduled_at?: string;
  send_now?: boolean;
}

export const getAllCampaignsApi = async (): Promise<any> => {
  const response = await apiClient().get(`${Endpoints.getCampaigns}`);
  return response?.data?.data;
};

export const getSingleCampaignsApi = async (id: string): Promise<any> => {
  const response = await apiClient().get(`${Endpoints.getCampaigns}/${id}`);
  return response?.data?.data;
};

export const createCampaignsApi = async (
  data: ICreateCampaign
): Promise<any> => {
  const response = await apiClient().post(`${Endpoints.getCampaigns}`, data);
  return response?.data?.data;
};

export const updateCampaignsApi = async (
  id: number,
  data: ICreateCampaignBody
): Promise<any> => {
  const response = await apiClient('application/json').patch(
    `${Endpoints.getCampaigns}/${id}`,
    data
  );
  return response?.data?.data;
};

export const updateCampaignsConditionsApi = async (
  id: number,
  data: ICondition[]
): Promise<any> => {
  const transformedData = data.map((condition) => ({
    source: condition.source,
    source_type: condition.product_type,
    source_id: condition.product_id,
    event: condition.event,
    operator: condition.operator,
    value: condition.value,
  }));

  const response = await apiClient('application/json').post(
    `${Endpoints.getCampaignConditions}/${id}`,
    { conditions: transformedData }
  );

  return response?.data?.data;
};
export const handleAssignRandomUser = async (id: number): Promise<any> => {
  const response = await apiClient().post(`${Endpoints.randomUser}/${id}`);
  return response?.data?.data;
};

export const handleCampaignsUsers = async (id: string): Promise<any> => {
  const response = await apiClient().get(
    `${Endpoints.getCampaignsUsers}/${id}`
  );
  return response?.data?.data;
};

export const handleCampaignsUsersThreads = async (
  campaignId: string,
  userId: number
): Promise<any> => {
  const response = await apiClient().get(
    `${Endpoints.getCampaignsUsersThreads}/${campaignId}/${userId}`
  );
  return response?.data?.data;
};
