export enum Source {
  ECOMMERCE = 'ecommerce',
  LMS = 'courses',
  SCHOOLS = 'schools',
  SHORT_BOOKS = 'books',
}

export enum ProductType {
  SHORT_BOOK = 'short_book',
  BOOK = 'products',
  SCHOOL = 'school',
  COURSES = 'courses',
}

export type CategoryKey = keyof typeof CategoriesType;

export const CategoriesType = {
  longBook: 'Long Book',
  course: 'Course',
  shortBook: 'Short Book',
  schools: 'Schools',
} as const;

export enum EventType {
  PURCHASED = 'purchased',
  VIEWED = 'viewed',
}

export interface ICondition {
  source: string;
  product_type: string;
  product_id: string;
  event: string;
  operator: string;
  value: string;
}
