import { Config } from '../config/config';

export const Endpoints = {
  userSignUp: `${Config.BASE_API_URL}/register`,

  userLogin: `${Config.BASE_API_URL}/login`,

  forgotPasswordSendEmail: `${Config.BASE_API_URL}/password/forget_request`,

  forgotPasswordCheckOtp: `${Config.BASE_API_URL}/password/confirm_otp`,

  forgotPasswordResetPassword: `${Config.BASE_API_URL}/password/confirm_otp`,

  abilities: `${Config.BASE_API_URL}/abilities`,

  createUserApi: `${Config.BASE_API_URL}/users`,

  createSchool: `${Config.BASE_API_URL}/schools/self`,

  school: `${Config.BASE_API_URL}/schools`,

  getSchoolData: `${Config.BASE_API_URL}/schools/self`,

  getAllAuthors: `${Config.BASE_API_URL}/authors`,

  getCategories: `${Config.BASE_API_URL}/categories`,

  getSchoolDetailsById: `${Config.BASE_API_URL}/schools/id`,

  getSchoolImages: `${Config.BASE_API_URL}/schools/document`,

  getSchoolImagesByS3Bucket: `${process.env.REACT_APP_URL_IMAGE_BASE_URL}`,

  getUser: `${Config.BASE_API_URL}/user`,

  blogs: `${Config.BASE_API_URL}/posts`,

  getPostBySchoolId: `${Config.BASE_API_URL}/schools/posts`,

  getMyPostBySchoolId: `${Config.BASE_API_URL}/schools/my-blogs`,

  restorePost: `${Config.BASE_API_URL}/posts/restore`,

  monetizationBlogs: `${Config.BASE_API_URL}/school-advertisements`,

  updateMonetizationBlogs: `${Config.BASE_API_URL}/school-advertisements/update`,

  uploadImage: `${Config.BASE_API_URL}/posts/img/upload`,

  // Live

  getAllWorkShops: `${Config.BASE_API_URL}/live-work-shops`,

  getSingleWorkShops: `${Config.BASE_API_URL}/live-work-shops`,

  updateWorkShopStatus: `${Config.BASE_API_URL}/live-work-shop-status-update`,

  updateWorkShop: `${Config.BASE_API_URL}/live-work-shops`,

  addWorkShop: `${Config.BASE_API_URL}/live-work-shops`,

  createSession: `${Config.BASE_API_URL}/live-work-shop-sessions`,

  createSessionAdvertisements: `${Config.BASE_API_URL}/advertisements`,

  assignAdvertisements: `${Config.BASE_API_URL}/module-advertisements`,

  syncSession: `${Config.BASE_API_URL}/sync-statistics`,

  // Campaigns
  getCampaigns: `${Config.BASE_API_URL}/chat-campaigns`,

  getCampaignConditions: `${Config.BASE_API_URL}/chat-campaigns-conditions`,

  randomUser: `${Config.BASE_API_URL}/chat-campaigns-assign-random-users`,

  getCampaignsUsers: `${Config.BASE_API_URL}/chat-campaigns/users`,

  getCampaignsUsersThreads: `${Config.BASE_API_URL}/chat-campaigns/thread`,

  users: `${Config.BASE_API_URL}/users`,
  allUsers: `${Config.BASE_API_URL}/users`,

  roles: `${Config.BASE_API_URL}/roles`,
  updateUser: `${Config.BASE_API_URL}/users`,
  updateUserRole: `${Config.BASE_API_URL}/users/update_role`,

  // AppWrite Api
  appWriteUser: `${Config.BASE_API_URL}/appwrite-user`,

  // courses
  getAllCourses: '/api/v2/v2/get/all-courses',

  // Readers
  getAllBooks: `api/v2/books`,
  getAllShortBooks: `api/v2/books/shorts`,
  getCoverImagesForBooks: `api/v2/books/cover-file`,
};
