import { AxiosError, AxiosResponse } from 'axios';
import { AppwriteException } from 'appwrite';

export interface MyAxiosError<T = any> extends AxiosError<T> {
  response?: AxiosResponse<T> | undefined;
}

export const formateErrorObject = (error: MyAxiosError): string => {
  return error?.response?.data?.message;
};
export const formateErrorAppwriteObject = (error: AppwriteException) => {
  return error?.message;
};
