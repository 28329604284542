import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import {
  AYE_ICON,
  DOLLAR_ICON,
  LINK_ICON,
  TIMER_ICON,
  UP_ARROW_ICON,
} from '../../../../assets';
import { useNavigate } from 'react-router-dom';

const SchoolAnalytics = () => {
  const navigate = useNavigate();
  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    console.log(value);
  };

  return (
    <div className="flex flex-col gap-4 pl-9  pt-8">
      <div className="flex flex-row gap-4">
        <div>
          <ArrowLeftOutlined
            onClick={() => navigate(`/school/`)}
            className="text-2xl cursor-pointer"
          />
        </div>
        <div className="text-xl">
          <b>Back</b>
        </div>
      </div>
      <div className="rounded-xl flex flex-row justify-between w-full bg-silverBg px-8 py-6">
        <div>ANALYTICS</div>
        <div>
          <Select
            labelInValue
            defaultValue={{ value: 'lucy', label: 'Lucy (101)' }}
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              {
                value: 'month',
                label: 'Month',
              },
              {
                value: 'daily',
                label: 'Daily',
              },
            ]}
          />
        </div>
      </div>
      <div className="grid grid-cols-10 gap-4">
        <div className="col-span-3 ">
          <div className="flex flex-col gap-4 items-center justify-between h-full border rounded-xl bg-silverBg p-6 h-[385px]">
            <div className="rounded-full bg-white w-20 h-20 flex items-center justify-center">
              <img src={DOLLAR_ICON} className="w-14 h-14" alt="" />
            </div>
            <div className="flex flex-row rounded-2xl bg-white py-2 px-4 gap-4">
              <img src={UP_ARROW_ICON} alt="" />
              <b>15%</b>
            </div>
            <div className="flex flex-col gap-2 ">
              <div>
                <b className="text-lg">Revenue </b>
                <span className="text-gray-500"> / month</span>
              </div>
              <b className="text-5xl">$ 3238</b>
            </div>
            <div className="w-full">
              <button
                type="button"
                className="rounded-xl border bg-white w-full py-4 text-xl hover:text-primary hover:border-primary"
              >
                <b>Claim</b>
              </button>
            </div>
          </div>
        </div>
        {/* second card */}
        <div className="col-span-7">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-4">
              <div className="flex flex-col justify-between  border rounded-xl bg-silverBg h-[185px] p-4">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-2">
                    <div>
                      <span className="text-lg">Revenue </span>
                      <span className="text-gray-500"> / month</span>
                    </div>
                    <div>
                      <b className="text-lg">9,84,816 </b>
                    </div>
                  </div>
                  <div className="flex flex-row rounded-2xl bg-white py-2 px-4 gap-4">
                    <img src={UP_ARROW_ICON} alt="" />
                    <b>102%</b>
                  </div>
                </div>
                <div>
                  <div className="rounded-full bg-white w-14 h-14 flex items-center justify-center cursor-pointer">
                    <img src={AYE_ICON} className="w-8 h-8" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <div className=" flex flex-col justify-between  border rounded-xl bg-silverBg h-[185px] p-4">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-2">
                    <div>
                      <span className="text-lg">Revenue </span>
                      <span className="text-gray-500"> / month</span>
                    </div>
                    <div>
                      <b className="text-lg">9,84,816 </b>
                    </div>
                  </div>
                  <div className="flex flex-row rounded-2xl bg-white py-2 px-4 gap-4">
                    <img src={UP_ARROW_ICON} alt="" />
                    <b>102%</b>
                  </div>
                </div>
                <div>
                  <div className="rounded-full bg-white w-14 h-14 flex items-center justify-center cursor-pointer">
                    <img src={AYE_ICON} className="w-8 h-8" alt="" />
                  </div>
                </div>{' '}
              </div>
            </div>
            <div className="col-span-6">
              <div className=" flex flex-col justify-between  border rounded-xl bg-silverBg h-[185px] p-4">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-2">
                    <div>
                      <span className="text-lg">Revenue </span>
                      <span className="text-gray-500"> / month</span>
                    </div>
                    <div>
                      <b className="text-lg">9,84,816 </b>
                    </div>
                  </div>
                  <div className="flex flex-row rounded-2xl bg-white py-2 px-4 gap-4">
                    <img src={UP_ARROW_ICON} alt="" />
                    <b>102%</b>
                  </div>
                </div>
                <div>
                  <div className="rounded-full bg-white w-14 h-14 flex items-center justify-center cursor-pointer">
                    <img src={TIMER_ICON} className="w-8 h-8" alt="" />
                  </div>
                </div>{' '}
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex flex-col justify-between  border rounded-xl bg-silverBg h-[185px] p-4">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-2">
                    <div>
                      <span className="text-lg">Revenue </span>
                      <span className="text-gray-500"> / month</span>
                    </div>
                    <div>
                      <b className="text-lg">9,84,816 </b>
                    </div>
                  </div>
                  <div className="flex flex-row rounded-2xl bg-white py-2 px-4 gap-4">
                    <img src={UP_ARROW_ICON} alt="" />
                    <b>102%</b>
                  </div>
                </div>
                <div>
                  <div className="rounded-full bg-white w-14 h-14 flex items-center justify-center cursor-pointer">
                    <img src={LINK_ICON} className="w-8 h-8" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolAnalytics;
