import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { CheckPermission } from '../../../components/basic/CheckPermission';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, Input, Modal } from 'antd';
import Editor, { Reader } from '../../schools/schools-blog/blogs/Editor';
import { useEffect, useState } from 'react';
import TargetAudienceModal from './TargetAudienceModal';
import { FaCaretDown } from 'react-icons/fa';
import {
  ICreateCampaignBody,
  createCampaignsApi,
  getSingleCampaignsApi,
  updateCampaignsApi,
  updateCampaignsConditionsApi,
} from '../../../services/campaignServices';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserInfoInterface } from '../../../utills/interfaces/interface';
import { user } from '../../../atoms/user';
import { notify } from '../../../components/basic/notity';
import { ShouldRender } from '../../../components/basic/ShouldRender';
import Loader from '../../../components/ui-elements/Loader/Loader';
import { ICondition } from '../../../utills/interfaces/BrodcastMessages';
import { triggerSendMessage } from '../../../atoms/triggerSendMessage';
import dayjs from 'dayjs';
import clsx from 'clsx';

interface FormData {
  title: string;
  BlogData: Reader[];
}

const CreateBroadcastMessage = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [conditions, setConditions] = useState<ICondition[]>([]);
  const [visible, setVisible] = useState({ value: false, label: '' });
  const [loading, setLoading] = useState(false);
  const [scheduleTime, setScheduleTime] = useState<string>();
  const userData = useRecoilValue<UserInfoInterface | undefined>(user);
  const [modalOpen, setModalOpen] = useState(false);
  const [authorId, setAuthorId] = useState<number | undefined>();
  const [trigger, setTrigger] = useRecoilState(triggerSendMessage);

  const [formData, setFormData] = useState<FormData>({
    title: '',
    BlogData: [
      {
        id: 1702372324769,
        type: 'title',
        value: '',
      },
      {
        id: 1702372324762,
        type: 'paragraph',
        value: '',
      },
    ],
  });

  useEffect(() => {
    if (trigger && trigger.label) {
      handleCreateCampaigns(trigger.label);
      setTrigger({
        label: '',
        value: false,
      });
    }
  }, [trigger]);

  const handleCreateCampaigns = async (type?: string) => {
    try {
      setLoading(true);

      const requiredIdsAndValues = [
        [1702372324769, ''],
        [1702372324762, ''],
      ];
      const missingFields = [];

      if (!userData?.id) missingFields.push('Author ID');
      if (!formData?.title) missingFields.push('Title');
      if (
        formData.BlogData &&
        requiredIdsAndValues.some(([id, value]) =>
          formData.BlogData.some(
            (item) => item.id === id && item.value === value
          )
        )
      )
        missingFields.push('Body');

      if (missingFields.length > 0) {
        notify(`Please provide ${missingFields.join(', ')}.`, 'error');
        return;
      }

      const updateData: ICreateCampaignBody = {};

      if (type === 'sendNow') updateData['send_now'] = true;
      else if (type === 'scheduleLater' && scheduleTime)
        updateData['scheduled_at'] = scheduleTime;

      if (!campaignId) {
        const response = await createCampaignsApi({
          author_id: authorId ?? (userData?.id as number),
          title: formData.title,
        });
        await updateCampaignsApi(response?.id, {
          message_body: JSON.stringify(formData?.BlogData),
          message_title: formData?.title as string,
        });

        await updateCampaignsConditionsApi(response?.id, conditions);
        // await handleAssignRandomUser(response?.id);

        if (type) {
          await updateCampaignsApi(response?.id, updateData);
        }
      } else {
        await updateCampaignsApi(Number(campaignId), {
          message_body: JSON.stringify(formData?.BlogData),
          message_title: formData?.title as string,
        });

        if (type) {
          await updateCampaignsApi(Number(campaignId), updateData);
        }
      }

      navigate('/broadcast');
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getSingleCampaigns = async () => {
    try {
      // setLoading(true);
      const response = await getSingleCampaignsApi(campaignId as string);
      // const formattedData: ICampaignData[] = formatResponse(response);
      // setCampaignData(formattedData);
      setFormData({
        ...formData,
        title: response?.message_title,
        BlogData: JSON.parse(response?.message_body),
      });

      if (response?.conditions.length) {
        const formattedConditions = response?.conditions?.map(
          (condition: any) => ({
            source: condition?.source,
            product_type: condition?.source_type,
            product_id: condition?.source_id,
            event: condition?.event,
            operator: condition?.operator,
            value: condition?.value,
          })
        );
        setConditions(formattedConditions);
      }
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (campaignId) getSingleCampaigns();
  }, [campaignId]);

  return (
    <>
      <ShouldRender check={loading}>
        <Loader />
      </ShouldRender>
      <ShouldRender check={!loading}>
        <div className="w-full relative mt-5 pb-10 z-10">
          <div className="border-b pb-4  border-[#f2f0f0] -top-2 z-20 w-full">
            <div className="flex justify-between items-center w-full">
              <span
                onClick={() => {
                  navigate(`/broadcast`);
                }}
                className="font-bold text-base flex gap-x-3 cursor-pointer hover:text-[#595858] items-center"
              >
                <MdOutlineKeyboardArrowLeft className="text-2xl" /> All Message
              </span>
              <span>
                <CheckPermission check="create-chat-campaigns">
                  <button
                    type="button"
                    onClick={() => {
                      handleCreateCampaigns();
                    }}
                    className="rounded-xl  font-bold  bg-[#F6F6F6] hover:bg-[#e9e7e7]  px-6 py-2 text-black mr-4 "
                  >
                    <b className="font-medium text-sm">Save as draft</b>
                  </button>
                  <button
                    type="button"
                    className="rounded-xl  font-bold  bg-[#FFF3F0]   text-primary  mr-4 "
                  >
                    <span className="flex items-center ">
                      <p
                        onClick={() => {
                          setVisible({ value: true, label: 'sendNow' });
                        }}
                        className="font-medium text-sm w-full rounded-l-xl pr-2 hover:bg-[#fcedeb] pl-6  py-2"
                      >
                        Send Now
                      </p>

                      <p
                        onClick={() => {
                          !campaignId && setModalOpen(true);
                        }}
                        className={clsx(
                          campaignId && 'cursor-not-allowed',
                          'font-medium  text-sm border-l hover:bg-[#fcdeda]  rounded-r-xl py-3 h-full pr-4 pl-2  justify-end'
                        )}
                      >
                        <FaCaretDown />
                      </p>
                    </span>
                  </button>
                </CheckPermission>
              </span>
            </div>
          </div>
          <div className="px-2 py-6 flex flex-col gap-y-4">
            <span>
              <Input
                size="large"
                value={formData?.title}
                onChange={(e) => {
                  setFormData({ ...formData, title: e.target.value });
                }}
                className="py-4 px-5 rounded-xl placeholder:text-sm text-sm"
                placeholder="Broadcast Subject (only visible to you)"
              />
            </span>

            <span className="flex  justify-center w-full h-full">
              <span className="px-5 w-full border rounded-xl border-borderGrayColor">
                <Editor
                  isMessage={true}
                  setFormData={setFormData}
                  formData={formData}
                />
              </span>
            </span>
          </div>
          <TargetAudienceModal
            visible={visible.value}
            modalLabel={visible.label}
            setAuthorId={setAuthorId}
            setFormData={setConditions}
            formData={conditions}
            closeModal={() => setVisible({ value: false, label: '' })}
          />
        </div>
      </ShouldRender>

      <Modal
        title=""
        open={modalOpen}
        onOk={() => {
          setModalOpen(false);
          setVisible({ value: true, label: 'scheduleLater' });
          setScheduleTime('');
        }}
        onCancel={() => {
          setModalOpen(false);
          setScheduleTime('');
        }}
        closable={false}
        okText="Schedule"
        cancelText="Cancel"
        width={400}
      >
        <div className="flex justify-start flex-col items-center">
          <span className="font-medium underline flex justify-start w-full text-xl text-start">
            Schedule send
          </span>
          <div className="flex items-center gap-x-2 py-5">
            <span className="font-medium text-lg text-[#464c57] flex justify-start w-full  text-start">
              Pick date & time :
            </span>
            <span className=" w-full">
              {' '}
              <DatePicker
                size="large"
                showTime
                value={dayjs(scheduleTime, 'YYYY-MM-DD HH:MM:SS')}
                onChange={(_, dateStrings) => {
                  setScheduleTime(dateStrings as string);
                }}
              />
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateBroadcastMessage;
