export const Config = {
  // BASE_API_URL: (import.meta.env.REACT_APP_BASE_API_URL || "http://localhost:8000") + '/api'

  // BASE_API_URL: (myConfig.BASE_API_URL || "https://analytics.zebralearn.com") + '/api'
  BASE_API_URL:
    (process.env.REACT_APP_BASE_API_URL || 'http://192.168.29.55:8000') +
    '/api/v2',

  BASE_API_URL_COURSES: process.env.REACT_APP_API_LMS_URL,

  BASE_API_URL_READER: process.env.REACT_APP_API_READER,
};
