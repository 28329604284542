import React, { useRef, useState } from 'react';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { Button, ColorPicker, Form, Input, Popconfirm, Popover } from 'antd';

import UploadImage from './UploadImage';
import TextArea from 'antd/es/input/TextArea';
import { ShouldRender } from '../../../../../components/basic/ShouldRender';
import { LuImagePlus } from 'react-icons/lu';
import { FiAlignLeft, FiCheck } from 'react-icons/fi';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import { MdFormatListBulleted } from 'react-icons/md';
import { uploadImage } from '../../../../../services/apiservices';
import { VscItalic } from 'react-icons/vsc';
import { GrUnderline } from 'react-icons/gr';
import { FaBold } from 'react-icons/fa6';
import QuillEditor, { Value } from 'react-quill';
import { BsFiletypeHtml } from 'react-icons/bs';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RxCross2 } from 'react-icons/rx';

export interface Reader {
  id: number;
  type: string;
  value: string | File | null | Value | undefined;
  color?: string;
  underlined?: string;
  italic?: string;
  bold?: string;
  altText?: string;
  link?: string;
}

interface FormData {
  title: string;
  BlogData: Reader[];
}

interface EditorProps {
  formData: FormData;
  isMessage?: boolean;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const Editor: React.FC<EditorProps> = ({
  formData,
  setFormData,
  isMessage = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenInline, setIsOpenInline] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedText, setSelectedText] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedIndexes, setSelectedIndexes] = useState<null | {
    startIndex: number | undefined;
    endIndex: number;
  }>(null);
  const [popoverVisibility, setPopoverVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const textAreaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<any>(null);
  const quillRef = useRef<QuillEditor>(null);

  const [activeField, setActiveField] = useState<{
    id: number;
    value: boolean;
  }>();

  const handleFocusChange = (
    field: { id: number; value: boolean },
    isQuillEditor?: boolean
  ) => {
    setActiveField(field);
    if (isQuillEditor && quillRef.current) {
      quillRef.current.focus();
    }
  };

  const handleInputChange = (
    id: number,
    name: string,
    value: string | File | null
  ) => {
    setFormData((prevFormData) => {
      const newReaders = prevFormData.BlogData.map((reader) =>
        reader.id === id
          ? {
              ...reader,
              [name]:
                reader.type === 'bullet'
                  ? (reader.value as string)?.startsWith('• ')
                    ? value
                    : addBulletPointAtStart(value as string)
                  : value,
            }
          : reader
      );

      return {
        ...prevFormData,
        BlogData: newReaders,
      };
    });
  };
  const addBulletPointAtStart = (inputString: string) => {
    const bulletPoint = '• ';
    return inputString.trim().length > 0
      ? `${bulletPoint}${inputString.trim()}`
      : bulletPoint;
  };

  const handleTextAreaKeyDown = (
    id: number,
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const target = e.target as HTMLTextAreaElement;
      const { selectionStart, selectionEnd, value } = target;

      const start = value.substring(0, selectionStart);
      const end = value.substring(selectionEnd);

      const modifiedValue = addBulletPointOnEnter(start, end);

      handleInputChange(id, 'value', modifiedValue);
    }
  };

  const addBulletPointOnEnter = (start: string, end: string) => {
    const bulletPoint = '• ';
    const resultString = `${start}\n${bulletPoint}${end}`;
    return resultString;
  };

  const addReader = (type: string) => {
    setFormData((prevFormData) => {
      const newReader = {
        id: Date.now(),
        type,
        value: type === 'image' ? null : '',
      };
      return {
        ...prevFormData,
        BlogData: [...prevFormData.BlogData, newReader],
      };
    });

    handleVisibleChange(false);
    setIsOpen(false);
  };

  const addCustomFieldAfterActive = (type: string) => {
    setFormData((prevFormData) => {
      // Find the index of the active field
      const activeFieldIndex = prevFormData.BlogData.findIndex(
        (reader) => reader.id === activeField?.id
      );

      if (activeFieldIndex !== -1) {
        // Insert the new field just below the active field
        const newReader = {
          id: Date.now(),
          type,
          value: type === 'image' ? null : '',
        };

        const newBlogData = [
          ...prevFormData.BlogData.slice(0, activeFieldIndex + 1),
          newReader,
          ...prevFormData.BlogData.slice(activeFieldIndex + 1),
        ];

        return {
          ...prevFormData,
          BlogData: newBlogData,
        };
      }

      return prevFormData;
    });

    handleVisibleChangeInline(false);
    setIsOpenInline(false);
  };
  const removeReader = (idToRemove: number) => {
    setFormData((prevFormData) => {
      const newReaders = prevFormData.BlogData.filter(
        (reader) => reader.id !== idToRemove
      );
      return {
        ...prevFormData,
        BlogData: newReaders,
      };
    });
  };
  const onFinish = (values: FormData) => {
    console.log('Form values:', values);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleInLine = () => {
    setIsOpenInline(!isOpenInline);
  };
  const handleVisibleChange = (visible: boolean) => {
    setIsOpen(visible);
    handleFocusChange({ id: 1, value: true });
    setIsOpenInline(false);
  };

  const handleVisibleChangeInline = (visible: boolean) => {
    setIsOpenInline(visible);
  };

  const handleImageUpload = async (id: number, image: File) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', image);
      const res = await uploadImage(formData);
      handleInputChange(id, 'value', res?.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (fieldId?: number, value?: string) => {
    const selection = window.getSelection();

    if (selection && selection.toString().length > 0) {
      const startIndex = value?.indexOf(selection.toString());
      const endIndex = (startIndex as number) + selection.toString().length;
      // Get the selected text and its start and end indexes
      const selectedText = selection.toString();
      // const startIndex = range.startOffset;
      // const endIndex = range.endOffset;

      // If text is selected, set visibility for the selected field to true
      if (fieldId) {
        setPopoverVisibility({ ...popoverVisibility, [fieldId]: true });
        setSelectedText(selectedText);
        setSelectedIndexes({ startIndex, endIndex }); // Store the start and end indexes
      }
    } else {
      // If no text is selected, set visibility for the selected field to false
      if (fieldId) {
        setPopoverVisibility({ ...popoverVisibility, [fieldId]: false });
        setSelectedText('');
        setSelectedIndexes(null);
      }
    }
  };
  // Handle click outside to hide Popover
  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     textAreaRef.current &&
  //     !textAreaRef.current.contains(event.target as Node)
  //   ) {
  //     // Create a new object to update visibility for each popover
  //     const updatedVisibility = { ...popoverVisibility };
  //     Object.keys(updatedVisibility).forEach((key) => {
  //       updatedVisibility[key] = false; // Set visibility to false for all keys
  //     });
  //     setPopoverVisibility(updatedVisibility);
  //   }
  // };

  // useEffect(() => {
  //   // Add event listener for click outside of textarea
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     // Cleanup event listener on component unmount
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  const handleTickClick = (value: string, id: number) => {
    if (selectedIndexes && value) {
      const { startIndex, endIndex } = selectedIndexes;

      if (startIndex !== -1 && endIndex !== -1) {
        const anchorTag = `<a href="${inputValue}">${selectedText}</a>`;
        const newText =
          value.substring(0, startIndex) +
          anchorTag +
          value.substring(endIndex);

        // Find the index of the object with the matching id
        // const dataIndex = formData.BlogData.findIndex((item) => item.id === id);
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            BlogData: prevFormData.BlogData.map((reader) => {
              if (reader.id === id && reader?.type === 'paragraph') {
                // Update the value of the object with the matching ID
                return { ...reader, value: newText };
              }
              // Leave other objects unchanged
              return reader;
            }),
          };
        });

        // Clear selections and popover visibility
        setSelectedText('');
        setInputValue('');
        setSelectedIndexes(null);
        setPopoverVisibility({});
      }
    }
  };
  const handleInputChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update input value for anchor tag dynamically
    setInputValue(e.target.value);
  };

  return (
    <div className="pt-4">
      <Form onFinish={onFinish} initialValues={formData}>
        <ShouldRender check={!isMessage}>
          <Form.Item name="title">
            <div>
              <TextArea
                className={` 
              text-[32px] font-bold rounded-none border-none focus:ring-0 placeholder:capitalize `}
                size="large"
                autoSize={{ minRows: 1, maxRows: 2000 }}
                placeholder={`Chapter Title`}
                style={{
                  fontSize: '32px',
                }}
                defaultValue={formData?.title as string}
                value={formData?.title as string}
                onFocus={() => {
                  handleFocusChange({ id: 1, value: true });
                  setIsOpenInline(false);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const capitalizedTitle = inputValue.replace(/^(.)/, (match) =>
                    match.toUpperCase()
                  );
                  setFormData({ ...formData, title: capitalizedTitle });
                }}
              />
            </div>
          </Form.Item>
        </ShouldRender>

        {formData?.BlogData?.map((reader, index) => {
          return (
            <div key={index} className="flex flex-col overflow-hidden">
              <Form.Item name={`BlogData[${reader.id}].value`}>
                {reader.type === 'image' ? (
                  <UploadImage
                    imageUrl={reader.value as string}
                    handleRemove={() => removeReader(reader?.id)}
                    id={reader?.id}
                    loading={loading}
                    onFileChange={(file) => handleImageUpload(reader?.id, file)}
                    handleInputChange={handleInputChange}
                    altText={reader?.altText}
                    visibleLinkText={reader?.link}
                  />
                ) : (
                  <div
                    onFocus={() => {
                      handleFocusChange({ id: reader.id, value: true });
                      setIsOpenInline(false);
                    }}
                    // onBlur={() =>
                    //   handleFocusChange({ id: reader.id, value: false })
                    // }
                    className="flex gap-x-2 relative items-start"
                  >
                    <ShouldRender
                      check={
                        reader?.id === activeField?.id && activeField?.value
                      }
                    >
                      <div
                        className={`${
                          reader?.type !== 'title' ? 'mt-1' : 'mt-3 pt-4'
                        } flex  items-center pl-2`}
                      >
                        <Popconfirm
                          placement="bottom"
                          showArrow={false}
                          open={isOpenInline}
                          title={''}
                          description={
                            <div className="flex flex-col gap-y-2.5 h-full px-2">
                              <span
                                onClick={() =>
                                  addCustomFieldAfterActive('title')
                                }
                                className="flex flex-col gap-y-1 items-center  text-sm font-medium hover:text-primary cursor-pointer"
                              >
                                <div className="font-bold text-2xl">T</div>
                                Title
                              </span>
                              <span className="border-t-2 border-[#EDEDED]" />
                              <span
                                onClick={() =>
                                  addCustomFieldAfterActive('paragraph')
                                }
                                className="flex flex-col gap-y-1 items-center text-sm font-medium hover:text-primary cursor-pointer"
                              >
                                <div className="font-bold text-2xl">
                                  <FiAlignLeft className="text-3xl" />
                                </div>
                                Paragraph
                              </span>
                              <span className="border-t-2 border-[#EDEDED]" />
                              <span
                                onClick={() =>
                                  addCustomFieldAfterActive('image')
                                }
                                className="flex flex-col gap-y-1 p-1 h-max  items-center text-sm font-medium hover:text-primary cursor-pointer"
                              >
                                <LuImagePlus className="text-3xl" />
                                Image
                              </span>
                              <span className="border-t-2 border-[#EDEDED]" />
                              <span
                                onClick={() =>
                                  addCustomFieldAfterActive('bullet')
                                }
                                className="flex flex-col gap-y-1 p-1 h-max  items-center text-sm font-medium hover:text-primary cursor-pointer"
                              >
                                <MdFormatListBulleted className="text-3xl" />
                                Bullet Points
                              </span>
                              <span className="border-t-2 border-[#EDEDED]" />
                              <span
                                onClick={() =>
                                  addCustomFieldAfterActive('html')
                                }
                                className="flex flex-col gap-y-1 p-1 h-max pb-2  items-center text-sm font-medium hover:text-primary cursor-pointer"
                              >
                                <BsFiletypeHtml className="text-3xl" />
                                WYSIWYG
                              </span>
                            </div>
                          }
                          icon={<></>}
                          okText=""
                          overlayClassName="custom-popconfirm"
                          onOpenChange={handleVisibleChangeInline}
                        >
                          <div
                            className="border border-[#D7D7D7] rounded-full p-1.5 w-min cursor-pointer transition-transform transform hover:scale-110"
                            onClick={handleToggleInLine}
                          >
                            <PiDotsThreeOutlineVerticalFill className="text-lg" />
                          </div>
                        </Popconfirm>
                      </div>
                    </ShouldRender>
                    <ShouldRender check={reader.type !== 'html'}>
                      <div ref={textAreaRef} className="w-full">
                        <Popover
                          content={
                            <Input
                              ref={inputRef}
                              onChange={handleInputChangeLink}
                              suffix={
                                <div>
                                  <Button
                                    className="text-green-500 px-2"
                                    type="link"
                                    danger
                                    onClick={() => {
                                      setSelectedText('');
                                      setInputValue('');
                                      setSelectedIndexes(null);
                                      setPopoverVisibility({});
                                    }}
                                  >
                                    <RxCross2 />
                                  </Button>
                                  <Button
                                    className="text-green-500 px-2"
                                    type="link"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleTickClick(
                                        reader.value as string,
                                        reader.id
                                      );
                                    }}
                                  >
                                    <FiCheck />
                                  </Button>
                                </div>
                              }
                              value={inputValue}
                              placeholder="Add Link"
                              onClick={(e) => e.stopPropagation()}
                              onPressEnter={() => {
                                // Handle enter key press
                              }}
                            />
                          }
                          trigger="hover"
                          visible={
                            popoverVisibility[reader.id.toString()] || false
                          } // Convert reader.id to string
                        >
                          <TextArea
                            className={` ${
                              reader.type === 'title'
                                ? 'text-[20px] md:text-[24px] xl:text-[28px]  leading-6 md:leading-8  tracking-normal md:tracking-tighter font-semibold whitespace-pre-line pt-2 xl:pt-5' +
                                  (reader.color
                                    ? ` text-[${reader.color}]`
                                    : '')
                                : 'text-[16px] font-normal text-gray-100   leading-7 md:leading-8 tracking-tight  whitespace-pre-line'
                            }  rounded-none border-none focus:ring-0 placeholder:capitalize `}
                            size="large"
                            onMouseUp={() =>
                              handleSelection(reader.id, reader.value as string)
                            }
                            style={{
                              // fontSize:
                              //   reader.type === 'title' ? '28px' : '24px',
                              color:
                                reader.type === 'title' && reader.color
                                  ? reader.color
                                  : '',
                              textDecoration:
                                reader.type === 'title' &&
                                reader.underlined === '1'
                                  ? 'underline'
                                  : '',
                              fontStyle:
                                reader.type === 'title' && reader.italic === '1'
                                  ? 'italic'
                                  : '',
                              fontWeight:
                                reader.type === 'title' && reader.bold === '0'
                                  ? 'initial'
                                  : '',
                            }}
                            autoSize={{ minRows: 1, maxRows: 2000 }}
                            placeholder={`${
                              reader.type === 'bullet'
                                ? 'Bullet Points'
                                : reader.type
                            } `}
                            defaultValue={reader.value as string}
                            value={reader.value as string}
                            onKeyDown={(e) =>
                              reader.type === 'bullet' &&
                              handleTextAreaKeyDown(reader.id, e)
                            }
                            onChange={(e) => {
                              handleInputChange(
                                reader.id,
                                'value',
                                e.target.value
                              );
                            }}
                          />
                        </Popover>
                      </div>
                    </ShouldRender>

                    <ShouldRender check={reader.type === 'html'}>
                      <div
                        onClick={() => {
                          handleFocusChange(
                            { id: reader.id, value: true },
                            true
                          );
                          setIsOpenInline(false);
                        }}
                        className="w-full min-h-40"
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={reader.value}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            handleInputChange(reader.id, 'value', data);
                          }}
                        />
                      </div>
                    </ShouldRender>

                    <ShouldRender
                      check={
                        reader.type === 'title' &&
                        reader?.id === activeField?.id &&
                        activeField?.value
                      }
                    >
                      <span className="flex items-center gap-x-2 mt-5">
                        <GrUnderline
                          onClick={() => {
                            handleInputChange(
                              reader.id,
                              'underlined',
                              reader.underlined && reader.underlined === '0'
                                ? '1'
                                : reader.underlined === '1'
                                ? '0'
                                : '1'
                            );
                          }}
                          className={`${
                            reader?.underlined === '1' ? 'text-primary' : ''
                          } text-2xl cursor-pointer`}
                        />
                        <FaBold
                          onClick={() => {
                            handleInputChange(
                              reader.id,
                              'bold',
                              reader.bold && reader.bold === '1'
                                ? '0'
                                : reader.bold === '0'
                                ? '1'
                                : '0'
                            );
                          }}
                          className={`${
                            reader?.bold === '1' ? 'text-primary' : ''
                          } text-lg cursor-pointer`}
                        />

                        <VscItalic
                          onClick={() => {
                            handleInputChange(
                              reader.id,
                              'italic',
                              reader.italic && reader.italic === '0'
                                ? '1'
                                : reader.italic === '1'
                                ? '0'
                                : '1'
                            );
                          }}
                          className={`${
                            reader?.italic === '1' ? 'text-primary' : ''
                          } text-xl cursor-pointer`}
                        />
                        <ColorPicker
                          defaultValue="#1677ff"
                          value={reader.color}
                          size="small"
                          onChange={(color) =>
                            handleInputChange(
                              reader.id,
                              'color',
                              color.toHexString()
                            )
                          }
                        />
                      </span>
                    </ShouldRender>
                    {(!String(reader?.value)?.length ||
                      reader?.value === '• ' ||
                      reader.type === 'html' ||
                      reader?.value === '•') && (
                      <div
                        className="bg-grayBg bg-opacity-40 m-2 mt-4 rounded-full p-2 w-min cursor-pointer transition-transform transform hover:scale-110"
                        onClick={() => removeReader(reader?.id)}
                      >
                        <XIcon className="w-4 h-4 text-black" />
                      </div>
                    )}
                  </div>
                )}
              </Form.Item>
            </div>
          );
        })}
        <div className="flex items-center py-10">
          <Popconfirm
            placement="right"
            showArrow={false}
            open={isOpen}
            title={''}
            description={
              <div className="flex gap-x-5 h-full px-5">
                <span
                  onClick={() => addReader('title')}
                  className="flex flex-col gap-y-1 items-center text-sm font-medium hover:text-primary cursor-pointer"
                >
                  <div className="font-bold text-2xl">T</div>
                  Title
                </span>
                <span className="border-r-2 border-[#EDEDED]" />
                <span
                  onClick={() => addReader('paragraph')}
                  className="flex flex-col gap-y-1 items-center text-sm font-medium hover:text-primary cursor-pointer"
                >
                  <div className="font-bold text-2xl">
                    <FiAlignLeft className="text-3xl" />
                  </div>
                  Paragraph
                </span>
                <span className="border-r-2 border-[#EDEDED]" />
                <span
                  onClick={() => addReader('image')}
                  className="flex flex-col gap-y-1 p-1 h-max  items-center text-sm font-medium hover:text-primary cursor-pointer"
                >
                  <LuImagePlus className="text-3xl" />
                  Image
                </span>
                <span className="border-r-2 border-[#EDEDED]" />
                <span
                  onClick={() => addReader('bullet')}
                  className="flex flex-col gap-y-1 p-1 h-max  items-center text-sm font-medium hover:text-primary cursor-pointer"
                >
                  <MdFormatListBulleted className="text-3xl" />
                  Bullet Points
                </span>
                <span className="border-r-2 border-[#EDEDED]" />
                <span
                  onClick={() => addReader('html')}
                  className="flex flex-col gap-y-1 p-1 h-max pb-2  items-center text-sm font-medium hover:text-primary cursor-pointer"
                >
                  <BsFiletypeHtml className="text-3xl" />
                  WYSIWYG
                </span>
              </div>
            }
            icon={<></>}
            okText=""
            overlayClassName="custom-popconfirm"
            onOpenChange={handleVisibleChange}
          >
            <div
              className="border border-[#D7D7D7] rounded-full p-2 w-min cursor-pointer transition-transform transform hover:scale-110"
              onClick={handleToggle}
            >
              {isOpen ? (
                <XIcon className="w-4 h-4 text-red-500" />
              ) : (
                <PlusIcon className="w-4 h-4" />
              )}
            </div>
          </Popconfirm>
        </div>
      </Form>
    </div>
  );
};

export default Editor;
