import React, { ReactNode } from 'react';

interface CheckPermissionProps {
  check: string;
  children: ReactNode;
}

export const CheckPermission: React.FC<CheckPermissionProps> = ({
  check,
  children,
}) => {
  const getAbilitiesData = localStorage.getItem('abilitiesData');
  const component = (children as React.ReactElement) || <React.Fragment />;
  if (check.trim() === '') {
    return component;
  } else {
    const checkPermission = (
      getAbilitiesData ? (JSON.parse(getAbilitiesData) as string[]) : []
    ).includes(check);

    return checkPermission ? component : <React.Fragment />;
  }
};
