import { Button, Input } from 'antd';

import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';

import AuthLayout from '../AuthLayout';
import { ShouldRender } from '../../../components/basic/ShouldRender';
import { EyeIcon, LockClosedIcon } from '@heroicons/react/outline';
import { HiOutlineMail } from 'react-icons/hi';
import { ZEBRA_ICON } from '../../../assets';
import {
  formateErrorAppwriteObject,
  formateErrorObject,
} from '../../../components/basic/formateError';
import {
  forgotPasswordCheckOtp,
  forgotPasswordResetPassword,
  forgotPasswordSendEmail,
} from '../../../actions/useAuth';
import { useNavigate } from 'react-router-dom';
import appwriteService from '../../../config/appwrite';
import { notify } from '../../../components/basic/notity';

interface Props {
  isModal?: boolean;
  onCancel?: () => void;
  visible?: boolean;
}

const ForgotPassword: React.FC<Props> = ({ isModal = false }) => {
  const [forgotPasswordStep, setForgotPasswordStep] = useState(0);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState({
    reEnterPassword: '',
    password: '',
  });

  const [emailErrors, setEmailErrors] = useState({
    email: '',
  });

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const router = useNavigate();
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'registeredEmail') {
      setEmail(value);
    } else if (name === 'password' || name === 'reEnterPassword') {
      setPassword({
        ...password,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const submitEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      email: '',
    };

    if (!email) {
      validationErrors.email = 'Email or phone number is required';
    } else if (!isValidEmailOrPhone(email)) {
      validationErrors.email = 'Invalid email or phone number';
    }

    setEmailErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setLoading(true);
        await appwriteService.passwordRecovery(email);
        setForgotPasswordStep(1);
        setEmailErrors({
          email: '',
        });
        setMessage('');
      } catch (error: any) {
        const message = formateErrorAppwriteObject(error);
        notify(error, 'error');
        validationErrors.email = message;
        setEmailErrors(validationErrors);
        setMessage(message);
      } finally {
        setLoading(false);
      }
    }
  };

  const isValidEmailOrPhone = (value: string) => {
    // Your combined validation logic for email and phone number
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  return (
    <AuthLayout>
      <div className="min-h-screen   flex items-center gap-y-10 flex-col justify-center">
        <div className="bg-white  rounded-xl min-w-[500px] z-30 px-12 lg:px-8 py-5 xl:py-10 flex flex-col gap-y-1">
          <div className="relative pt-4">
            <span className=" flex justify-center w-full">
              <img
                src={ZEBRA_ICON}
                alt="Logo"
                className="object-contain w-1/2"
              />
            </span>
          </div>
          <div className="border-b-2 border-backgroundGray py-3" />
          <div className="flex items-center gap-y-10 flex-col justify-center">
            <div className="z-30  py-6 flex flex-col w-full gap-y-1">
              <ShouldRender check={forgotPasswordStep === 0}>
                <h1 className="text-xl text-fadedWhite font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full">
                  Reset your{' '}
                  <b className="text-primary font-medium">password</b>
                </h1>
                <span className="text-textGray text-center text-lg">
                  Don't worry! We got your back.
                </span>
                <form
                  onSubmit={submitEmail}
                  className="flex flex-col  gap-2 mt-5 w-full"
                >
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input
                      type="text"
                      name="registeredEmail"
                      placeholder="Email"
                      autoComplete="off"
                      value={email}
                      onChange={handleChange}
                      size="large"
                      prefix={
                        <span className="pr-2">
                          <HiOutlineMail className="text-lg text-graydark" />
                        </span>
                      }
                      className="rounded border border-gray-600"
                    />
                    {(emailErrors.email || message) && (
                      <p className="text-red-500">
                        {message ?? emailErrors.email}
                      </p>
                    )}
                  </div>

                  <span className="flex justify-center mt-2">
                    <Button
                      className="p-1 w-full"
                      disabled={loading}
                      size="large"
                      loading={loading}
                      onClick={(e) => submitEmail(e)}
                    >
                      <span className="flex text-sm font-bold gap-x-2  whitespace-nowrap">
                        Send Reset Link
                      </span>
                    </Button>
                  </span>

                  {/* <ShouldRender check={!onCancel}>
                <span className='flex justify-center'>
                  <span
                    onClick={() => {
                      if (onCancel) {
                        handleModalClose();
                      } else {
                        router.push('/auth/login');
                      }
                    }}
                    className='text-center  text-primary  whitespace-nowrap px-1 hover:font-bold underline cursor-pointer text-sm'
                  >
                    GO BACK
                  </span>
                </span>
              </ShouldRender> */}

                  <span className="text-center text-sm text-fadedWhite mt-2">
                    Email will be send on your mail
                  </span>
                </form>
              </ShouldRender>

              <ShouldRender check={forgotPasswordStep === 1}>
                <div className="bg-readerLightGrayBg text-center rounded-xl py-5">
                  <div className="text-textGray text-center font-medium text-base leading-8">
                    <p>Email sent, please check</p>
                  </div>
                  <span className="font-medium leading-8  text-center text-xl">
                    {email}
                  </span>
                </div>
                <div className="flex justify-center mt-2">
                  {' '}
                  <span className="text-center text-textGray w-[90%] font-medium">
                    {` If you haven't received shortly, check spam/junk. For help
                    contact support`}{' '}
                    <b className="text-gray-300">support@zebralearn.com</b>
                  </span>
                </div>
                <span
                  onClick={() => router('/')}
                  className="text-center underline mt-2 cursor-pointer hover:text-primary text-xl text-textGray  font-medium"
                >
                  Login
                </span>
                <div className="text-center flex flex-col pt-20">
                  <span className="text-textGray text-base font-medium">
                    Wrong email?
                  </span>
                  <span
                    onClick={() => {
                      setEmail('');
                      setForgotPasswordStep(0);
                    }}
                    className="text-base font-medium cursor-pointer underline text-gray-300"
                  >
                    Change here
                  </span>
                </div>
              </ShouldRender>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default ForgotPassword;
