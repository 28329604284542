import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import CREATE_BLOG from '../../../../../assets/create_blog.png';
import { toast } from 'react-toastify';
import { EllipsisOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import {
  UpdatemonetizationApi,
  deleteMyBlogBySchoolIdApi,
  deleteSchoolApi,
  editSchoolDetailsApi,
  getAllAuthorsListApi,
  getCategoriesApi,
  getMyBlogBySchoolIdApi,
  getSchooldetailsById,
  monetizationBlogsApi,
  restorePostApi,
  statusUpdateForMonetizationApi,
} from '../../../../../services/apiservices';
import { Endpoints } from '../../../../../services/httpendpoints';
import Loader from '../../../../../components/ui-elements/Loader/Loader';
import SidePopup from '../../../../../components/ui-elements/SidePopup/SidePopup';
import {
  AllBlogsDataInterface,
  CategoriesDataInterface,
  DataBySchoolId,
} from '../../../../../utills/interfaces/interface';
import DEFAULT_IMAGE from '../../../../../assets/images/cards/1.png';
import { AiOutlineClose } from 'react-icons/ai';
import { Input, Modal, Select, Tooltip } from 'antd';
import ImageUploader from '../../../../../components/ui-elements/image-uploader/ImageUploader';
import { Switch } from 'antd';
import { format } from 'date-fns';
import { GrFormClose } from 'react-icons/gr';
import { CheckPermission } from '../../../../../components/basic/CheckPermission';
import { useRecoilValue } from 'recoil';
import { triggerChange } from '../../../../../atoms/triggerChange/triggerChange';

interface BlogData {
  published?: number;
  is_draft?: number;
  trash?: number;
}

function SchoolDetailView() {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState<DataBySchoolId>();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const triggerChanges = useRecoilValue(triggerChange);
  const { id } = params;
  const [activeTab, setActiveTab] = useState('published');
  const [allBlogsData, setAllBlogsData] = useState<AllBlogsDataInterface>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForEditSchool, setModalOpenForEditSchool] = useState(false);
  const [formData, setFormData] = useState({
    mobile_image: '',
    desktop_image: '',
    title: '',
    url: '',
    active: false,
  });
  const [mobileImage, setMobileImage] = useState<File | null>(null);
  const [desktopImage, setDesktopImage] = useState<File | null>(null);
  const [showAllTags, setShowAllTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [seoData, setSeoData] = useState({
    title: '',
    meta_description: '',
    keywords: '',
    author: '',
    slug: '',
    canonical: '',
  });
  const [seoDataBlog, setSeoDataBlog] = useState({
    title: '',
    meta_description: '',
    keywords: '',
    slug: '',
    canonical: '',
  });
  const [categoriesData, setCategoriesData] =
    useState<CategoriesDataInterface>();
  const initialMonetizationStatus =
    localStorage.getItem('isMonetized') === 'true';
  const [isMonetized, setIsMonetized] = useState(initialMonetizationStatus);

  const [editFormData, setEditFormData] = useState({
    coverImage: null as File | null,
    name: '',
    description: '',
    selectedTags: [] as string[],
    author_id: '',
    author: '',
  });
  const [authorList, setAuthorList] = useState<
    {
      name: string;
      zl_uid: string;
      id: string;
    }[]
  >();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const maxTagsToShow = 20;
  /*  useEffect(() => {
          // Save the current state to localStorage whenever it changes
          localStorage.setItem('isMonetized', String(isMonetized));
      }, [isMonetized,responseData]);*/

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    getBlogResponseBySchoolId(tabName);
  };

  const getSchoolsDataById = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await getSchooldetailsById(id);
      setResponseData(response?.data?.data);
      setSeoData({
        author: response.data.data?.author,
        keywords: response.data.data?.keywords || '',
        meta_description: response.data.data?.meta_description || '',
        title: response.data.data?.title || '',
        slug: response.data.data?.slug || '',
        canonical: response.data.data?.canonical || '',
      });

      if (response?.data?.data?.advertisements[0]?.active === true) {
        setIsMonetized(true);
      } else if (
        response?.data?.data?.advertisements?.length !== 0 &&
        response?.data?.data?.advertisements?.active === false
      ) {
        setIsMonetized(false);
      } else if (
        response?.data?.data?.advertisements?.length !== 0 &&
        response?.data?.data?.advertisements?.active === true
      ) {
        setIsMonetized(true);
      }
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSchoolsDataById(id as string);
    }
  }, [id, triggerChanges]);

  const getBlogResponseBySchoolId = async (action: string) => {
    let data: BlogData = {};

    if (action === 'published') {
      data.published = 1;
      setActiveTab('published');
    } else if (action === 'drafts') {
      data.is_draft = 1;
      setActiveTab('drafts');
    } else if (action === 'trash') {
      data.trash = 1;
      setActiveTab('trash');
    } else {
      data.published = 1;
    }
    try {
      setIsLoading(true);
      const response = await getMyBlogBySchoolIdApi(id, data);
      setAllBlogsData(response?.data);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllAuthors = async () => {
    try {
      const response = await getAllAuthorsListApi();
      setAuthorList(
        response?.data?.data?.map((author: any) => ({
          name: author?.name,
          zl_uid: author?.zl_uid,
          id: author?.id?.toString(),
        }))
      );
    } catch (error) {
      console.error('API call error:', error);
    }
  };
  useEffect(() => {
    if (id) {
      getBlogResponseBySchoolId(activeTab);
      getAllAuthors();
    }
  }, [id, activeTab, triggerChanges]);

  const handleDeleteBlog = async (id: number) => {
    setOpen(true);
    try {
      setIsLoading(true);
      setIsPopoverVisible(false);
      await deleteMyBlogBySchoolIdApi(id);
      getBlogResponseBySchoolId(activeTab); // Use activeTab as the action

      toast.error('Blog deleted successfully');
      // getBlogResponseBySchoolId(id);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (value: string) => {
    const selectedAuthor = authorList?.find(
      (author) => author.id.toString() === value.toString()
    );
    console.log(value, selectedAuthor, 'asjhgdkj');
    if (selectedAuthor) {
      setEditFormData({
        ...editFormData,
        author_id: selectedAuthor.id.toString(),
        author: selectedAuthor.name,
      });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string | number }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleRestorePost = async (id: number) => {
    try {
      setIsLoading(true);
      setIsPopoverVisible(false);
      await restorePostApi(id);
      getBlogResponseBySchoolId(activeTab); // Use activeTab as the action
      toast.success('Blog restore successfully');
      // getBlogResponseBySchoolId(id);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(' ');
    if (words.length <= maxWords) {
      return text;
    }
    const truncatedText = words.slice(0, maxWords).join(' ');
    return `${truncatedText} ...`;
  };

  const truncateTextForCategories = (text: string, maxCharacters: number) => {
    if (text.length <= maxCharacters) {
      return text;
    }
    const truncatedText = text.slice(0, maxCharacters);
    return `${truncatedText} ...`;
  };

  const handleMobileImageUpload = (file: File | null) => {
    setMobileImage(file);
  };

  const handleDesktopImageUpload = (file: File | null) => {
    setDesktopImage(file);
  };

  const onOpenHandlerForMonetize = () => {
    setModalOpen(true);
  };

  const updateStatusForMonetization = async (id: number, data: any) => {
    try {
      setIsLoading(true);
      await statusUpdateForMonetizationApi(id, data);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isMonetizationStatus = () => {
    const adsSchoolFormData = new FormData();

    // Convert boolean to 0 or 1
    const activeValue = String(isMonetized ? 1 : 0);

    adsSchoolFormData.append('active', activeValue);
    adsSchoolFormData.append('school_id', id as string);

    if (
      responseData?.advertisements &&
      responseData?.advertisements.length > 0
    ) {
      const adsId = responseData?.advertisements[0]?.id;
      if (adsId !== undefined) {
        updateStatusForMonetization(adsId, adsSchoolFormData);
        setIsMonetized(!isMonetized);
        return;
      }

      // updateStatusForMonetization(adsId, adsSchoolFormData);
      // setIsMonetized(!isMonetized);
    } else {
      setIsMonetized(isMonetized);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    if (
      responseData?.advertisements &&
      responseData?.advertisements?.length > 0
    ) {
      const firstAdvertisement = responseData?.advertisements[0];
      setFormData({
        mobile_image: '',
        desktop_image: '',
        title: firstAdvertisement?.name || '',
        url: firstAdvertisement?.url || '',
        active: false,
      });
    }
  }, [responseData]);

  const onChangeHandleMonetizationBlog = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const submitMonetizationFrom = async (data: any) => {
    try {
      setIsLoading(true);
      await monetizationBlogsApi(data);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
      toast.success('Advertisement created successfully');
      setModalOpen(false);
    }
  };

  const updateMonetizationFrom = async (id: number, data: any) => {
    try {
      setIsLoading(true);
      await UpdatemonetizationApi(id, data);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      toast.success('Advertisement updated successfully');
      setModalOpen(false);
      setIsLoading(false);
    }
  };

  const submitForm = () => {
    const missingFields = [];
    if (!formData.title) {
      missingFields.push('Title');
    }
    if (!formData.url) {
      missingFields.push('URL');
    }
    if (!desktopImage) {
      missingFields.push('Desktop Image');
    }

    if (missingFields.length > 0) {
      const errorMessage = `${missingFields.join(', ')} ${
        missingFields.length > 1 ? 'are' : 'is'
      } required`;
      toast.error(errorMessage);
      return;
    }

    const adsSchoolFormData = new FormData();

    if (desktopImage) {
      adsSchoolFormData.append('ads_image1', desktopImage as File);

      adsSchoolFormData.append('ads_image2', desktopImage);
    }
    adsSchoolFormData.append('name', formData.title);
    adsSchoolFormData.append('url', formData.url);
    adsSchoolFormData.append('school_id', id as string);
    adsSchoolFormData.append('active', Number(formData.active).toString());

    if (
      responseData?.advertisements &&
      responseData?.advertisements.length > 0
    ) {
      const adsId = responseData?.advertisements[0]?.id;

      if (adsId !== undefined) {
        updateMonetizationFrom(adsId, adsSchoolFormData);

        // setIsMonetized(!isMonetized);
        return;
      }
      // updateMonetizationFrom(adsId, adsSchoolFormData);
    } else {
      submitMonetizationFrom(adsSchoolFormData);
    }

    // Reset form data and close modal
    setFormData({
      mobile_image: '',
      desktop_image: '',
      title: '',
      url: '',
      active: false,
    });
    setModalOpen(false);
  };

  const handlEditBlogById = async (editId: number, optionalId?: number) => {
    navigate(
      `/school/schoolblog-view/${id}/create-blogs/${editId}/${optionalId || ''}`
    );
  };

  const viewBlogById = (viewId: number, optionalId?: number) => {
    navigate(
      `/school/schoolblog-view/${id}/blogsview/${viewId}/${optionalId || ''}`
    );
  };

  const handleCreateBlogs = () => {
    navigate(`/school/schoolblog-view/${id}/create-blogs`);
  };

  // const defaultForMobile = responseData?.advertisements[0]?.documents;
  // const imageForMobile = defaultForMobile
  //   ?.filter((item: any) => item.sub_type === 'SMALL')
  //   ?.map((item: any) => item.id);

  // const imageForDesktop = defaultForMobile
  //   ?.filter((item: any) => item.sub_type === 'LARGE')
  //   ?.map((item: any) => item.id);

  const defaultForMobile = responseData?.advertisements[0]?.documents;

  const imageForDesktop = Array.isArray(defaultForMobile)
    ? defaultForMobile
        .filter((item: any) => item.sub_type === 'LARGE')
        .map((item: any) => item.id)
    : [];

  const handleDeleteSchool = async (id: any) => {
    try {
      setIsLoading(true);
      await deleteSchoolApi(id);
      toast.error('School deleted successfully!');
      navigate(`/school/`);
      // getBlogResponseBySchoolId(id);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSeeMoreClick = () => {
    setShowAllTags(!showAllTags);
  };

  const handleTagClick = (category_id: any) => {
    // If the clicked tag is already selected, deselect it
    if (selectedTag === category_id) {
      setSelectedTag(null);
      setEditFormData({
        ...editFormData,
        selectedTags: editFormData.selectedTags.filter(
          (selectedTag) => selectedTag !== category_id
        ),
      });
    } else {
      // Otherwise, select the clicked tag
      setSelectedTag(category_id);
      setEditFormData({
        ...editFormData,
        selectedTags: [category_id],
      });
    }
  };

  const handleEditSchoolDetails = () => {
    setModalOpenForEditSchool(true);
    setEditFormData({
      coverImage: null as File | null,
      name: responseData?.name as string,
      description: responseData?.description as string,
      selectedTags: [] as string[],
      author_id: responseData?.author_id as string,
      author: responseData?.author as string,
    });
  };

  const handleImageUpload = (file: File | null) => {
    // const file = e.target.files[0];
    setSelectedImage(file);
    setEditFormData({ ...editFormData, coverImage: file as File });
  };

  useEffect(() => {
    const getCategoriesData = async () => {
      try {
        setIsLoading(true);
        const response = await getCategoriesApi();
        setCategoriesData(response?.data);
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    getCategoriesData();
  }, []);

  const handleChangeForEditSchool = (field: string, value: string) => {
    setEditFormData({ ...editFormData, [field]: value });
  };

  const submitEditSchoolForm = async () => {
    const editedSchoolDetails = new FormData();

    editedSchoolDetails.append('cover_image', editFormData.coverImage as File);
    // editedSchoolDetails.append('created_by', userInfo);
    editedSchoolDetails.append('name', editFormData.name);
    editedSchoolDetails.append('description', editFormData.description);
    editedSchoolDetails.append(
      'author_id',
      editFormData?.author_id?.toString() as string
    );
    editedSchoolDetails.append(
      'author',
      editFormData?.author?.toString() as string
    );

    // editFormData.selectedTags.forEach((tag) => {
    //   editedSchoolDetails.append(`category_id`, tag);
    // });

    try {
      setIsLoading(true);
      await editSchoolDetailsApi(editedSchoolDetails, id);
      toast.success('school updated successfully!');
      navigate(`/school/`);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
      setModalOpenForEditSchool(false);
    }
  };

  return (
    <>
      <>
        {!isLoading && allBlogsData?.data ? (
          <>
            <div className="flex flex-row justify-between gap-4 pl-9 pb-3 pt-3 border-b border-[#e2e2e2]">
              <div className="flex flex-row items-center gap-4">
                <div
                  onClick={() => navigate(`/school/`)}
                  // type="button"
                  className="rounded-xl text-lg  px-6 py-3 cursor-pointer"
                >
                  <p className="flex gap-2">
                    <ArrowLeftOutlined className="text-black font-bold" />
                    <b className="hidden sm:inline text-black">Back</b>
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => {
                    navigate(`/school/schoolblog-view/${id}/analytics`);
                  }}
                  className="rounded-xl  font-bold border border-[#e2e2e2] bg-white px-6 py-3 text-black mr-4 "
                >
                  <b className="font-medium">View Analytics</b>
                </button>

                <div className="border-l h-10 text-[#e2e2e2] p-2 "></div>
                <CheckPermission check="">
                  <button
                    type="button"
                    onClick={() =>
                      navigate(`/school/schoolblog-view/${id}/create-blogs`)
                    }
                    className="rounded-xl text-base font-bold  bg-[#FFF3F0] px-6 py-3 text-primary "
                  >
                    <b className="font-medium">Create Chapter</b>
                  </button>
                </CheckPermission>
              </div>
            </div>
            <div className="flex flex-col gap-4 p-10">
              {/* <div className='border w-full border-gray-200'></div> */}
              <div className="flex flex-row justify-between overflow-hidden gap-2 py-5 ">
                <div className="flex ">
                  <div className="">
                    <div className="w-[180px] h-[200px]">
                      <img
                        className="w-full object-cover rounded-xl "
                        src={
                          responseData?.documents?.file_path
                            ? `${Endpoints.getSchoolImagesByS3Bucket}/${responseData?.documents?.file_path}`
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between p-2">
                    <div className="flex flex-col justify-between  w-full px-6">
                      <div className="flex flex-col gap-4">
                        <div>
                          <b className="text-3xl">{responseData?.name}</b>
                        </div>
                        <div className="w-full text-lg">
                          <span>{responseData?.description} </span>
                        </div>
                      </div>
                      <CheckPermission check="edit-schools">
                        <div className="flex items-center gap-3">
                          <div
                            onClick={onOpenHandlerForMonetize}
                            className={`grid col-span-1 font-bold w-40 rounded-3xl hover:border-primary text-center py-2 ${
                              !isMonetized
                                ? 'bg-[#FFF3F0] text-primary text-base font-bold'
                                : 'bg-[#E6FFE6] text-success text-base font-bold'
                            } cursor-pointer`}
                          >
                            {!isMonetized ? 'Monetize' : 'Monetized'}
                          </div>
                          {/*<Switch
                                                style={!isMonetized ? {backgroundColor: "#E6FFE6"} : {backgroundColor: "#ff5612"}}

                                                checked={isMonetized}
                                                onChange={isMonetizationStatus}
                                            />*/}
                        </div>
                      </CheckPermission>
                    </div>
                  </div>
                </div>

                {/* <div className="flex items-start justify-end overflow-hidden"> */}
                {/* <div className="flex items-center justify-center border border-gray-700 w-7 h-7 rounded-full"> */}
                <div className="w-5 h-5 cursor-pointer mr-5">
                  <SidePopup
                    seoData={seoData}
                    handleDeleteBlog={() => handleDeleteSchool(id)}
                    handleRestorePost={handleEditSchoolDetails}
                    deleteText={'Delete School'}
                    secondOption={'Edit School '}
                    placement="bottom"
                    id={Number(id)}
                  />
                </div>
                {/* </div> */}
                {/* modal for edit school */}
                <Modal
                  open={modalOpenForEditSchool}
                  onCancel={() => setModalOpenForEditSchool(false)}
                  onOk={submitEditSchoolForm}
                  closeIcon={<></>}
                  closable={false}
                  width={730}
                  centered
                  footer={<></>}
                >
                  <div className="">
                    <div className=" px-6 py-6 rounded-t-xl">
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col gap-2">
                          <b className="text-2xl">Update School details </b>
                          <b className="text-base text-graydark">
                            update a list of blogs
                          </b>
                        </div>
                        <GrFormClose
                          onClick={() => setModalOpenForEditSchool(false)}
                          style={{ fontSize: '40px', cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 py-4">
                    <div className="flex flex-col gap-3 px-6">
                      <b className="text-lg text-[#4B4B4B]">
                        {' '}
                        Add image of your school
                      </b>
                      <div className="flex flex-col">
                        <ImageUploader
                          uploadAction="School cover image"
                          maxFileCount={1}
                          onChange={handleImageUpload}
                          defaultImage={`${Endpoints.getSchoolImagesByS3Bucket}/${responseData?.documents?.file_path}`}
                          clearImage={modalOpen}
                        />
                      </div>
                    </div>
                    <div className="px-6 w-full">
                      <span className="flex gap-x-4">
                        <p className="text-lg font-medium whitespace-nowrap">
                          Select Author
                        </p>
                      </span>
                      <div className="w-full">
                        <Select
                          placeholder="Search Author"
                          size="large"
                          suffixIcon={
                            <SearchOutlined className="w-3 mr-2 h-3 text-gray-400 hover:text-gray-800 cursor-pointer" />
                          }
                          allowClear
                          className="w-full"
                          showSearch
                          value={editFormData?.author_id.toString()}
                          onChange={handleChange}
                          filterOption={filterOption}
                          options={authorList
                            ?.filter((author) => author.zl_uid !== null)
                            .map((author) => ({
                              label: author.name,
                              value: author.id,
                            }))}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 px-6">
                      <b className="text-lg text-[#4B4B4B]">
                        What will be the title of your school?
                      </b>

                      <Input
                        showCount
                        maxLength={100}
                        placeholder="Enter school Title"
                        className="h-10 text-base focus:border-primary hover:border-primary"
                        // name="name"
                        value={editFormData?.name}
                        // onChange={handleChangeForEditSchool}
                        onChange={(e) =>
                          handleChangeForEditSchool('name', e.target.value)
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-3 px-6">
                      <b className="text-lg text-[#4B4B4B]">
                        What is the school about, describe your school.
                      </b>

                      <Input
                        showCount
                        maxLength={500}
                        placeholder="Describe your school"
                        className="h-20 text-base focus:border-primary hover:border-primary"
                        // name='description'
                        value={editFormData?.description}
                        onChange={(e) =>
                          handleChangeForEditSchool(
                            'description',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex flex-col  gap-2 px-6">
                      <div>
                        <b>Add a tag about the school</b>
                      </div>
                      <div className="grid grid-cols-6 gap-2">
                        {categoriesData?.data
                          ?.slice(0, showAllTags ? undefined : maxTagsToShow)
                          .map((tag: any) => (
                            <div
                              key={tag.id}
                              className={`grid col-span-1 rounded-3xl text-center py-2 border border-[#EAEAEA] whitespace-nowrap  cursor-pointer ${
                                editFormData.selectedTags.includes(tag.id)
                                  ? 'bg-[#FFF3F0] text-primary font-bold'
                                  : ''
                              }`}
                              onClick={() => handleTagClick(tag.id)}
                            >
                              <Tooltip placement="top" title={tag.name}>
                                {truncateTextForCategories(tag.name, 10)}
                              </Tooltip>
                            </div>
                          ))}
                        <div className="flex items-center ">
                          <a
                            onClick={handleSeeMoreClick}
                            className="hover:text-primary underline hover:underline font-bold"
                          >
                            {showAllTags ? 'Show less' : 'See more..'}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center p-6 space-x-2  border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={() => setModalOpenForEditSchool(false)}
                      type="button"
                      className=" w-1/3 text-black border rounded-xl p-3 font-bold hover:border-primary hover:text-primary"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="w-full text-white bg-primary rounded-xl p-3 font-bold "
                      onClick={submitEditSchoolForm}
                    >
                      Submit
                    </button>
                  </div>
                </Modal>
                {/* </div> */}
                {/* modal for monetization */}

                <Modal
                  open={modalOpen}
                  onCancel={() => setModalOpen(false)}
                  onOk={submitForm}
                  closeIcon={<></>}
                  closable={false}
                  width={600}
                  centered
                  footer={<></>}
                  className="px-4"
                >
                  <div className="p-0 bg-gray">
                    <div className=" px-6 py-6 rounded-t-xl">
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col gap-2 ">
                          <b className="text-2xl">
                            {responseData?.advertisements &&
                            responseData?.advertisements.length > 0
                              ? 'Activate your ads for school'
                              : 'Update your activated ads for school'}
                          </b>
                          <p className="text-[#565656]">
                            Add images and run your ads
                          </p>
                        </div>

                        <AiOutlineClose
                          onClick={() => setModalOpen(false)}
                          style={{ fontSize: '26px', cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 py-4">
                    {/* ImageUploader for Mobile Ads Image */}
                    <div className="flex">
                      {/* <div className="flex flex-col gap-3 items-center px-6 ">
                        <b className="text-base text-[#4B4B4B]">
                          Mobile ads image
                        </b>
                        <ImageUploader
                          uploadAction="your_upload_action_url_for_mobile_image"
                          maxFileCount={1}
                          onChange={handleMobileImageUpload}
                          defaultImage={
                            imageForMobile
                              ? `${Endpoints.getSchoolImages}/${imageForMobile}`
                              : null
                          }
                        />
                      </div> */}

                      {/* ImageUploader for Desktop Ads Image */}
                      <div className="flex flex-col gap-3 ">
                        <b className="text-base text-[#4B4B4B]">
                          Upload ads image for desktop (of size 1080 * 720)
                        </b>
                        <ImageUploader
                          uploadAction="your_upload_action_url_for_desktop_image"
                          maxFileCount={1}
                          onChange={handleDesktopImageUpload}
                          defaultImage={
                            imageForDesktop.length
                              ? `${Endpoints.getSchoolImages}/${imageForDesktop}`
                              : null
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 border border-borderGrayColor p-4 rounded-lg">
                      <b className=" text-[#4B4B4B]">
                        What will be the title of your Ads?
                      </b>

                      <Input
                        placeholder="Enter title here"
                        className="text-base border-none"
                        value={formData.title}
                        showCount
                        maxLength={100}
                        onChange={(e) =>
                          onChangeHandleMonetizationBlog(
                            'title',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-y-2 border border-borderGrayColor p-4 rounded-lg">
                      <b className=" text-[#4B4B4B]">
                        Please enter the URL of your Ads
                      </b>

                      <Input
                        placeholder="paste url here"
                        className="text-base border-none"
                        value={formData?.url}
                        onChange={(e) =>
                          onChangeHandleMonetizationBlog('url', e.target.value)
                        }
                      />
                    </div>

                    <div className=" gap-3  ">
                      <div className="border border-borderGrayColor p-4 rounded-lg w-full  flex justify-between items-center">
                        <div>
                          <b className="text-lg text-[#4B4B4B]">
                            Activate your ads for school
                          </b>
                          <p className="text-[#a9a7a7] text-xs font-medium">
                            By turn the toggle on your blog will show ads of
                            your choosing
                          </p>
                        </div>

                        <Switch
                          style={
                            !isMonetized
                              ? { backgroundColor: '#F6F6F6' }
                              : { backgroundColor: '#ff5612' }
                          }
                          checked={isMonetized}
                          onChange={(value) => {
                            if (isMonetized != null) {
                              setIsMonetized(!isMonetized);
                            }
                            setFormData({ ...formData, active: value });
                          }}
                          //changed functionality switch button taken inside modal, so isMonetizationStatus Disabled
                          /*  onChange={isMonetizationStatus}*/
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center p-6 justify-center  space-x-2  border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={() => setModalOpen(false)}
                      type="button"
                      className="w-full text-black border rounded-lg font-bold p-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="w-full text-white bg-primary rounded-lg font-bold p-2 "
                      onClick={submitForm}
                    >
                      Submit
                    </button>
                  </div>
                </Modal>
              </div>
              {allBlogsData?.data ? (
                <CheckPermission check="view-all-posts">
                  <div className="mb-6">
                    <div className="grid grid-cols-3 gap-2 mb-2 w-full xl:w-[30%] lg:w-[30%] ">
                      <div
                        onClick={() => handleTabClick('published')}
                        className={`grid col-span-1 bg-[#F6F6F9] rounded-3xl text-center py-2  cursor-pointer ${
                          activeTab === 'published'
                            ? 'border-primary text-primary font-bold  bg-[#FFF3F0]'
                            : ''
                        }`}
                      >
                        Published
                      </div>
                      <div
                        onClick={() => handleTabClick('drafts')}
                        /*  onClick={() => getBlogResponseBySchoolId('is_draft')}*/
                        className={`grid col-span-1 bg-[#F6F6F9] rounded-3xl text-center hover:text-primary py-2  cursor-pointer ${
                          activeTab === 'drafts'
                            ? 'border-primary text-primary font-bold  bg-[#FFF3F0]'
                            : ''
                        }`}
                      >
                        Drafts
                      </div>
                      <div
                        onClick={() => handleTabClick('trash')}
                        className={`grid col-span-1 bg-[#F6F6F9] rounded-3xl text-center py-2  hover:text-primary  cursor-pointer ${
                          activeTab === 'trash'
                            ? 'border-primary text-primary font-bold  bg-[#FFF3F0]'
                            : ''
                        }`}
                      >
                        Trash
                      </div>
                    </div>

                    {/* Content based on active tab */}
                    {activeTab === 'published' && (
                      <div>
                        {allBlogsData?.data &&
                          allBlogsData?.data?.map((items, index: number) => (
                            <div
                              key={index}
                              className="group  border-b border-[#EAEAEA] hover:bg-[#FFF3F0] py-2 active-btn"
                            >
                              <div className="flex flex-row items-center">
                                <div className="w-full p-4">
                                  <div className="flex flex-row gap-10 items-center">
                                    <div>
                                      <b className="text-xl">{index + 1}</b>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                      <div>
                                        <b className="text-[#B4B4B4]">
                                          {format(
                                            new Date(items?.created_at),
                                            'dd MMM yy'
                                          )}
                                        </b>
                                      </div>
                                      <div className="hover:cursor-pointer text-xl ">
                                        <b>{truncateText(items.title, 12)}</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="w-full flex justify-end p-4 gap-10 items-center">
                                  <div className="group flex gap-x-2">
                                    <CheckPermission check="view-posts">
                                      <button
                                        onClick={() => viewBlogById(items?.id)}
                                        className="px-8  rounded-full py-2 bg-white border-primary border text-black opacity-0 group-hover:opacity-100 transition-opacity"
                                      >
                                        Preview
                                      </button>
                                    </CheckPermission>
                                    <CheckPermission check="edit-posts">
                                      <button
                                        onClick={() =>
                                          handlEditBlogById(items?.id)
                                        }
                                        className="px-12 bg-primary rounded-full py-2 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                      >
                                        Edit
                                      </button>
                                    </CheckPermission>
                                  </div>

                                  <div
                                    onClick={() => {
                                      setSeoDataBlog({
                                        keywords: items?.keywords || '',
                                        meta_description:
                                          items?.meta_description || '',
                                        slug: items?.slug || '',
                                        title: items?.title || '',
                                        canonical: items?.canonical || '',
                                      });
                                    }}
                                    className="w-5 h-5 cursor-pointer"
                                  >
                                    <SidePopup
                                      seoData={seoDataBlog}
                                      handleDeleteBlog={() =>
                                        handleDeleteBlog(items?.id)
                                      }
                                      handleRestorePost={undefined}
                                      secondOption={'Blog report'}
                                      deleteText={'Delete Blog'}
                                      placement="left"
                                      id={items?.id}
                                      isPost={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {activeTab === 'drafts' && (
                      <div>
                        {allBlogsData?.data &&
                          allBlogsData?.data?.map((items, index: number) => (
                            <div
                              key={index}
                              className="group  border-b border-[#EAEAEA] hover:bg-[#FFF3F0] active-btn"
                            >
                              <div className="flex flex-row items-center">
                                <div className="w-full p-4">
                                  <div className="flex flex-row gap-10 items-center">
                                    <div>
                                      <b className="text-xl">{index + 1}</b>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                      <div>
                                        <b className="text-[#B4B4B4]">
                                          {format(
                                            new Date(items?.created_at),
                                            'dd MMM yy'
                                          )}
                                        </b>
                                      </div>
                                      <div
                                        // onClick={() => viewBlogById(items?.id)}
                                        className="hover:cursor-pointer "
                                      >
                                        <b>{truncateText(items.title, 12)}</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="w-full flex justify-end p-4 gap-10 items-center">
                                  <div className="group">
                                    <button
                                      onClick={() => viewBlogById(items?.id)}
                                      className="px-8  rounded-full py-2 bg-white border-primary border text-black opacity-0 group-hover:opacity-100 transition-opacity"
                                    >
                                      Preview
                                    </button>
                                    <button
                                      onClick={() =>
                                        handlEditBlogById(items?.id)
                                      }
                                      className="px-12 bg-primary rounded-full py-2 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                    >
                                      Edit
                                    </button>
                                  </div>

                                  <div className="w-5 h-5 cursor-pointer">
                                    <SidePopup
                                      seoData={seoData}
                                      handleDeleteBlog={() =>
                                        handleDeleteBlog(items?.id)
                                      }
                                      handleRestorePost={undefined}
                                      secondOption={'restore blog'}
                                      deleteText={'Delete Blog'}
                                      placement="left"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {activeTab === 'trash' && (
                      <div>
                        {allBlogsData?.data &&
                          allBlogsData?.data?.map((items, index: number) => (
                            <div
                              key={index}
                              className="group  border-b border-[#EAEAEA] hover:bg-[#FFF3F0] active-btn"
                            >
                              <div className="flex flex-row items-center">
                                <div className="w-full p-4">
                                  <div className="flex flex-row gap-10 items-center">
                                    <div>
                                      <b className="text-xl">{index + 1}</b>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                      <div>
                                        <b className="text-[#B4B4B4]">
                                          {format(
                                            new Date(items?.created_at),
                                            'dd MMM yy'
                                          )}
                                        </b>
                                      </div>
                                      <div
                                        onClick={() => viewBlogById(items?.id)}
                                        className="hover:cursor-pointer "
                                      >
                                        <b>{truncateText(items.title, 12)}</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="w-full flex justify-end p-4 gap-10 items-center">
                                  <div className="group">
                                    <button
                                      // onClick={() => handlEditBlogById(items?.id)}
                                      className="px-12 bg-primary rounded-full py-2 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                                    >
                                      Edit
                                    </button>
                                  </div>

                                  <div className="w-5 h-5 cursor-pointer">
                                    <SidePopup
                                      seoData={seoData}
                                      handleDeleteBlog={() =>
                                        handleDeleteBlog(items?.id)
                                      }
                                      handleRestorePost={() =>
                                        handleRestorePost(items?.id)
                                      }
                                      secondOption={'restore blog'}
                                      deleteText={'Delete Blog'}
                                      placement="left"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </CheckPermission>
              ) : (
                <>
                  <div className="rounded-xl border border-lightgray p-20">
                    <div className="flex items-center justify-center gap-2">
                      <div className="flex flex-col items-center justify-center gap-6">
                        <div>
                          <img src={CREATE_BLOG} alt="CREATE_BLOG" />
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          <b className="text-black text-2xl">
                            Create your chapter
                          </b>
                          <span className="text-gray-500 text-base">
                            School are just group or series of blogs
                          </span>
                        </div>

                        <button
                          onClick={handleCreateBlogs}
                          type="button"
                          className="rounded-xl border text-base bg-[#FFF3F0] w-full py-4 text-primary"
                        >
                          <b>Create Chapter</b>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </>
    </>
  );
}

export default SchoolDetailView;
