import {
  createBlogsApi,
  editBlogsApi,
  editMyBlogBySchoolIdApi,
} from '../../../../services/apiservices';
import Loader from '../../../../components/ui-elements/Loader/Loader';
import { toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import Quill from 'quill'; // Import Quill
import ImageResize from 'quill-image-resize'; // Import Image Resize module
import { Button } from 'antd';
import Editor, { Reader } from './Editor';

Quill.register('modules/imageResize', ImageResize); // Register the Image Resize module

interface FormData {
  title: string;
  BlogData: Reader[];
}

const CreateBlog = () => {
  const navigate = useNavigate();
  const [blogContent, setBlogContent] = useState([]);
  const [isSaveActive, setIsSaveActive] = useState(false);
  const { id, optionalid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    BlogData: [
      {
        id: 1702372324769,
        type: 'title',
        value: '',
      },
      {
        id: 1702372324762,
        type: 'paragraph',
        value: '',
      },
    ],
  });

  useEffect(() => {
    const getMyBlogContents = async (optionalid: string) => {
      try {
        setIsLoading(true);
        const response = await editMyBlogBySchoolIdApi(optionalid);
        const { data } = response.data;
        if (data) {
          // Set the pre-filled data in the state
          setBlogContent(JSON.parse(data?.json_content));
          setFormData({
            title: data.title,
            BlogData: JSON.parse(data?.json_content),
          });
        }
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (optionalid) {
      getMyBlogContents(optionalid);
    }
  }, [optionalid]);

  const array = formData.BlogData as {
    type: string;
    value: string;
  }[];
  const paragraphObjects = array?.filter(
    (item) => item.type === 'paragraph' || item.type === 'html'
  );

  const desc = paragraphObjects?.map((item) => item.value).join(' ');

  const handleQuillEditor = async (isDraft = 0) => {
    try {
      const response = await createBlogsApi({
        school_id: id,
        title: formData.title,
        is_draft: isDraft,
        description: desc,
        json_content: JSON.stringify(formData.BlogData),
      });
      navigate(`/school/schoolblog-view/${id}`);
      toast.success(
        isDraft
          ? 'Blog saved to draft successfully!'
          : 'blog published successfully!'
      );
      console.log(response, 'response from draf blog');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      console.error('API call error:', error);
    }

    setIsSaveActive(true);
    setTimeout(() => {
      setIsSaveActive(false);
    }, 3000);
  };

  console.log(desc, 'desc');

  const editBlog = async (optionalid: string, data: any, isDraft = 0) => {
    try {
      await editBlogsApi(optionalid as string, {
        school_id: id,
        title: formData?.title,
        is_draft: isDraft,
        description: desc,
        json_content: JSON.stringify(formData.BlogData),
      });
      navigate(`/school/schoolblog-view/${id}`);
      toast.success(
        isDraft
          ? 'Blog saved to draft successfully!'
          : 'blog Updated successfully!'
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      console.error('API call error: ', error);
    }

    setIsSaveActive(true);
    setTimeout(() => {
      setIsSaveActive(false);
    }, 3000);
  };

  const GotoPreviousPage = async () => {
    // try {
    //   await draftBlogsApi({
    //     school_id: id,
    //     title: inputValue, // Get the title from the input field
    //     content: blogContent,
    //     is_draft: 1,
    //   });
    //   navigate(`/school/schoolblog-view/${id}`);
    //   toast.success('blog saved in draft');
    //
    // } catch (error) {
    //   console.error('API call error:', error);
    // }
    navigate(`/school/schoolblog-view/${id}`);
  };

  return (
    <>
      {!isLoading ? (
        <div className="flex flex-col ">
          <div className="flex justify-between px-6 py-3  ">
            <Button
              onClick={() => {
                GotoPreviousPage();
              }}
              className=" h-10  px-3 rounded-lg flex gap-x-3 items-center "
            >
              <span>
                <LeftOutlined className="text-gray-500 w-4 h-4" />
              </span>

              <p className="text-base">Back</p>
            </Button>

            <div className="flex gap-x-3">
              <Button
                disabled={isSaveActive}
                onClick={() => {
                  if (optionalid) {
                    editBlog(optionalid as string, blogContent, 1);
                  } else {
                    handleQuillEditor(1);
                  }
                }}
                className="font-medium bg-[#F6F6F9] text-lg border-none  px-5 h-10 rounded-lg "
              >
                Save & Preview
              </Button>
              <Button
                disabled={isSaveActive}
                loading={isSaveActive}
                onClick={() => {
                  if (optionalid) {
                    editBlog(optionalid as string, blogContent);
                  } else {
                    handleQuillEditor();
                  }
                }}
                className="font-medium bg-[#FFF3F0] text-lg h-10 text-primary border-none px-5 rounded-lg "
              >
                {!isSaveActive ? 'Publish' : 'Publish..'}
              </Button>
            </div>
          </div>
          <div className="flex  justify-center w-full h-full">
            <span className="z-10 w-4/5 md:w-3/5 xl:w-1/2">
              <Editor setFormData={setFormData} formData={formData} />
            </span>
          </div>
          {/* {optionalid ? (
            <div>
              <h3>Preview:</h3>
              <div dangerouslySetInnerHTML={{ __html: blogContent }} />
            </div>
          ) : (
            ''
          )} */}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CreateBlog;
