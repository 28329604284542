import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckIcon } from '@heroicons/react/outline';
import { ICondition } from '../../../utills/interfaces/BrodcastMessages';

export interface IData {
  name: string;
  image: string;
  desc?: string;
  event: string;
  operator: string;
  product_type: string;
  value: string;
  id: number;
  source: string;
}

interface CardWithCheckboxProps {
  data: IData;
  isChecked: boolean;
  onChange: (data: ICondition, addItem: boolean) => void;
}

const CardWithCheckbox: React.FC<CardWithCheckboxProps> = ({
  data,
  isChecked,
  onChange,
}) => {
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    onChange(
      {
        event: data?.event,
        operator: data?.operator,
        product_id: `${data.id}`,
        product_type: data?.product_type,
        source: data?.source,
        value: data?.value,
      },
      e.target.checked
    );
  };

  const handleCardClick = () => {
    onChange(
      {
        event: data?.event,
        operator: data?.operator,
        product_id: `${data.id}`,
        product_type: data?.product_type,
        source: data?.source,
        value: data?.value,
      },
      !isChecked
    );
  };
  return (
    <div
      className="flex items-center hover:bg-[#f3f0f0] cursor-pointer bg-white rounded-lg justify-between p-4"
      onClick={handleCardClick}
    >
      <div className="flex items-center">
        <img
          src={data?.image}
          alt={data?.name}
          className="w-16 rounded-md h-20 mr-4"
        />
        <div className="flex flex-col">
          <span className="font-medium text-lg">{data?.name}</span>
        </div>
      </div>
      <label className="rounded-full  flex items-center justify-center cursor-pointer">
        <Checkbox
          className="hidden"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div
          className={`w-5 p-.5  h-5 rounded-full flex items-center justify-center ${
            isChecked ? 'bg-primary' : 'bg-lightgray'
          }`}
        >
          {isChecked && <CheckIcon className="w-4 h-4 text-white" />}
        </div>
      </label>
    </div>
  );
};

export default CardWithCheckbox;
