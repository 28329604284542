import { ExclamationCircleIcon, XIcon } from '@heroicons/react/outline';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCategoriesApi } from '../../../services/apiservices';
import UploadBannerImage from '../CreateLiveForm/UploadBannerImage';
import { formateErrorObject } from '../../basic/formateError';
import { notify } from '../../basic/notity';
import {
  advertisementsI,
  assignAdvertisementsApi,
  createSessionAdvertisementsApi,
  getSingleAdvertisementApi,
  updateSessionAdvertisementsApi,
} from '../../../services/liveServices';
import { ShouldRender } from '../../basic/ShouldRender';
import Loader from '../../ui-elements/Loader/Loader';

interface Props {
  visible: {
    name: string;
    value: boolean;
    sessionId: string;
    advertisementId: string;
  };
  closeModal: () => void;
}

const LiveAddModal: React.FC<Props> = ({ closeModal, visible }) => {
  const [form] = Form.useForm();
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState<
    { value: string; label: string }[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<advertisementsI>({
    category: null,
    title: '',
    url: '',
    thumbnail: '',
  });

  const handleClose = () => {
    closeModal();
    setFormData({
      category: null,
      title: '',
      url: '',
      thumbnail: '',
    });
    form.resetFields();
  };
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      if (visible?.advertisementId) {
        await updateSessionAdvertisementsApi(
          {
            category: formData?.category as string,
            thumbnail: formData?.thumbnail,
            title: formData?.title,
            url: formData?.url,
            _method: 'patch',
          },
          visible?.advertisementId
        );
        notify(`Advertisement updated created for ${visible.name}`, 'success');
        handleClose();
      } else {
        const response = await createSessionAdvertisementsApi({
          category: formData?.category as string,
          thumbnail: formData?.thumbnail,
          title: formData?.title,
          url: formData?.url,
        });
        await assignAdvertisementsApi({
          module_id: visible?.sessionId as string,
          advertisement_id: response?.data?.data?.id as string,
          active: 1,
        });
        notify(
          `Advertisement successfully created for ${visible.name}`,
          'success'
        );
        handleClose();
      }

      // navigate(`/live-session/${id}`);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string | number }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getSingleAdvertisementData = async (id: string) => {
    try {
      setLoading(true);

      const response = await getSingleAdvertisementApi(id);
      setFormData({
        category: response?.data?.data?.category,
        thumbnail: response?.data?.data?.advertisement_thumbnail?.original_url,
        title: response?.data?.data?.title,
        url: response?.data?.data?.url,
      });
      form.setFieldsValue({
        category: response?.data?.data?.category,
        thumbnail: response?.data?.data?.advertisement_thumbnail?.original_url,
        title: response?.data?.data?.advertisement_thumbnail?.title,
        url: response?.data?.data?.advertisement_thumbnail?.url,
      });
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value: string | null, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleValuesChange = (changedValues: any) => {
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const getCategoriesData = async () => {
    try {
      setCategoryLoading(true);
      const response = await getCategoriesApi();
      setCategoryData(
        response?.data?.data?.map((category: any) => {
          return {
            value: category?.name,
            label: category?.name,
          };
        })
      );
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setCategoryLoading(false);
    }
  };

  useEffect(() => {
    if (visible?.value) {
      getCategoriesData();
    }
    if (visible?.advertisementId) {
      getSingleAdvertisementData(visible?.advertisementId);
    }
  }, [visible]);

  const handleInputChange = (
    name: string,
    value: string | File | undefined
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isValidURL = (url: string) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(url);
  };

  return (
    <Modal
      open={visible.value}
      maskClosable={false}
      closable={false}
      footer={null}
      centered
      onCancel={handleClose}
      className="custom-modal"
    >
      <>
        <ShouldRender check={!loading}>
          <div className="flex flex-col ">
            <span className="flex justify-between px-7 rounded-t-xl bg-[#F6F6F6]  py-4">
              <span>
                <p className="text-lg font-medium">
                  Activate your ads for `<b>{visible?.name}</b>`
                </p>
                <p className="text-[#737373]">Add images and run your ads</p>
              </span>
              <div className="flex items-center">
                <div
                  onClick={() => handleClose()}
                  className="border border-borderGrayColor cursor-pointer p-1 w-7 h-7 rounded-full flex justify-center items-center"
                >
                  <XIcon className="w-4 h-4" />
                </div>
              </div>
            </span>

            <Form
              form={form}
              layout="vertical"
              className="flex  p-5 gap-y-4 flex-col"
              // onFinish={onFinish}
              initialValues={formData}
              onValuesChange={(changedValues) => {
                // Update formData on field value changes
                handleValuesChange(changedValues);
              }}
            >
              <Form.Item
                name="category"
                valuePropName="category"
                rules={[
                  {
                    validator: (_, value) => {
                      const categoryValue =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;
                      if (categoryValue) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please select category!')
                        );
                      }
                    },
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    height: '48px',
                  }}
                  filterOption={filterOption}
                  size="large"
                  loading={categoryLoading}
                  value={formData.category}
                  placeholder="Type and choose category"
                  onChange={(value) => handleSelectChange(value, 'category')}
                  options={categoryData}
                  className="rounded-2xl w-full"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium text-left ">
                    Upload ads image for desktop (of size 10:1 aspect ratio)
                  </span>
                }
                name="thumbnail"
                valuePropName="thumbnail"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!formData.thumbnail) {
                        return Promise.reject('Please provide  thumbnail!');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <UploadBannerImage
                  image={formData.thumbnail ? formData.thumbnail : null}
                  onFileChange={(file) => handleInputChange('thumbnail', file)}
                  onImageRemove={() =>
                    handleInputChange('thumbnail', undefined)
                  }
                  // isSession={true}
                />
              </Form.Item>

              <Form.Item
                name="title"
                label={
                  <span className="text-base text-fadedWhite font-medium text-left ">
                    What will be the title of your Ads?
                  </span>
                }
                valuePropName="title"
                className="flex flex-col gap-y-2 border  border-borderGrayColor p-4 rounded-lg"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!formData.title) {
                        return Promise.reject('Please enter  title!');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter title here"
                  className="text-base border-none mb-4"
                  value={formData.title}
                  showCount
                  maxLength={100}
                  onChange={(e) => handleInputChange('title', e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="url"
                label={
                  <span className="text-base text-fadedWhite font-medium text-left ">
                    Please enter the URL of your Ads
                  </span>
                }
                valuePropName="url"
                className="flex flex-col gap-y-2 border pb-4 border-borderGrayColor p-4 rounded-lg"
                rules={[
                  {
                    validator: (_, value) => {
                      if (formData.url) {
                        if (isValidURL(formData.url)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Please enter a valid URL!')
                          );
                        }
                      } else {
                        return Promise.reject(new Error('Please enter url!'));
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Paste URL here"
                  className="text-base border-none mb-4"
                  value={formData.url}
                  onChange={(e) => handleInputChange('url', e.target.value)}
                />
              </Form.Item>
              <span className="flex  w-full ">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="rounded-xl w-full font-medium  bg-black text-white hover:bg-[#2b2b2b] px-6  py-3   "
                >
                  Activate ads for `<b>{visible?.name}</b>`
                </button>
              </span>
            </Form>
          </div>
        </ShouldRender>
        <ShouldRender check={loading}>
          <div className="h-[80vh] flex justify-center items-center">
            <Spin size="large" />
          </div>
        </ShouldRender>
      </>
    </Modal>
  );
};

export default LiveAddModal;
