import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Button, DatePicker, Form, Input, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  createSession,
  getSingleSessionApi,
  updateSession,
} from '../../../services/liveServices';
import { notify } from '../../../components/basic/notity';
import { formateErrorObject } from '../../../components/basic/formateError';
import UploadBannerImage from '../../../components/Live/CreateLiveForm/UploadBannerImage';
import dayjs from 'dayjs';
import { ShouldRender } from '../../../components/basic/ShouldRender';
import Loader from '../../../components/ui-elements/Loader/Loader';

export interface StatusI {
  desc: string;
  end_date: string;
  end_time: string;
  id: number;
  start_date: string;
  start_time: string;
  status: string;
  thumbnail: string;
  title: string;
}

export interface FormDataSessionI {
  session_title: string;
  session_description: string;
  startDate: string;
  startTime: string;
  endTime: string;
  endDate: string;
  session_thumbnail: string;
  youtube_link: string;
}

const CreateSessionPage: React.FC = () => {
  const [form] = Form.useForm();

  const [formData, setFormData] = useState({
    session_title: '',
    session_description: '',
    startDate: '',
    startTime: '',
    endTime: '',
    endDate: '',
    session_thumbnail: '',
    youtube_link: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id, sessionId } = useParams();

  const handleInputChange = (
    name: string,
    value: string | File | undefined
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleValuesChange = (changedValues: any) => {
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      if (!isValidURL(formData?.youtube_link)) {
        return notify('Please enter valid Youtube link!!', 'error');
      }
      if (sessionId) {
        await updateSession(
          {
            title: formData?.session_title,
            desc: formData?.session_description,
            yt_video_id: formData?.youtube_link,
            start_date: formData?.startDate,
            end_date: formData?.endDate,
            start_time: formData?.startTime,
            end_time: formData?.endTime,
            live_workshop_id: id as string,
            thumbnail: formData?.session_thumbnail,
            is_active: 1,
            completed: 0,
            _method: 'patch',
          },
          sessionId
        );
        notify('Session updated successfully!!', 'success');
      } else {
        await createSession({
          title: formData?.session_title,
          desc: formData?.session_description,
          yt_video_id: formData?.youtube_link,
          start_date: formData?.startDate,
          end_date: formData?.endDate,
          start_time: formData?.startTime,
          end_time: formData?.endTime,
          live_workshop_id: id as string,
          thumbnail: formData?.session_thumbnail,
          is_active: 1,
          completed: 0,
        });
        notify('Session created successfully!!', 'success');
      }

      navigate(`/live-session/${id}`);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formatData = (obj: any) => {
    return {
      session_title: obj?.title || '',
      session_description: obj?.desc || '',
      startDate: obj?.start_date || '',
      startTime: obj?.start_time || '',
      endTime: obj?.end_time || '',
      endDate: obj?.end_date || '',
      session_thumbnail: obj?.live_workshop_session_thumbnails
        ? obj.live_workshop_session_thumbnails.original_url
        : '',
      youtube_link: obj?.yt_video_id || '',
    };
  };

  const getSingleSessionData = async (id: string) => {
    try {
      setLoading(true);
      const response = await getSingleSessionApi(id);
      const formattedData = formatData(response?.data?.data);
      setFormData(formattedData);
      form.setFieldsValue(formattedData);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sessionId) {
      getSingleSessionData(sessionId);
    }
  }, [sessionId]);

  const isValidURL = (url: string) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(url);
  };

  return (
    <>
      <ShouldRender check={!loading}>
        <div className="flex sticky top-0 w-full bg-white z-20  flex-row justify-between gap-4 pl-9 pb-3 pt-3 border-y border-[#e2e2e2]">
          <div className="flex flex-row items-center gap-6 z-">
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/live-session/${id}`);
              }}
              size="large"
              className="rounded-xl cursor-pointer group"
            >
              <p className="flex gap-2 items-center">
                <ChevronLeftIcon className="w-4 text-[#afaeae] group-hover:text-primary font-bold" />
                Back
              </p>
            </Button>
            <div className="border-l  h-10 text-[#e2e2e2]  " />
            <span className="text-xl font-medium">
              {sessionId ? 'Update Session' : 'Create Session'}
            </span>
          </div>
          <div className="flex items-center pr-10 z-40">
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="rounded-xl text-base font-bold  bg-[#FFF3F0] px-8 py-2 text-primary "
            >
              <b className="font-medium text-sm">Save</b>
            </button>
          </div>
        </div>
        <div className="flex justify-center pt-10 pb-20">
          <div className=" w-4/5 pr-20 flex gap-y-5 flex-col">
            <Form
              form={form}
              layout="horizontal"
              className="flex gap-y-10 flex-col"
              initialValues={formData}
              onValuesChange={(changedValues) => {
                handleValuesChange(changedValues);
              }}
            >
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-2">
                    Session’s Title
                  </span>
                }
                name="session_title"
                valuePropName="session_title"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!formData.session_title) {
                        return Promise.reject('Please enter session title!');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  name="session_title"
                  placeholder="Session’s Title"
                  value={formData.session_title}
                  onChange={(e) =>
                    handleInputChange('session_title', e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-2">
                    Session’s Description
                  </span>
                }
                name="session_description"
                valuePropName="session_description"
                rules={[
                  {
                    validator: (_, value) => {
                      const sessionDescriptionValue =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;

                      if (sessionDescriptionValue) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error(`Please enter your Session's Description!`)
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  name="session_description"
                  placeholder="Enter Session's Description"
                  value={formData.session_description}
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3   bg-bgColor customInputBlack makeBgBlack"
                  onChange={(e) =>
                    handleInputChange('session_description', e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium min-w-40 text-left pt-2">
                    Youtube Link
                  </span>
                }
                name="youtube_link"
                valuePropName="youtube_link"
                rules={[
                  {
                    validator: (_, value) => {
                      const youtubeLinkValue =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;

                      if (youtubeLinkValue) {
                        if (
                          isValidURL(youtubeLinkValue) &&
                          youtubeLinkValue.includes('youtube.com/live')
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Please enter a valid YouTube URL!')
                          );
                        }
                      } else {
                        return Promise.reject(
                          new Error('Please enter YouTube link!')
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  name="youtube_link"
                  placeholder="Session’s Title"
                  value={formData.youtube_link}
                  onChange={(e) =>
                    handleInputChange('youtube_link', e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-10">
                    Session thumbnail
                  </span>
                }
                name="session_thumbnail"
                valuePropName="session_thumbnail"
                rules={[
                  {
                    validator: (_, value) => {
                      const livebannerValue =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;

                      if (livebannerValue) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please enter your session thumbnail!')
                        );
                      }
                    },
                  },
                ]}
              >
                <div className="flex">
                  <span className="w-full text-textLiteGray mt-7">
                    This picture will be visible to all
                  </span>
                  <span className="w-full">
                    <UploadBannerImage
                      image={
                        formData.session_thumbnail
                          ? formData.session_thumbnail
                          : null
                      }
                      onFileChange={(file) =>
                        handleInputChange('session_thumbnail', file)
                      }
                      onImageRemove={() =>
                        handleInputChange('session_thumbnail', undefined)
                      }
                      isSession={true}
                    />
                  </span>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-2">
                    Start Time - End Time
                  </span>
                }
                name="startEndTime"
                valuePropName="startTime"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!formData.startTime || !formData.endTime) {
                        return Promise.reject(
                          'Please select both start and end times!'
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <TimePicker.RangePicker
                  size="large"
                  className="rounded-xl ml-1"
                  value={[
                    formData.startTime
                      ? dayjs(formData.startTime, 'HH:mm:ss')
                      : null,
                    formData.endTime
                      ? dayjs(formData.endTime, 'HH:mm:ss')
                      : null,
                  ]}
                  onChange={(_, timeStrings) => {
                    const [startTime, endTime] = timeStrings;
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      startTime,
                      endTime,
                    }));
                  }}
                  format="HH:mm:ss"
                  defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-2">
                    Start Date - End Date
                  </span>
                }
                name="startEndDate"
                valuePropName="startEndDate"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!formData.startDate || !formData.endDate) {
                        return Promise.reject(
                          'Please select both start and end date!'
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <DatePicker.RangePicker
                  size="large"
                  className="rounded-xl ml-1"
                  value={[
                    formData.startDate
                      ? dayjs(formData.startDate, 'YYYY-MM-DD')
                      : null,
                    formData.endDate
                      ? dayjs(formData.endDate, 'YYYY-MM-DD')
                      : null,
                  ]}
                  onChange={(_, dateStrings) => {
                    const [startDate, endDate] = dateStrings;
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      startDate,
                      endDate,
                    }));
                  }}
                  format="YYYY-MM-DD"
                  defaultPickerValue={[dayjs(), dayjs()]}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </ShouldRender>
      <ShouldRender check={loading}>
        <Loader />
      </ShouldRender>
    </>
  );
};

export default CreateSessionPage;
