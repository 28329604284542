import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { checkSessionExists, setTokenInCookies } from '../config/appwrite';
import { Config } from '../config/config';

const apiClientForCourses = axios.create({
  baseURL: Config.BASE_API_URL_COURSES,
  timeout: 30000,
});

apiClientForCourses.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const authToken = Cookies.get('auth_token');
    config.headers = config.headers || {};

    if (authToken) {
      config.headers['Authorization'] = `${authToken}`;
    } else {
      const session = await checkSessionExists();
      if (session) {
        const token = await setTokenInCookies();
        config.headers['Authorization'] = `${token}`;
      }
    }
    return config;
  }
);

export default apiClientForCourses;
