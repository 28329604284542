import React, { useState } from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Editor, { Reader } from '../../schools/schools-blog/blogs/Editor';
import { useNavigate } from 'react-router';

interface FormData {
  title: string;
  BlogData: Reader[];
}

const CreateNewsLetter: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    title: '',
    BlogData: [
      {
        id: 1702372324769,
        type: 'title',
        value: '',
      },
      {
        id: 1702372324762,
        type: 'paragraph',
        value: '',
      },
    ],
  });

  return (
    <>
      <div className="flex flex-col ">
        <div className="flex justify-between px-6 py-3">
          <Button
            onClick={() => {
              navigate('/newsletters');
            }}
            className=" h-10  px-3 rounded-lg flex gap-x-3 items-center "
          >
            <span>
              <LeftOutlined className="text-gray-500 w-4 h-4" />
            </span>

            <p className="text-base">Back</p>
          </Button>

          <div className="flex gap-x-3">
            <Button
              onClick={() => {
                console.log('this is something');
              }}
              className="font-medium bg-[#F6F6F9] text-lg border-none px-5 h-10 rounded-lg "
            >
              Save & Preview
            </Button>
            <Button className="font-medium bg-[#FFF3F0] text-lg h-10 text-primary border-none px-5 rounded-lg ">
              publish
            </Button>
          </div>
        </div>

        <div className="flex  justify-center w-full h-full">
          <span className="z-10 w-4/5 md:w-3/5 xl:w-1/2">
            <Editor setFormData={setFormData} formData={formData} />
          </span>
        </div>
      </div>
    </>
  );
};

export default CreateNewsLetter;
