import React, { useEffect, useState } from 'react';
import { Layout, theme } from 'antd';
import Header from '../components/header/Header';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import { useRecoilState } from 'recoil';
import { tokenUpdate } from '../atoms/authModalControl';
import appwriteService, { setTokenInCookies } from '../config/appwrite';
import { AppwriteUserData, UserData } from '../utills/interfaces/user';
import { appWriteUser, getAbilitiesApiCall } from '../actions/useAuth';
import { setToken } from '../utills/lib/auth';
import { UserInfoInterface } from '../utills/interfaces/interface';
import { user } from '../atoms/user';

const { Content } = Layout;

const DefaultLayout: React.FC = () => {
  const {
    token: {},
  } = theme.useToken();
  const navigate = useNavigate();
  const [, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const googleLoginCompleted = searchParams.get('googleLoginCompleted');
  const [, setUserData] = useRecoilState<UserInfoInterface | undefined>(user);

  const isCreateBlogs = location.pathname.includes('/create-blogs');
  const isLiveSessionCreate =
    /(\/create-session\/|\/live-session\/|\/live-session\/edit-session\/)/i.test(
      location.pathname
    );
  const isMessagesPage = location.pathname.includes('/broadcast/messages');
  const handlePostSignUpData = async (response: {
    dbUser: UserInfoInterface;
    token: string;
  }) => {
    await setToken(response?.token);

    setUserData(response?.dbUser);

    setIsTokenChanged(true);
  };

  const handleFetchAbilities = async () => {
    try {
      const response = await getAbilitiesApiCall();
      const abilitiesData = response?.data?.data || [];
      localStorage.setItem('abilitiesData', JSON.stringify(abilitiesData));
    } catch (error) {
      console.error('API call error:', error);
    }
  };

  useEffect(() => {
    const fetchTokenAndTriggerChange = async () => {
      if (googleLoginCompleted === 'true') {
        setIsTokenChanged(true);
        const token = await setTokenInCookies();

        const user = await appwriteService.getCurrentUser();
        const currentUser = user as unknown as AppwriteUserData;
        const dbUser: UserInfoInterface = await appWriteUser(
          currentUser?.$id as string
        );
        if (token) {
          await appWriteUser(currentUser?.$id as string);
          await handleFetchAbilities();
        }

        await handlePostSignUpData({ dbUser, token });
        const { pathname } = location;
        navigate(pathname, { replace: true });
      }
    };
    fetchTokenAndTriggerChange();
  }, [googleLoginCompleted]);

  return (
    <Layout className="h-[100vh] overflow-hidden bg-white">
      {!isCreateBlogs && (
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}

      <Layout className="flex flex-row">
        {!isCreateBlogs && (
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        )}

        <Layout
          className={`${
            isLiveSessionCreate
              ? 'h-[92vh]'
              : !isCreateBlogs
              ? 'p-4 h-[92vh] overflow-auto'
              : ''
          } bg-white w-full overflow-auto`}
        >
          <Content>
            <main>
              <div>
                <Outlet />
              </div>
            </main>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
