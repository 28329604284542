import { PlusIcon } from '@heroicons/react/outline';
import { Button, Form, FormInstance, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { Dispatch, SetStateAction } from 'react';
import UploadBannerImage from './UploadBannerImage';

export interface StatusI {
  desc: string;
  end_date: string;
  end_time: string;
  id: number;
  start_date: string;
  start_time: string;
  status: string;
  thumbnail: string;
  title: string;
  advertisementId?: string;
}

export interface FormDataI {
  authorname: string;
  livetitle: string;
  livedescription: string;
  learningtopic: string[];
  livebanner: any;
  banner_phone: any;
  channelname: string;
  liveIncludes: string[];
  sessions?: StatusI[];
}

interface CreateLiveFormProps {
  form: FormInstance;
  formData: FormDataI;
  setFormData: Dispatch<SetStateAction<FormDataI>>;
}

const CreateLiveForm: React.FC<CreateLiveFormProps> = ({
  form,
  formData,
  setFormData,
}) => {
  const handleInputChange = (
    name: string,
    value: string | File | undefined
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleValuesChange = (changedValues: any) => {
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const handleLiveIncludesChange = (index: number, value: string) => {
    setFormData((prevFormData) => {
      const newLiveIncludes = [...prevFormData.liveIncludes];
      newLiveIncludes[index] = value;
      return {
        ...prevFormData,
        liveIncludes: newLiveIncludes,
      };
    });
  };

  const removeLiveIncludes = (index: number) => {
    if (index >= 1) {
      setFormData((prevFormData) => {
        const newLiveIncludes = prevFormData.liveIncludes.filter(
          (_, i) => i !== index
        );
        return {
          ...prevFormData,
          liveIncludes: newLiveIncludes,
        };
      });
    }
  };

  const addKeyTakeaway = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      liveIncludes: [...prevFormData.liveIncludes, ''],
    }));
  };

  const handleLearningTopicChange = (index: number, value: string) => {
    setFormData((prevFormData) => {
      const newLearningTopics = [...prevFormData.learningtopic];
      newLearningTopics[index] = value;
      return {
        ...prevFormData,
        learningtopic: newLearningTopics,
      };
    });
  };

  const removeLearningTopic = (index: number) => {
    if (index >= 1) {
      setFormData((prevFormData) => {
        const newLearningTopics = prevFormData.learningtopic.filter(
          (_, i) => i !== index
        );
        return {
          ...prevFormData,
          learningtopic: newLearningTopics,
        };
      });
    }
  };

  const addLearningTopic = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      learningtopic: [...prevFormData.learningtopic, ''],
    }));
  };

  const isValidURL = (url: string) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(url);
  };

  return (
    <div className="flex justify-center pt-10 pb-20">
      <div className=" w-4/5 pr-20 flex gap-y-5 flex-col">
        <Form
          form={form}
          layout="horizontal"
          className="flex gap-y-10 flex-col"
          initialValues={formData}
          onValuesChange={(changedValues) => {
            // Update formData on field value changes
            handleValuesChange(changedValues);
          }}
        >
          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-2">
                Author name{' '}
              </span>
            }
            name="authorname"
            valuePropName="authorname"
            rules={[
              {
                validator: (_, value) => {
                  const authornameValue =
                    value && typeof value === 'object'
                      ? value.target.value
                      : value;

                  if (authornameValue) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error('Please enter author name!')
                    );
                  }
                },
              },
            ]}
          >
            <Input
              size="large"
              name="authorname"
              placeholder="Author Name"
              value={formData.authorname}
              className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3   bg-bgColor customInputBlack makeBgBlack"
              onChange={(e) => handleInputChange('authorname', e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-2">
                Live’s Title
              </span>
            }
            name="livetitle"
            valuePropName="livetitle"
            rules={[
              {
                validator: (_, value) => {
                  const livetitleValue =
                    value && typeof value === 'object'
                      ? value.target.value
                      : value;

                  if (livetitleValue) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error('Please enter your live title!')
                    );
                  }
                },
              },
            ]}
          >
            <Input
              size="large"
              name="livetitle"
              placeholder="Enter Live title"
              value={formData.livetitle}
              className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3   bg-bgColor customInputBlack makeBgBlack"
              onChange={(e) => handleInputChange('livetitle', e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base h-full text-fadedWhite font-medium min-w-40 text-left mt-10">
                Live’s Description
              </span>
            }
            name="livedescription"
            valuePropName="livedescription"
            rules={[
              {
                validator: (_, value) => {
                  const livedescriptionValue =
                    value && typeof value === 'object'
                      ? value.target.value
                      : value;

                  if (livedescriptionValue) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error('Please enter your live description!')
                    );
                  }
                },
              },
            ]}
          >
            <TextArea
              size="large"
              name="livedescription"
              rows={6}
              placeholder="Add Awesome description"
              showCount
              maxLength={500}
              value={formData.livedescription}
              className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3   bg-bgColor customInputBlack makeBgBlack"
              onChange={(e) =>
                handleInputChange('livedescription', e.target.value)
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-10">
                Learning Topics
                <p>(Max 6)</p>
              </span>
            }
            name="learningtopic"
            valuePropName="learningtopic"
            rules={[
              {
                validator: async (_, value) => {
                  const learningtopicValues = Array.isArray(value)
                    ? value
                    : [value];
                  const formDataLiveIncludes = formData.learningtopic || [];

                  if (
                    learningtopicValues.length >= 1 &&
                    learningtopicValues.length <= 6
                  ) {
                    if (
                      formDataLiveIncludes.length + learningtopicValues.length >
                      6
                    ) {
                      return Promise.reject(
                        new Error('Cannot add more than 6 entries!')
                      );
                    }

                    for (let i = 0; i < formDataLiveIncludes.length; i++) {
                      const liveInclude = formDataLiveIncludes[i];
                      if (
                        typeof liveInclude !== 'string' ||
                        liveInclude.trim() === ''
                      ) {
                        return Promise.reject(new Error('Invalid entry!'));
                      }
                    }
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        'Must contain at least 1 entry and at most 6 entries!'
                      )
                    );
                  }
                },
              },
            ]}
          >
            {formData.learningtopic.map((learningtopic, index) => (
              <div key={index} className="flex mb-2">
                <TextArea
                  className=" "
                  placeholder={`What will you learn section ${index + 1}`}
                  value={learningtopic}
                  rows={6}
                  // showCount
                  maxLength={500}
                  onChange={(e) =>
                    handleLearningTopicChange(index, e.target.value)
                  }
                />
                {index >= 1 ? (
                  <Button
                    type="link"
                    onClick={() => removeLearningTopic(index)}
                  >
                    Remove
                  </Button>
                ) : null}
              </div>
            ))}
            <button
              type="button"
              disabled={formData?.learningtopic?.length >= 6}
              onClick={addLearningTopic}
              className="rounded-xl  font-bold  disabled:cursor-not-allowed bg-[#F6F6F6] w-full flex justify-center py-4 items-center gap-x-2 hover:text-primary mr-4 "
            >
              Add more <PlusIcon className="w-4" />
            </button>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-10">
                Live Banner (Large)
              </span>
            }
            name="livebanner"
            valuePropName="livebanner"
            rules={[
              {
                validator: (_, value) => {
                  const livebannerValue =
                    value && typeof value === 'object'
                      ? value.target.value
                      : value;

                  if (livebannerValue) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error('Please enter your live banner!')
                    );
                  }
                },
              },
            ]}
          >
            <div className="flex">
              <span className="w-full text-textLiteGray mt-7 px-4">
                This picture will be visible to all
              </span>
              <span className="w-full">
                <UploadBannerImage
                  image={formData.livebanner ? formData.livebanner : null}
                  onFileChange={(file) => handleInputChange('livebanner', file)}
                  onImageRemove={() =>
                    handleInputChange('livebanner', undefined)
                  }
                />
              </span>
            </div>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left mt-10">
                Live Banner (Mobile screens)
              </span>
            }
            name="banner_phone"
            valuePropName="banner_phone"
            rules={[
              {
                validator: (_, value) => {
                  const livebannerValue =
                    value && typeof value === 'object'
                      ? value.target.value
                      : value;

                  if (livebannerValue) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        'Please enter your live banner (Mobile screen)!'
                      )
                    );
                  }
                },
              },
            ]}
          >
            <div className="flex">
              <span className="w-full text-textLiteGray mt-7 px-4">
                This picture will be visible to all
              </span>
              <span className="w-full">
                <UploadBannerImage
                  image={formData.banner_phone ? formData.banner_phone : null}
                  onFileChange={(file) =>
                    handleInputChange('banner_phone', file)
                  }
                  onImageRemove={() =>
                    handleInputChange('banner_phone', undefined)
                  }
                />
              </span>
            </div>
          </Form.Item>

          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left pt-2">
                Add Channel
              </span>
            }
            name="channelname"
            rules={[
              {
                validator: (_, value) => {
                  const channelLinkValue =
                    value && typeof value === 'object'
                      ? value.target.value
                      : value;

                  if (channelLinkValue) {
                    if (
                      isValidURL(channelLinkValue) &&
                      channelLinkValue.includes('youtube.com')
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error('Please enter a valid YouTube URL!')
                      );
                    }
                  } else {
                    return Promise.reject(
                      new Error('Please enter channel link!')
                    );
                  }
                },
              },
            ]}
          >
            <div className="flex">
              <span className="w-2/5 text-textLiteGray">
                <Input
                  size="large"
                  name="channelname"
                  value={formData.channelname}
                  placeholder="Enter Channel link"
                  className="border text-sm focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray py-3 bg-bgColor customInputBlack makeBgBlack"
                  onChange={(e) =>
                    handleInputChange('channelname', e.target.value)
                  }
                />
              </span>
            </div>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-fadedWhite font-medium min-w-40 text-left pt-2">
                Live includes
                <p>(Max 6)</p>
              </span>
            }
            name="liveIncludes"
            rules={[
              // Validator function to ensure the validity of live includes
              {
                validator: async (_, value) => {
                  // Convert value to array if not already an array
                  const liveIncludesValues = Array.isArray(value)
                    ? value
                    : [value];
                  // Get existing live includes from formData or initialize empty array
                  const formDataLiveIncludes = formData.liveIncludes || [];

                  if (
                    // Check if the number of live includes is between 1 and 6
                    liveIncludesValues.length >= 1 &&
                    liveIncludesValues.length <= 6
                  ) {
                    if (
                      // Check if adding new entries exceeds the maximum limit (6)
                      formDataLiveIncludes.length + liveIncludesValues.length >
                      6
                    ) {
                      return Promise.reject(
                        new Error('Cannot add more than 6 entries!')
                      );
                    }
                    // Validate each live include
                    for (let i = 0; i < formDataLiveIncludes.length; i++) {
                      const liveInclude = formDataLiveIncludes[i];
                      if (
                        typeof liveInclude !== 'string' ||
                        liveInclude.trim() === ''
                      ) {
                        // Reject if any live include is invalid
                        return Promise.reject(new Error('Invalid entry!'));
                      }
                    }
                    // Resolve if all validation checks pass
                    return Promise.resolve();
                  } else {
                    // Reject if the number of live includes is not within the valid range
                    return Promise.reject(
                      new Error(
                        'Must contain at least 1 entry and at most 6 entries!'
                      )
                    );
                  }
                },
              },
            ]}
          >
            {formData.liveIncludes.map((liveInclude, index) => (
              <div key={index} className="flex mb-2">
                <Input
                  className=" "
                  placeholder={`What will Live includes ? `}
                  value={liveInclude}
                  size="large"
                  onChange={(e) =>
                    handleLiveIncludesChange(index, e.target.value)
                  }
                />
                {index >= 1 ? (
                  <Button type="link" onClick={() => removeLiveIncludes(index)}>
                    Remove
                  </Button>
                ) : null}
              </div>
            ))}
            <button
              type="button"
              disabled={formData?.liveIncludes?.length >= 6}
              onClick={addKeyTakeaway}
              className="rounded-xl  font-bold  disabled:cursor-not-allowed bg-[#F6F6F6] w-full flex justify-center py-4 items-center gap-x-2 hover:text-primary mr-4 "
            >
              Add more <PlusIcon className="w-4" />
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateLiveForm;
