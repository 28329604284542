import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import LiveTab from '../../components/Live/LiveTab';
import CompletedTab from '../../components/Live/CompletedTab';
import {
  getAllWorkShopsApi,
  updateWorkShopStatus,
} from '../../services/liveServices';
import { useNavigate } from 'react-router-dom';
import { CheckPermission } from '../../components/basic/CheckPermission';

interface Workshop {
  id: number;
  title: string;
  slug: string;
  desc: string;
  session_limit: number;
  author_name: string;
  status: string;
  user_id: number;
  studio_user_id: number;
  session_count: number;
  created_at: string;
  live_workshop_banner: {
    original_url: string;
  };
  live_workshop_thumbnails: {
    original_url: string;
  };
}

export interface WorkshopData {
  id: number;
  title: string;
  slug: string;
  desc: string;
  session_limit: number;
  created_at: string;
  author_name: string;
  status: string;
  user_id: number;
  studio_user_id: number;
  session_count: number;
  live_workshop_banner: string;
  live_workshop_thumbnails: string;
}

const LivePage = () => {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [workShopId, setWorkShopId] = useState<number | undefined>();
  const [workShopData, setWorkShopData] = useState<WorkshopData[]>([]);
  const navigate = useNavigate();

  const formatWorkshopData = (workshopData: Workshop[]): any[] => {
    return workshopData.map((workshop) => ({
      author_name: workshop.author_name,
      live_workshop_banner: workshop?.live_workshop_banner?.original_url,
      title: workshop.title,
      session_count: workshop.session_count,
      status: workshop.status,
      id: workshop.id,
      created_at: workshop.created_at,
    }));
  };

  const getAllWorkShopsData = async (
    status?: 'Approved' | 'Pending' | 'Completed'
  ) => {
    try {
      setLoading(true);
      const response = await getAllWorkShopsApi(status);
      const formattedData = formatWorkshopData(response?.data?.data);
      setWorkShopData(formattedData || []);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (id: number, status: string) => {
    try {
      await updateWorkShopStatus(id, status);
      getAllWorkShopsData();
    } catch (error) {
      console.error('API call error:', error);
    }
  };

  useEffect(() => {
    if (activeKey === '1') {
      getAllWorkShopsData();
    } else {
      getAllWorkShopsData('Completed');
    }
  }, [activeKey]);

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className="px-5 font-medium">Live</div>,
      children: (
        <div>
          <LiveTab
            loading={loading}
            workShopId={workShopId}
            setWorkShopId={setWorkShopId}
            workShopData={workShopData}
            getAllWorkShopsData={getAllWorkShopsData}
            handleUpdateStatus={handleUpdateStatus}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: <div className="px-5 font-medium">Completed live</div>,
      children: (
        <CompletedTab
          loading={loading}
          workShopId={workShopId}
          setWorkShopId={setWorkShopId}
          workShopData={workShopData}
          getAllWorkShopsData={getAllWorkShopsData}
          handleUpdateStatus={handleUpdateStatus}
        />
      ),
    },
  ];

  return (
    <div className="w-full relative mt-5 pb-10 z-10">
      <div className="absolute right-10 -top-2 z-20">
        <CheckPermission check="create-live-workshop-sessions">
          <button
            type="button"
            onClick={() => {
              navigate(`/live-session/create-session`);
            }}
            className="rounded-xl  font-bold  bg-[#F6F6F6] hover:bg-[#e9e7e7]  px-6 py-3 text-black mr-4 "
          >
            <b className="font-medium text-sm">Create new Live</b>
          </button>
        </CheckPermission>
      </div>
      <div className="flex  w-full justify-center">
        <div className="lg:w-4/5">
          <Tabs
            activeKey={activeKey}
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </div>
      </div>

      <span className="border-b absolute top-[38px] border-[#f2f0f0] h-2 w-full" />
    </div>
  );
};

export default LivePage;
