import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Button, Modal } from 'antd';

interface Props {
  visible: { value: boolean; id: number };
  closeModal: () => void;
  handleUpdateStatus: (id: number, value: string) => void;
}

const CompleteConfirmModal: React.FC<Props> = ({
  closeModal,
  visible,
  handleUpdateStatus,
}) => {
  return (
    <Modal
      open={visible.value}
      maskClosable={false}
      closable={false}
      footer={null}
      onCancel={closeModal}
    >
      <div className="flex flex-col gap-3">
        <span className="text-center flex justify-center">
          <ExclamationCircleIcon className="text-yellow-500 w-12 h-12" />
        </span>
        <div className="flex justify-center text-lg pb-4">
          <span>Are you sure?</span>{' '}
          <span>Action once completed can not be changed</span>
        </div>

        <span className="flex justify-end gap-x-2">
          <Button onClick={() => closeModal()}>No</Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              handleUpdateStatus(visible?.id as number, 'Completed');
              closeModal();
            }}
          >
            Yes, I'm sure
          </Button>
        </span>
      </div>
    </Modal>
  );
};

export default CompleteConfirmModal;
