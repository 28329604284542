import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { userInfoApi } from '../services/apiservices';
import { notify } from '../components/basic/notity';

const PrivateWrapper = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const router = useNavigate();

  const checkAuthentication = async () => {
    try {
      await userInfoApi();
    } catch {
      notify('You are not authenticated', 'error');

      router(`/?redirectUrl=${pathname}`);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  return children;
};

export default PrivateWrapper;
