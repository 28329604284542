import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import CreateLiveForm from '../../../components/Live/CreateLiveForm';
import {
  createWorkShop,
  getSingleWorkShopsApi,
  updateWorkShop,
} from '../../../services/liveServices';
import { useNavigate, useParams } from 'react-router-dom';
import { formateErrorObject } from '../../../components/basic/formateError';
import { notify } from '../../../components/basic/notity';
import { ShouldRender } from '../../../components/basic/ShouldRender';
import Loader from '../../../components/ui-elements/Loader/Loader';

const CreateLiveSessionPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    authorname: '',
    livetitle: '',
    livedescription: '',
    learningtopic: [''],
    livebanner: undefined,
    banner_phone: undefined,
    channelname: '',
    liveIncludes: [''],
  });

  const { id } = useParams();

  const formatData = (obj: any) => {
    return {
      authorname: obj?.author_name,
      livetitle: obj?.title,
      livedescription: obj?.desc,
      learningtopic: obj?.learn_topics || [''],
      livebanner: obj?.live_workshop_banner
        ? obj.live_workshop_banner.original_url
        : null,
      banner_phone: obj?.live_workshop_banner_phone
        ? obj.live_workshop_banner_phone.original_url
        : null,
      channelname: obj?.channel,
      liveIncludes: obj?.key_points || [''],
    };
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      if (id) {
        await updateWorkShop(
          {
            author_name: formData.authorname,
            banner: formData.livebanner,
            channelname: formData.channelname,
            desc: formData.livedescription,
            learningtopic: formData.learningtopic,
            liveIncludes: formData.liveIncludes,
            title: formData.livetitle,
            banner_phone: formData.banner_phone,
          },
          id
        );
        notify('Workshop updated successfully!!', 'success');
      } else {
        await createWorkShop({
          author_name: formData.authorname,
          banner: formData.livebanner,
          channelname: formData.channelname,
          desc: formData.livedescription,
          learningtopic: formData.learningtopic,
          liveIncludes: formData.liveIncludes,
          title: formData.livetitle,
          banner_phone: formData.banner_phone,
        });
        notify('Workshop created successfully!!', 'success');
      }

      navigate(`/live-session`);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getSingleWorkShopsData = async (id: string) => {
    try {
      setLoading(true);
      const response = await getSingleWorkShopsApi(id);
      const formattedData = formatData(response?.data?.data);
      setFormData(formattedData);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSingleWorkShopsData(id);
    }
  }, [id]);

  return (
    <>
      <ShouldRender check={!loading}>
        <div className="flex sticky top-0 w-full bg-white z-20  flex-row justify-between gap-4 pl-9 pb-3 pt-3 border-y border-[#e2e2e2]">
          <div className="flex flex-row items-center gap-6 z-">
            <Button
              onClick={() => navigate(`/live-session`)}
              size="large"
              className="rounded-xl cursor-pointer group"
            >
              <p className="flex gap-2 items-center">
                <ChevronLeftIcon className="w-4 text-[#afaeae] group-hover:text-primary font-bold" />
                Back
              </p>
            </Button>
            <div className="border-l  h-10 text-[#e2e2e2]  " />
            <span className="text-xl font-medium">
              {id ? 'Update Live' : 'Create Live'}
            </span>
          </div>
          <div className="flex items-center pr-10 z-40">
            <button
              type="button"
              onClick={handleSubmit}
              className="rounded-xl text-base font-bold  bg-[#FFF3F0] px-8 py-2 text-primary "
            >
              <b className="font-medium text-sm">Done</b>
            </button>
          </div>
        </div>
        <span className="z-10">
          <CreateLiveForm
            form={form}
            formData={formData}
            setFormData={setFormData}
          />
        </span>
      </ShouldRender>
      <ShouldRender check={loading}>
        <Loader />
      </ShouldRender>
    </>
  );
};

export default CreateLiveSessionPage;
