import React, { useEffect, useState } from 'react';
import { Input, Modal, Tooltip } from 'antd';
import {
  createSchoolApi,
  getAllSchoolListApi,
  getCategoriesApi,
  userInfoApi,
} from '../../../../services/apiservices';
import ADD_SCHOOLS from '../../../../assets/create_school.png';
import { useNavigate } from 'react-router-dom';
import ImageCards from '../../../../components/cards/ImageCard';
import { Endpoints } from '../../../../services/httpendpoints';
import Loader from '../../../../components/ui-elements/Loader/Loader';
import {
  AllDatum,
  CategoriesDataInterface,
  SchoolListDataInterface,
} from '../../../../utills/interfaces/interface';
import { GrFormClose } from 'react-icons/gr';
import DEFAULT_IMAGE from '../../../../assets/images/cards/1.png';
import ImageUploader from '../../../../components/ui-elements/image-uploader/ImageUploader';
import { CheckPermission } from '../../../../components/basic/CheckPermission';

const SchoolList = () => {
  const route = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userSchoolsData, setUserSchoolsData] =
    useState<SchoolListDataInterface>();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [categoriesData, setCategoriesData] =
    useState<CategoriesDataInterface>();
  const [formData, setFormData] = useState({
    coverImage: null as File | null,
    blogTitle: '',
    titleDescription: '',
    selectedTags: [] as string[],
  });
  const [errors, setErrors] = useState({
    coverImage: '',
    blogTitle: '',
    titleDescription: '',
    // selectedTags: [] as string[],
  });
  const [showAllTags, setShowAllTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const [userInfo, setUserInfo] = useState<any>();
  const maxTagsToShow = 20;

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await userInfoApi();
        console.log(response, 'response from user');
        setUserInfo(response?.data?.id);
      } catch (error) {
        console.error('API call error:', error);
      }
    };
    getUserDetails();
  }, []);

  const handleSeeMoreClick = () => {
    setShowAllTags(!showAllTags);
  };

  const createSchoolHandler = () => {
    setModalOpen(true);
  };

  const handleImageUpload = (file: File | null) => {
    // const file = e.target.files[0];
    setSelectedImage(file);
    setFormData({ ...formData, coverImage: file as File });
  };

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  /* const handleTagClick = (category_id: any) => {
    console.log(formData,"form data line 79")
    if (formData.selectedTags.includes(category_id)) {
      setFormData({
        ...formData,
        selectedTags: formData.selectedTags.filter(
          (selectedTag) => selectedTag !== category_id,
        ),
      });
    } else {
      setFormData({
        ...formData,
        selectedTags: [...formData.selectedTags, category_id],
      });
    }
  };*/

  const handleTagClick = (category_id: any) => {
    // If the clicked tag is already selected, deselect it
    if (selectedTag === category_id) {
      setSelectedTag(null);
      setFormData({
        ...formData,
        selectedTags: formData.selectedTags.filter(
          (selectedTag) => selectedTag !== category_id
        ),
      });
    } else {
      // Otherwise, select the clicked tag
      setSelectedTag(category_id);
      setFormData({
        ...formData,
        selectedTags: [category_id],
      });
    }
  };

  useEffect(() => {
    const getAllSchoolListData = async () => {
      try {
        setIsLoading(true);
        const response = await getAllSchoolListApi({});
        setUserSchoolsData(response?.data);
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    getAllSchoolListData();
  }, [modalOpen]);

  const getAllSchoolListData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllSchoolListApi({});
      setUserSchoolsData(response?.data);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiCall = async (data: any) => {
    try {
      setIsLoading(true);
      const response = await createSchoolApi(data);
      if (response.success) {
        getAllSchoolListData();
      }

      handleCancel();
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setFormData({
      coverImage: null,
      blogTitle: '',
      titleDescription: '',
      selectedTags: [],
    });
    setSelectedImage(null);
  };

  useEffect(() => {
    const getCategoriesData = async () => {
      try {
        setIsLoading(true);
        const response = await getCategoriesApi();
        setCategoriesData(response?.data);
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    getCategoriesData();
  }, []);

  const submitForm = () => {
    const validationErrors = {
      coverImage: '',
      blogTitle: '',
      titleDescription: '',
      // selectedTags: [] as string[],
    };

    if (!formData.blogTitle) {
      validationErrors.blogTitle = 'Title is required!';
    }
    if (!formData.titleDescription) {
      validationErrors.titleDescription = 'School Description is required!';
    }
    if (!formData.coverImage) {
      validationErrors.coverImage = 'School cover image is required!';
    }

    setErrors(validationErrors);
    console.log('calling api s1', formData, validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      console.log('calling api s2', formData, validationErrors);

      setIsLoading(true);
      const schoolFormData = new FormData();
      if (selectedImage) {
        // schoolFormData.append('cover_image', formData.coverImage as File);
      }
      schoolFormData.append('cover_image', formData.coverImage as File);
      schoolFormData.append('created_by', userInfo);
      schoolFormData.append('name', formData.blogTitle);
      schoolFormData.append('description', formData.titleDescription);
      formData.selectedTags.forEach((tag) => {
        schoolFormData.append(`category_id`, tag);
      });
      handleApiCall(schoolFormData);
    }

    // setModalOpen(false);
  };

  const openSchoolDetails = (id: any) => {
    route(`/school/schoolblog-view/${id}`);
  };

  const onAnalyticsHandler = (id: number) => {
    route(`/school/schoolblog-view/${id}/analytics`);
  };

  const truncateText = (text: string, maxCharacters: number) => {
    if (text.length <= maxCharacters) {
      return text;
    }
    const truncatedText = text.slice(0, maxCharacters);
    return `${truncatedText} ...`;
  };

  return (
    <>
      {!isLoading ? (
        // <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-8">
        <div className="grid grid-cols-5 gap-6 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
          <CheckPermission check="create-schools">
            <div className="">
              <div
                onClick={createSchoolHandler}
                className="grid grid-cols p-4 w-full h-full rounded-xl bg-[#F6F6F9] "
              >
                <div className="rounded-xl flex justify-center p-14 items-center bg-white cursor-pointer">
                  <img src={ADD_SCHOOLS} alt="create school ]" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <b className="text-base text-black"> Create School </b>
                </div>
              </div>
            </div>
          </CheckPermission>

          <>
            {userSchoolsData?.data && userSchoolsData?.data.length > 0 ? (
              userSchoolsData?.data?.map(
                (items: AllDatum, index: React.Key | null | undefined) => (
                  <CheckPermission key={index} check="manage-schools">
                    <div>
                      <ImageCards
                        blogCount={items?.posts_count}
                        blogName={items?.name}
                        cardImage={
                          items?.documents?.file_path
                            ? `${Endpoints.getSchoolImagesByS3Bucket}/${items?.documents?.file_path}`
                            : DEFAULT_IMAGE
                        }
                        onOpenHandler={() => openSchoolDetails(items.id)}
                        schoolId={items.id}
                        onAnalyticsHandler={() => onAnalyticsHandler(items.id)}
                      />
                    </div>
                  </CheckPermission>
                )
              )
            ) : (
              // If there are no schools, display a message
              <div className="flex flex-col justify-center items-center">
                <p>Record not found !! </p>
                <p>Please create school</p>
              </div>
            )}
          </>
        </div>
      ) : (
        <Loader />
      )}

      <Modal
        open={modalOpen}
        onCancel={() => handleCancel()}
        onOk={submitForm}
        closeIcon={<></>}
        closable={false}
        width={730}
        centered
        footer={<></>}
      >
        <div className="">
          <div className=" px-6 py-6 rounded-t-xl">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <b className="text-2xl">Create a School </b>
                <b className="text-base text-graydark">
                  Create a list of blogs
                </b>
              </div>
              {/* <div className="rounded-full border p-0.3"> */}
              <GrFormClose
                onClick={() => setModalOpen(false)}
                style={{ fontSize: '40px', cursor: 'pointer' }}
              />
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 py-4">
          <div className="flex flex-col gap-3 px-6">
            <b className="text-lg text-[#4B4B4B]"> Add image of your school</b>
            {/* image uploader  code start */}
            <div className="flex flex-col">
              {/* <input
                type="file"
                onChange={handleImageUpload}
                multiple // Allow multiple file selection
              /> */}
              <ImageUploader
                uploadAction="School cover image"
                maxFileCount={1}
                onChange={handleImageUpload}
                defaultImage=""
                clearImage={modalOpen}
              />
              {errors.coverImage && (
                <p className="text-danger">{errors.coverImage}</p>
              )}
            </div>
            {/* upload image code end */}
          </div>
          <div className="flex flex-col gap-3 px-6">
            <b className="text-lg text-[#4B4B4B]">
              What will be the title of your school?
            </b>

            <Input
              showCount
              maxLength={100}
              placeholder="Enter school Title"
              className="h-10 text-base focus:border-primary hover:border-primary"
              value={formData.blogTitle}
              onChange={(e) =>
                setFormData({ ...formData, blogTitle: e.target.value })
              }
            />
            {errors.blogTitle && (
              <p className="text-danger">{errors.blogTitle}</p>
            )}
          </div>
          <div className="flex flex-col gap-3 px-6">
            <b className="text-lg text-[#4B4B4B]">
              What is the school about, describe your school.
            </b>

            <Input
              showCount
              maxLength={500}
              placeholder="Describe your school"
              className="h-20 text-base focus:border-primary hover:border-primary"
              value={formData.titleDescription}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  titleDescription: e.target.value,
                })
              }
            />
            {errors.titleDescription && (
              <p className="text-danger">{errors.titleDescription}</p>
            )}
          </div>

          {/* todo important */}

          <div className="flex flex-col  gap-2 px-6">
            <div>
              <b>Add a tag about the school</b>
            </div>
            <div className="grid grid-cols-6 gap-2">
              {categoriesData?.data
                ?.slice(0, showAllTags ? undefined : maxTagsToShow)
                .map((tag: any) => (
                  <div
                    key={tag.id}
                    className={`grid col-span-1 rounded-3xl text-center py-2 border border-[#EAEAEA] whitespace-nowrap  cursor-pointer ${
                      formData.selectedTags.includes(tag.id)
                        ? 'bg-[#FFF3F0] text-primary font-bold'
                        : ''
                    }`}
                    onClick={() => handleTagClick(tag.id)}
                  >
                    <Tooltip placement="top" title={tag.name}>
                      {truncateText(tag.name, 10)}
                    </Tooltip>
                  </div>
                ))}
              <div className="flex items-center ">
                <a
                  onClick={handleSeeMoreClick}
                  className="hover:text-primary underline hover:underline font-bold"
                >
                  {showAllTags ? 'Show less' : 'See more..'}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center p-6 space-x-2  border-gray-200 rounded-b dark:border-gray-600">
          <button
            onClick={handleToggleModal}
            type="button"
            className=" w-1/3 text-black border rounded-xl p-3 font-bold hover:border-primary hover:text-primary"
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full text-white bg-primary rounded-xl p-3 font-bold "
            onClick={submitForm}
          >
            Create School
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SchoolList;
