import { atom } from 'recoil';

export const triggerSendMessage = atom<{
  value: boolean;
  label: string;
}>({
  key: 'triggerSendMessage',
  default: {
    value: false,
    label: '',
  },
});
