import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editMyBlogBySchoolIdApi } from '../../../../services/apiservices';
import Loader from '../../../../components/ui-elements/Loader/Loader';
import { LeftOutlined } from '@ant-design/icons';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Renderer from './Editor/Render';

interface QuillStylingProps {
  content: string;
}

const QuillStyling: React.FC<QuillStylingProps> = ({ content }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const quill = new Quill(containerRef.current, {
        readOnly: true,
        theme: 'snow',
      });

      quill.root.innerHTML = content;

      // Hide the Quill toolbar
      const toolbar = containerRef.current.previousSibling as HTMLElement;
      if (toolbar) {
        toolbar.style.display = 'none';
      }
    }
  }, [content]);

  return <div ref={containerRef} />;
};

function BlogView() {
  const navigate = useNavigate();
  const [blogContent, setBlogContent] = useState([]);
  // const [isSaveActive, setIsSaveActive] = useState(false);
  const { id, optionalid } = useParams();
  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  console.log(id, optionalid);

  useEffect(() => {
    const getMyBlogContents = async (optionalid: string) => {
      try {
        setIsLoading(true);
        const response = await editMyBlogBySchoolIdApi(optionalid);
        const { data } = response.data;
        if (data) {
          // Set the pre-filled data in the state
          setBlogContent(JSON.parse(data?.json_content));
          setInputValue(data.title);
        }
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (optionalid) {
      getMyBlogContents(optionalid);
    }
  }, [optionalid]);

  const GotoPreviousPage = () => {
    navigate(`/school/schoolblog-view/${id}`);
  };

  const handlEditBlogById = async (
    editId: string | undefined,
    optionalId?: number
  ) => {
    navigate(
      `/school/schoolblog-view/${id}/create-blogs/${editId}/${optionalId || ''}`
    );
  };

  // const editYourBlog = (editId: number, optionalId?: number) => {

  //   navigate(
  //     `/school/schoolblog-view/${id}/blogsview/${id}/create-blogs/${optionalid}/`,
  //   );  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className="flex flex-col  gap-5">
            <div className="flex justify-between">
              <button
                onClick={GotoPreviousPage}
                type="button"
                className="rounded-xl text-lg border hover:border-primary hover:text-primary px-4 py-2"
              >
                <p className="flex gap-2">
                  <LeftOutlined className="text-gray-500 " />
                  <span className="hidden sm:inline">Back</span>
                </p>
              </button>
              <button
                onClick={() => handlEditBlogById(optionalid)}
                type="button"
                className="rounded-xl text-lg border hover:border-primary hover:text-primary px-4 py-2"
              >
                <p className="flex gap-2">
                  <LeftOutlined className="text-gray-500 " />
                  <span className="hidden sm:inline">Edit Blog</span>
                </p>
              </button>
            </div>
            <div className="flex justify-center">
              <div className="w-4/5 md:w-3/5 xl:w-1/2">
                <div className="text-start mb-4 text-[32px]">
                  <b>{inputValue}</b>
                </div>
                {/* <div dangerouslySetInnerHTML={{ __html: blogContent }} /> */}
                {/* <QuillStyling content={blogContent || ''} /> */}
                <span className=" ">
                  <Renderer data={blogContent} />
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default BlogView;
