import React, { useState } from 'react';
import { Menu, MenuProps } from 'antd';
import InlineEditInput from '../../components/Input/InlineEditInput';
import Overview from '../../components/newsletter-element/Overview';
import { useNavigate } from 'react-router';
import Audience from '../../components/newsletter-element/Audience';

const Newsletter: React.FC = () => {
  const [current, setCurrent] = useState('overview');
  const navigate = useNavigate();
  const items: MenuProps['items'] = [
    {
      label: 'Overview',
      key: 'overview',
      icon: '',
    },
    {
      label: 'Audience',
      key: 'audience',
      icon: '',
      disabled: false,
    },
    {
      label: 'Engagement',
      key: 'Engagement',
      icon: '',
    },
    {
      label: 'Monetization',
      key: 'Monetization',
      icon: '',
    },
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const handleSave = (editedValue: string) => {
    console.log(editedValue, 'input Value getting');
  };
  return (
    <div className="py-3 px-6">
      <div className="flex items-center justify-between w-full mb-2">
        <div>
          <h1 className="font-bold text-4xl">Hi Author 👋,</h1>
          <p className="text-xl font-semibold text-[#898989]">
            Here's how your publication is doing
          </p>
        </div>
        <div className="">
          <button
            className="flex items-center m-1 p-2 rounded-lg border bg-primary text-white text-lg "
            onClick={() => {
              navigate('/newsletters/create');
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mx-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
              />
            </svg>
            Start Writing
          </button>
        </div>
      </div>

      <div className="my-4">
        <InlineEditInput value={'Authors NewsLetter'} onSave={handleSave} />
        <p className="text-sm text-[#898989]">
          Enter / Edit your Newsletters Name
        </p>
      </div>

      <div>
        <Menu
          className="text-lg font-bold text-[#898989]"
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </div>

      <div className="">
        {current === 'overview' && <Overview />}
        {current === 'audience' && <Audience />}
      </div>
    </div>
  );
};

export default Newsletter;
