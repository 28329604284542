import { Helmet } from 'react-helmet';
import React from 'react';
import ResetPassword from '../Auth/ResetPassword';

const ResetPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>{'Reset Password'}</title>
      </Helmet>

      <ResetPassword />
    </>
  );
};
export default ResetPasswordPage;
