import { useNavigate } from 'react-router-dom';
import { CheckPermission } from '../../components/basic/CheckPermission';
import { useEffect, useState } from 'react';
import { getAllCampaignsApi } from '../../services/campaignServices';
import { Empty, Modal, Tag } from 'antd';
import moment from 'moment';
import { ShouldRender } from '../../components/basic/ShouldRender';
import Loader from '../../components/ui-elements/Loader/Loader';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useRecoilValue } from 'recoil';
import { UserInfoInterface } from '../../utills/interfaces/interface';
import { user } from '../../atoms/user';

interface ICampaignData {
  sent_at: string | null;
  updated_at: string;
  id: number;
  title: string;
  description: string;
  status: string;
  sources: string[];
  author: string;
}

const BroadcastPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState<ICampaignData[]>([]);
  const userData = useRecoilValue<UserInfoInterface | undefined>(user);
  const [modalOpen, setModalOpen] = useState(false);

  const getAllCampaigns = async () => {
    try {
      setLoading(true);
      const response = await getAllCampaignsApi();
      const formattedData: ICampaignData[] = formatResponse(response);
      setCampaignData(formattedData);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatResponse = (response: any): ICampaignData[] => {
    return response.map((campaign: any) => ({
      sent_at: campaign.sent_at,
      updated_at: campaign.updated_at,
      id: campaign.id,
      title: campaign.title,
      description: campaign.description,
      status: campaign.status,
      author: campaign?.author_name || '',
      sources: Array.from(
        new Set(
          campaign?.conditions
            ?.filter((source: any) => source.hasOwnProperty('source_type'))
            .map((source: any) => source.source_type)
        )
      ),
    }));
  };

  useEffect(() => {
    getAllCampaigns();
  }, []);

  const getColor = (status?: string, sentAt?: string | null): string => {
    if (status === 'sending' || sentAt !== null) return 'green';
    if (status === 'failed') return 'red';
    return '';
  };

  const getText = (status?: string, sentAt?: string | null): string => {
    switch (status) {
      case 'sending':
        return 'Sending';
      case 'failed':
        return 'Failed';
      default:
        return sentAt !== null ? 'Sent' : 'In-Review';
    }
  };

  return (
    <div className="relative mt-5 pb-10 z-10">
      <div className={`w-full `}>
        <div className="border-b pb-4 border-[#f2f0f0]">
          <div className="flex justify-between items-center w-full">
            <span className="font-bold px-2 text-lg flex gap-x-3 cursor-pointer hover:text-[#595858] items-center">
              Broadcast Messages
            </span>
            <CheckPermission check="create-chat-campaigns">
              <button
                type="button"
                onClick={() => {
                  // if (!userData?.is_verified) {
                  navigate(`/broadcast/create-message`);
                  // }
                  // navigate(`/broadcast/create-message`);
                }}
                className="rounded-xl font-bold bg-[#FFF3F0] hover:bg-[#fcedeb] px-6 py-2 text-primary mr-4"
              >
                <b className="font-medium text-sm">Create Message</b>
              </button>
            </CheckPermission>
          </div>
        </div>
        <ShouldRender check={!loading && campaignData.length}>
          <div className="flex flex-col gap-2 py-8 px-2">
            {campaignData?.map((item) => {
              return (
                <div
                  key={item?.id}
                  className="border grid grid-cols-4 gap-x-5 justify-between items-center border-borderGrayColor p-4 rounded-xl"
                >
                  <span className="flex gap-x-2 items-start">
                    <Tag
                      className="border-none font-medium px-4 py-1"
                      color={getColor(item?.status, item?.sent_at)}
                    >
                      {getText(item?.status, item?.sent_at)}
                    </Tag>
                    <span className="font-medium ">
                      <p className="text-sm text-[#919191] whitespace-nowrap">
                        {moment(item?.updated_at).format('ddd, D MMM YY')}
                      </p>
                      <p className="text-base">
                        {item.title}
                        <ShouldRender check={item.description}>
                          - {item.description}
                        </ShouldRender>
                      </p>
                    </span>
                  </span>
                  <span className="flex gap-x-2 items-start justify-center">
                    <span className="font-medium">
                      <p className="text-sm text-[#919191]">Author</p>
                      <p className="text-base flex justify-center w-full">
                        {item.author}
                      </p>
                    </span>
                  </span>
                  <span className="flex gap-x-2 items-start justify-center">
                    {/* <ShouldRender check={item?.sources?.length}> */}
                    <span className="font-medium">
                      <p className="text-sm flex justify-center -translate-x-5 text-[#919191]">
                        Sources
                      </p>
                      <ul className="list-disc flex flex-col justify-center capitalize flex-wrap gap-x-2">
                        {item?.sources?.map((source, index) => (
                          <li key={index}>{source}</li>
                        ))}
                      </ul>
                      {/* <p className="text-base flex justify-center w-full">
                        {item.sources}
                      </p> */}
                    </span>
                    {/* </ShouldRender> */}
                    {/* <ShouldRender check={!item?.sources?.length}>
                      <p className="text-base flex justify-center w-full">
                        N/A
                      </p>
                    </ShouldRender> */}
                  </span>
                  <span className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        if (item?.sent_at) {
                          navigate(`/broadcast/messages/${item.id}`);
                        } else {
                          navigate(`/broadcast/edit-message/${item.id}`);
                        }
                      }}
                      className="rounded-xl font-bold bg-[#F6F6F6] hover:bg-[#e9e7e7] px-7 py-2 text-black"
                    >
                      <b className="font-medium text-sm">
                        {item?.sent_at ? 'Open' : 'Edit'}
                      </b>
                    </button>
                  </span>
                </div>
              );
            })}
          </div>
        </ShouldRender>

        <ShouldRender check={!loading && !campaignData.length}>
          <div className="py-40">
            <Empty description={'No message found!'} />
          </div>
        </ShouldRender>

        <ShouldRender check={loading}>
          <Loader />
        </ShouldRender>
      </div>

      <Modal
        title=""
        open={modalOpen}
        onOk={() => {
          setModalOpen(false);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        // closable={false}
        okText="Schedule"
        cancelText="Cancel"
        footer={false}
        className="custom-modal"
        width={400}
      >
        <div className="overlay rounded-xl p-4 w-full h-full bg-grayBg flex items-center justify-center bg-opacity-40 ">
          <div className="text-center z-30">
            <span className="flex justify-center">
              <AiOutlineExclamationCircle className="text-5xl mb-4" />
            </span>
            <p className="text-lg mb-2 font-medium">
              You're not authorized yet!
            </p>
            <p className="mb-4">
              Please contact our support team at{' '}
              <a
                href="mailto:hello@zebralearn.com
"
                className="text-blue-400"
              >
                hello@zebralearn.com
              </a>{' '}
              to get verified.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BroadcastPage;
