import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { Config } from '../config/config';
import { checkSessionExists, setTokenInCookies } from '../config/appwrite';

const getToken = () => {
  return Cookies.get('auth_token');
};

const apiClient = (contentType = 'multipart/form-data') => {
  const instance = axios.create({
    baseURL: Config.BASE_API_URL,
    timeout: 30000,
  });

  // Request interceptor
  instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const currentToken = getToken();
    if (!currentToken && config.headers) {
      const session = await checkSessionExists();
      if (session) {
        const token = await setTokenInCookies();
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['Content-Type'] = contentType;
      }
    } else if (currentToken && config.headers) {
      config.headers['Authorization'] = `Bearer ${currentToken}`;
      config.headers['Content-Type'] = contentType;
    }

    return config;
  });

  // Response interceptor
  instance.interceptors.response.use((response) => {
    return response;
  });

  return instance;
};

export default apiClient;
