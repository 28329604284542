import React, { useEffect, useState } from 'react';
import { Table, Switch, Avatar, Badge, Modal, Empty } from 'antd';

import moment from 'moment';
import UserModal from './UserModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExclamationCircleIcon, PencilAltIcon } from '@heroicons/react/outline';
import {
  DataSourceItem,
  userRolesEnumMapping,
} from '../../utills/interfaces/user';
import { useUsers } from '../../hooks/useUsers';
import { formateErrorObject } from '../basic/formateError';
import { notify } from '../basic/notity';
import { letterColors } from '../../utills/data';

import ButtonDefault, { ButtonVariants } from '../basic/button';
import { CheckPermission } from '../basic/CheckPermission';

const AllUser: React.FC = () => {
  const {
    allUsers,
    getAllUsers,
    loading,
    paginationData,
    updateStatus,
    // getCountryName,
  } = useUsers();
  const router = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState<DataSourceItem[] | undefined>([]);
  const [isModalOpenUser, setIsModalOpenUser] = useState(false);
  const [editId, setEditId] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number>();
  const [editStatus, setEditStatus] = useState<{
    id: number | null;
    is_verified: number | null;
  }>({
    id: null,
    is_verified: null,
  });

  const handleStatusChange = (key: number, checked: boolean) => {
    if (checked) {
      setEditStatus({ id: key, is_verified: 1 });
    } else {
      setEditStatus({ id: key, is_verified: 0 });
    }

    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const response = await updateStatus(
        editStatus.id,
        editStatus.is_verified
      );
      if (response) {
        getAllUsers();
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      handleModalCancel();
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAllUsers(page);
  }, [isModalOpenUser, page]);

  const handleOkUser = () => {
    setIsModalOpenUser(false);
    setEditId(undefined);
  };

  const handleCancelUser = () => {
    setIsModalOpenUser(false);
    setEditId(undefined);
  };

  useEffect(() => {
    if (location?.search === '?isModalOpenUser=true') {
      setIsModalOpenUser(true);
      query.delete('isModalOpenUser');
      router({ search: `?${query.toString()}` });
    }
  }, [location?.search]);

  useEffect(() => {
    setData(allUsers);
  }, [allUsers, loading]);

  const columns = [
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">S.N.</span>
      ),
      dataIndex: 'id',
      width: 50,
      key: 'id',
      render: (text: string) => (
        <div className="border rounded-md border-lightgray flex justify-center w-min px-2 py-1 text-xs">
          {text}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">Name</span>
      ),
      width: 200,
      key: 'name',
      render: (record: DataSourceItem) => {
        return (
          <div className="flex gap-x-2 items-center">
            <span className="w-12 h-12 flex items-center">
              <Avatar
                size={40}
                style={{
                  backgroundColor:
                    (letterColors as Record<string, string>)[
                      record?.name?.toUpperCase().charAt(0)
                    ] || '#000000',
                }}
              >
                {record?.name?.toUpperCase().charAt(0)}
              </Avatar>
            </span>

            <div className="flex flex-col ">
              <span className="font-medium truncate capitalize">
                {record?.name}
              </span>
              <span className="font-medium italic text-gray-400 text-xs">
                @{userRolesEnumMapping[record?.role?.name || '']}
              </span>
            </div>
          </div>
        );
      },
    },
    // {
    //   title: (
    //     <span className="text-xs text-gray-500 flex justify-center">
    //       Country
    //     </span>
    //   ),
    //   key: 'country_code',
    //   render: (record: DataSourceItem) => {
    //     return (
    //       <div className="flex gap-x-2 items-center justify-center">
    //         <span className="w-4 h-4">
    //           <ShouldRender check={record?.country_code === '+91'}>
    //             <img
    //               src={IndianFlag}
    //               alt="Book"
    //               className="object-contain h-4 w-full"
    //             />
    //           </ShouldRender>
    //         </span>

    //         <div className="flex flex-col items-center">
    //           <span className="font-medium text-gray-500">
    //             {getCountryName(record?.country_code)}
    //           </span>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Status
        </span>
      ),
      dataIndex: 'is_verified',
      key: 'is_verified',
      render: (is_verified: number) => (
        <div
          className={`${
            is_verified === 1 ? 'text-green-500' : 'text-gray-500'
          } flex gap-x-2 px-2 text-xs font-medium justify-center rounded-full py-1`}
        >
          {' '}
          <Badge color={is_verified === 1 ? 'green' : '#C0C0C0'} />
          {is_verified === 1 ? 'Verified' : 'Un-Verified'}
        </div>
      ),
    },

    // {
    //   title: (
    //     <span className="text-xs text-gray-500 flex justify-center whitespace-nowrap">
    //       Phone Number
    //     </span>
    //   ),
    //   key: 'phone',
    //   render: (record: DataSourceItem) => (
    //     <div className=" flex justify-center   px-2 py-1 whitespace-nowrap">
    //       {record?.country_code + ' ' + record?.phone}
    //     </div>
    //   ),
    // },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Email Address
        </span>
      ),
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => (
        <div className=" flex justify-center   ">{email}</div>
      ),
    },

    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Created At
        </span>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => (
        <div className=" flex justify-center   ">
          {moment(date).format('MMMM D, YYYY')}
        </div>
      ),
    },

    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Action
        </span>
      ),
      dataIndex: 'is_verified',
      key: 'Action',
      render: (is_verified: number, record: DataSourceItem) => (
        <div className="flex gap-x-4 justify-center">
          <div className="flex gap-x-2 items-center">
            {/* <span className="font-medium text-xs">Verified</span> */}
            <Switch
              checked={is_verified === 1}
              className="bg-lightgray"
              onChange={(checked) => handleStatusChange(record.id, checked)}
            />
            <span className="font-medium text-xs">Verified</span>
          </div>

          <PencilAltIcon
            onClick={() => {
              setEditId(record?.id);
              setIsModalOpenUser(true);
            }}
            className="w-6 h-6 hover:cursor-pointer text-gray-500 hover:text-gray-800"
          />
        </div>
      ),
    },
  ];

  const usersWithKeys = data?.map((user) => ({
    ...user,
    key: user.id.toString(),
  }));

  return (
    <div className="bg-white rounded-md flex flex-col">
      <span className="px-5 font-medium py-4 text-base flex gap-x-4 items-center">
        All Users{' '}
        <p className="text-xs bg-red-50 text-red-500 px-4 rounded-full py-1">
          {paginationData?.total} users
        </p>
      </span>
      <CheckPermission check="manage-users">
        <div className="overflow-auto ">
          <Table
            dataSource={usersWithKeys}
            columns={columns}
            loading={loading}
            pagination={{
              current: page,
              pageSize: paginationData?.per_page || 0,
              total: paginationData?.total || 0,
              onChange: (newPage) => {
                setPage(newPage);
              },
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Users</span>}
                  className=""
                />
              ),
            }}
          />
        </div>
      </CheckPermission>

      <UserModal
        isModalOpen={isModalOpenUser}
        editId={editId}
        handleOk={handleOkUser}
        handleCancel={handleCancelUser}
      />
      <Modal
        open={isModalVisible}
        onOk={handleModalOk}
        closable={false}
        footer={false}
        onCancel={handleModalCancel}
      >
        <div className="flex justify-center pt-4">
          <div className="flex flex-col gap-y-3 justify-center">
            <span className="flex justify-center">
              <ExclamationCircleIcon className="w-12 h-12 text-yellow-400 " />
            </span>
            <span className="text-xl">
              {' '}
              Are you sure you want to change status of this user?
            </span>
          </div>
        </div>

        <div className="flex gap-x-3 justify-center mt-9">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleModalCancel}
          >
            <span className="px-4 py-1">No</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            onClick={handleModalOk}
          >
            <span className="px-4 py-1">Yes</span>
          </ButtonDefault>
        </div>
      </Modal>
    </div>
  );
};

export default AllUser;
