import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { checkSessionExists, setTokenInCookies } from '../config/appwrite';
import { Config } from '../config/config';

const baseForPdf = axios.create({
  baseURL: Config.BASE_API_URL_READER,
  timeout: 30000,
});

baseForPdf.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const authToken = Cookies.get('auth_token');
  config.headers = config.headers || {};

  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  } else {
    const session = await checkSessionExists();
    if (session) {
      const token = await setTokenInCookies();
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return config;
});

export default baseForPdf;
