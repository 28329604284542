import React, { useState } from 'react';

import { FaLayerGroup } from 'react-icons/fa';
import { ShouldRender } from '../../basic/ShouldRender';
import { FiChevronDown } from 'react-icons/fi';
import { Dropdown, Empty, MenuProps, Tag } from 'antd';
import { PiPencilSimpleLight } from 'react-icons/pi';
import { LuClock4, LuShieldCheck } from 'react-icons/lu';
import Loader from '../../ui-elements/Loader/Loader';
import { IoRocketOutline } from 'react-icons/io5';
import moment from 'moment';

import { WorkshopData } from '../../../pages/LivePage';
import { useNavigate } from 'react-router-dom';
import { CheckPermission } from '../../basic/CheckPermission';
import CompleteConfirmModal from '../CompleteConfirmModal';

interface LiveTabProps {
  loading: boolean;
  workShopId: number | undefined;
  setWorkShopId: React.Dispatch<React.SetStateAction<number | undefined>>;
  workShopData: WorkshopData[];
  getAllWorkShopsData: () => Promise<void>;
  handleUpdateStatus: (id: number, status: string) => Promise<void>;
}

const LiveTab: React.FC<LiveTabProps> = ({
  loading,
  workShopId,
  setWorkShopId,
  workShopData,
  handleUpdateStatus,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState({ value: false, id: 0 });
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleUpdateStatus(workShopId as number, 'Pending');
          }}
          className="flex relative gap-x-2 items-center text-sm font-medium py-2 "
        >
          <PiPencilSimpleLight className="text-[#9F9F9F] text-lg" />
          Draft Live
          <span className="border-b absolute -bottom-1.5 border-[#f2f0f0] py-4 w-full" />
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleUpdateStatus(workShopId as number, 'Approved');
          }}
          className="flex gap-x-2 relative items-center text-sm font-medium py-2"
        >
          <LuShieldCheck className="text-[#38C13E] text-lg" />
          Approved Live
          <span className="border-b absolute -bottom-1.5 border-[#f2f0f0] py-4 w-full" />
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setVisible({
              value: true,
              id: workShopId as number,
            });
            // handleUpdateStatus(workShopId as number, 'Completed');
          }}
          className="flex gap-x-2  items-center text-sm font-medium py-2"
        >
          <IoRocketOutline className="text-[#166FF6] text-lg" />
          Completed
        </div>
      ),
    },
  ];

  return (
    <>
      <ShouldRender check={!loading && workShopData.length}>
        <CheckPermission check="manage-live-workshops">
          <div className="w-full relative  flex flex-col  ">
            {workShopData?.map((item, index) => {
              return (
                <div
                  key={`${item.title}-${index}`}
                  onClick={() => navigate(`/live-session/${item.id}`)}
                  className="flex flex-col hover:bg-[#fbfafa] pt-8 cursor-pointer px-5 rounded-md"
                >
                  <div className="flex gap-x-10 ">
                    <span className="h-[102px] xl:h-[162px] min-w-[200px]  xl:min-w-[276px] w-2/5 xl:w-1/5  rounded-full flex items-center ">
                      <img
                        src={item.live_workshop_banner}
                        alt={'live_workshop_banner'}
                        className="rounded-lg h-full w-full "
                      />
                    </span>
                    <div className="flex justify-between w-full lg:pr-10">
                      <div className="flex flex-col justify-between pb-2">
                        <span className=" flex flex-col gap-y-1">
                          <p className="text-[#9D9D9D]  capitalize">
                            {item.author_name}
                          </p>
                          <p className="text-xl">{item.title}</p>

                          <span className="flex gap-x-2">
                            <ShouldRender check={item.session_count}>
                              <p className="flex items-center text-sm gap-x-2">
                                <FaLayerGroup className="text-[#9D9D9D] text-sm" />{' '}
                                {item.session_count} Sessions
                              </p>
                            </ShouldRender>
                            <p className="flex items-center text-sm gap-x-2">
                              <LuClock4 className="text-[#9D9D9D] text-sm" />{' '}
                              {moment(item.created_at).format('DD MMM YYYY')}
                            </p>
                          </span>
                        </span>
                        <span>
                          <Tag
                            className="text-sm py-1 px-4 font-medium rounded-lg border-none"
                            color={
                              item.status === 'Pending'
                                ? 'default'
                                : item.status === 'Completed'
                                ? 'processing'
                                : 'success'
                            }
                          >
                            {item.status === 'Pending' ? 'Draft' : item.status}
                          </Tag>
                        </span>
                      </div>
                      <ShouldRender check={item.status !== 'Completed'}>
                        <div className="flex items-center">
                          <div className="flex flex-col justify-between h-1/2 gap-y-2">
                            <CheckPermission check="edit-live-workshops">
                              <Dropdown
                                trigger={['click']}
                                menu={{ items }}
                                placement="bottomLeft"
                              >
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setWorkShopId(item.id);
                                  }}
                                  className="rounded-xl  font-medium flex items-center gap-x-2 text-xs  bg-[#F6F6F6] hover:bg-[#e9e7e7]   px-10 py-3 text-black mr-4 "
                                >
                                  STATUS <FiChevronDown className="text-base" />{' '}
                                </button>
                              </Dropdown>
                            </CheckPermission>
                            <CheckPermission check="edit-live-workshops">
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(
                                    `/live-session/edit-session/${item.id}`
                                  );
                                }}
                                className="rounded-xl  font-bold  bg-[#F6F6F6]  hover:bg-[#e9e7e7] px-6  py-2 text-black mr-4 "
                              >
                                <b className="font-medium text-xs">EDIT</b>
                              </button>
                            </CheckPermission>
                          </div>
                        </div>
                      </ShouldRender>
                    </div>
                  </div>
                  <ShouldRender check={index !== workShopData.length - 1}>
                    <span className="border-b  border-[#f2f0f0] py-4 w-full" />
                  </ShouldRender>
                </div>
              );
            })}
          </div>
        </CheckPermission>
      </ShouldRender>
      <ShouldRender check={!loading && !workShopData.length}>
        <div className="py-40">
          <Empty description={'No Live workshop found !'} />
        </div>
      </ShouldRender>

      <ShouldRender check={loading}>
        <Loader />
      </ShouldRender>
      <CompleteConfirmModal
        visible={visible}
        handleUpdateStatus={handleUpdateStatus}
        closeModal={() => setVisible({ value: false, id: 0 })}
      />
    </>
  );
};

export default LiveTab;
