import React, { useEffect, useState } from 'react';
import { Message, getUserInitials } from './Message';
import { useRecoilValue } from 'recoil';
import { UserInfoInterface } from '../../utills/interfaces/interface';
import { user } from '../../atoms/user';
import { Avatar, Empty, Tooltip } from 'antd';
import {
  handleCampaignsUsers,
  handleCampaignsUsersThreads,
} from '../../services/campaignServices';
import { useParams } from 'react-router-dom';
import { ShouldRender } from '../basic/ShouldRender';
import Loader from '../ui-elements/Loader/Loader';
import moment from 'moment';

interface IMessage {
  id: number;
  sender: UserInfoInterface;
  messages: any;
}

interface Contact {
  id: number;
  name: string;
}

const ChatBox: React.FC = () => {
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [usersList, setUsersList] = useState<
    {
      id: number;
      name: string;
      updated_at: string;
      avatar: string;
    }[]
  >();
  const [messagesList, setMessagesList] = useState<IMessage[]>();
  const userData = useRecoilValue<UserInfoInterface | undefined>(user);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingThread, setLoadingThread] = useState<boolean>(false);

  const { campaignId } = useParams();

  const formatDataUserList = (data: any) => {
    return {
      id: data.id,
      name: data.name,
      updated_at: data?.chat_thread?.updated_at,
      avatar: data?.avatar,
    };
  };

  const formatDataMessages = (data: any) => {
    return {
      messages: data?.messages?.map((item: any) => {
        return {
          id: data?.id,
          sender: {
            id: item?.sender_id,
            name:
              item?.sender_id === data?.user_id
                ? data?.user_name
                : data?.author_name,
          },
          messages: JSON.parse(item?.message),
        };
      }),
    };
  };

  const getCampaignsUsers = async () => {
    try {
      setLoading(true);
      const response = await handleCampaignsUsers(campaignId as string);
      const formattedData = response?.map(formatDataUserList);
      setUsersList(formattedData);
      if (formattedData.length) {
        setSelectedContact(formattedData[0]);
      }
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };
  const getCampaignsUsersThreads = async () => {
    try {
      setLoadingThread(true);
      const response = await handleCampaignsUsersThreads(
        campaignId as string,
        selectedContact?.id as number
      );
      const formattedData = formatDataMessages(response);
      setMessagesList(formattedData?.messages);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoadingThread(false);
    }
  };

  useEffect(() => {
    if (campaignId) {
      getCampaignsUsers();
    }
  }, []);

  useEffect(() => {
    if (campaignId && selectedContact?.id) {
      getCampaignsUsersThreads();
    }
  }, [campaignId, selectedContact]);
  return (
    <>
      <ShouldRender check={loading}>
        <div className="w-full">
          <Loader />
        </div>
      </ShouldRender>
      <ShouldRender check={!loading}>
        <div className="flex gap-x-4 w-full ">
          <div className="w-1/4 bg-gray-200 my-4  pr-2 pt overflow-auto no-scrollbar border-r-2 border-[#D9D9D9]">
            <div className="flex flex-col gap-y-1  no-scrollbar">
              {usersList?.map((contact) => (
                <div
                  className={`${
                    selectedContact?.id === contact?.id && 'bg-[#F6F6F6]'
                  } flex justify-between no-scrollbar hover:bg-[#F6F6F6] px-2 py-4 rounded-xl cursor-pointer`}
                  key={contact.id}
                  onClick={() => setSelectedContact(contact)}
                >
                  <span className="flex  items-center gap-x-2 justify-center">
                    <Tooltip title={contact?.name} placement="top">
                      <Avatar
                        className="flex-shrink-0 select-none bg-[#FFEAE1] text-primary font-bold text-sm"
                        draggable={false}
                        src={contact?.avatar}
                        shape={'circle'}
                      >
                        {getUserInitials(`${contact?.name}`)}
                      </Avatar>
                    </Tooltip>
                    <p className="font-medium cursor-pointer">{contact.name}</p>
                  </span>
                  <span className="text-xs flex items-end whitespace-nowrap">
                    {moment(contact?.updated_at).format('Do MMM YY, HH:mm')}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="w-3/4 py-4 px-4 overflow-auto ">
            {selectedContact ? (
              <div className="bg-[#F6F6F6] rounded-2xl w-full customScroll gap-y-3   shadow-md p-4 flex flex-col-reverse">
                <ShouldRender check={loadingThread}>
                  <Loader />
                </ShouldRender>
                <ShouldRender check={!loadingThread}>
                  {messagesList
                    ?.reverse()
                    ?.map((msg: IMessage, index: number) => {
                      return (
                        <Message
                          key={`${msg?.sender?.id}-${index}`}
                          isSender={msg.sender.id === userData?.id}
                          showSenderName={true}
                          text={msg.messages}
                          user={msg.sender}
                        />
                      );
                    })}
                </ShouldRender>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <Empty description={'Select a user to start chatting'} />
              </div>
            )}
          </div>
        </div>
      </ShouldRender>
    </>
  );
};

export default ChatBox;
