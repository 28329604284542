import { InboxOutlined } from '@ant-design/icons';
import { TrashIcon } from '@heroicons/react/outline';
import { message } from 'antd';

import React, { DragEvent, useEffect, useRef, useState } from 'react';
import { ShouldRender } from '../../../basic/ShouldRender';

interface FileUploadProps {
  image?: string | null;
  fileKey?: number;
  onFileChange: (file: File) => void;
  onImageRemove: () => void;
  isSession?: boolean;
}

const UploadBannerImage: React.FC<FileUploadProps> = ({
  fileKey,
  onFileChange,
  onImageRemove,
  image,
  isSession = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageFile, setImageFile] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);

  const handleFileChange = (selectedFile: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        const imageDataUrl = event.target.result as string;
        onFileChange(selectedFile);
        if (selectedFile) {
          setImageFile(imageDataUrl);
        }
        message.success(`${selectedFile?.name} file uploaded successfully.`);
      }
    };

    // Ensure selectedFile.type is available, otherwise use a default MIME type
    const mimeType = selectedFile?.type || 'application/octet-stream';

    // Convert selectedFile to Blob
    const blob = new Blob([selectedFile], { type: mimeType });
    reader.readAsDataURL(blob);
  };
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleRemoveFile = () => {
    setImageFile(undefined);
    onImageRemove();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const selectedFile = e.dataTransfer.files[0];

    if (selectedFile) {
      handleFileChange(selectedFile);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (!loaded) {
      setImageFile(image);
    }
    if (isSession) {
      if (image !== null) {
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
  }, [image]);

  return (
    <div
      className="flex justify-center items-center cursor-pointer group text-gray-700 transition-all border-[#E8E8E8] duration-400 ease-in-out hover:text-primary bg-gray-50 hover:border-primary border-2 p-5 border-dashed rounded-lg"
      onClick={handleButtonClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!imageFile ? (
        <label className="cursor-pointer">
          <p className="ant-upload-drag-icon flex justify-center cursor-pointer">
            <InboxOutlined className="text-5xl text-[#E8E8E8] group-hover:text-primary" />
          </p>
          <p className="ant-upload-text cursor-pointer text-textLiteGray group-hover:text-primary">
            Click or drag and drop to upload
          </p>
        </label>
      ) : (
        <ShouldRender check={imageFile !== undefined}>
          <div>
            {imageFile && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRemoveFile();
                }}
                className="flex justify-end hover:text-red-700"
              >
                <TrashIcon className="w-4" />
              </div>
            )}

            <div className="flex flex-col gap-y-4 justify-center items-center px-10 ">
              {imageFile && (
                <img
                  src={imageFile}
                  width={190} // set the width as per your requirement
                  height={190} // set the height as per your requirement
                  className=" "
                  alt="_Image"
                />
              )}
            </div>
          </div>
        </ShouldRender>
      )}

      <input
        key={fileKey}
        type="file"
        id="fileInput"
        ref={fileInputRef}
        className="cursor-pointer"
        accept=".jpg, .png, .jpeg, .webp"
        style={{ display: 'none' }}
        onChange={(e) =>
          e.target.files && handleFileChange(e.target.files[0] as File)
        }
      />
    </div>
  );
};

export default UploadBannerImage;
