import React from 'react';
import MetricCard from '../cards/MetricCard';

const Audience: React.FC = () => {
  return (
    <>
      <div className="pt-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <MetricCard
            title="New Subscribers"
            value="100%"
            percentage="100%"
            trend="100%"
            trendColor="success"
            trendText="from 0 last (4 weeks)"
          />

          <MetricCard
            title={'Unsubscribes'}
            value={'2'}
            percentage={''}
            trend={'100%'}
            trendColor={'danger'}
            trendText={'from 0 last (4 weeks)'}
          />
          <MetricCard
            title={'Net Subscribers'}
            value={'50'}
            percentage={''}
            trend={'0%'}
            trendColor={'black'}
            trendText={'from 0 last (4 weeks)'}
          />
        </div>
      </div>

      {/* import Subscribers */}
      <div className="mt-6 border border-[#e2e2e2] rounded">
        <div className="p-2">
          <h3 className="font-semibold text-xl">Subscription Events</h3>
          <p className="text-[#898989]">
            All subscription events for your publication including premium
            upgrades and downgrades.
          </p>
        </div>
      </div>
    </>
  );
};

export default Audience;
