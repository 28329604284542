import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Button, Modal } from 'antd';

import { useState } from 'react';
import {
  deleteSessionApi,
  deleteWorkShopsApi,
} from '../../../services/liveServices';
import { useNavigate } from 'react-router-dom';

interface Props {
  visible: { value: boolean; name: string; id: string };
  closeModal: () => void;
  isSession?: boolean;
}

const DeleteModal: React.FC<Props> = ({
  closeModal,
  visible,
  isSession = false,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getSingleWorkShopsData = async (id: string) => {
    try {
      setLoading(true);
      if (isSession) {
        await deleteSessionApi(id);
      } else {
        await deleteWorkShopsApi(id);
      }
      closeModal();
      navigate('/live-session');
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible.value}
      maskClosable={false}
      closable={false}
      footer={null}
      onCancel={closeModal}
    >
      <div className="flex flex-col gap-3">
        <span className="text-center flex justify-center">
          <ExclamationCircleIcon className="text-yellow-500 w-12 h-12" />
        </span>
        <div className="flex justify-center text-lg pb-4">
          Are u sure u want to delete `<b>{visible.name}</b>`
        </div>

        <span className="flex justify-end gap-x-2">
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button
            danger
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={() => getSingleWorkShopsData(visible.id)}
          >
            Delete
          </Button>
        </span>
      </div>
    </Modal>
  );
};

export default DeleteModal;
