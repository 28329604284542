import apiClientForCourses from './apiClientForCourses';
import { Endpoints } from './httpendpoints';

export const getAllCourse = async ({
  author,
  limit,
  search,
  isAdmin,
}: {
  author: string;
  limit: number;
  search?: string;
  isAdmin?: boolean;
}) => {
  const queryParams = new URLSearchParams({
    ...(author && isAdmin && { author: author }),
    ...(!isAdmin ? { limit: limit?.toString() } : { limit: '500' }),
    ...(search && { search }),
  });
  const response = await apiClientForCourses.get(
    `${Endpoints.getAllCourses}?${queryParams}`
  );
  return response?.data;
};
