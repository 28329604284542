import React from 'react';

import { FaLayerGroup } from 'react-icons/fa';
import { ShouldRender } from '../../basic/ShouldRender';
import { Empty, Tag } from 'antd';
import Loader from '../../ui-elements/Loader/Loader';
import { WorkshopData } from '../../../pages/LivePage';

interface LiveTabProps {
  loading: boolean;
  workShopId: number | undefined;
  setWorkShopId: React.Dispatch<React.SetStateAction<number | undefined>>;
  workShopData: WorkshopData[];
  getAllWorkShopsData: () => Promise<void>;
  handleUpdateStatus: (id: number, status: string) => Promise<void>;
}

const CompletedTab: React.FC<LiveTabProps> = ({ loading, workShopData }) => {
  return (
    <>
      <ShouldRender check={!loading && workShopData.length}>
        <div className="w-full relative mt-5 flex flex-col gap-y-8">
          {workShopData?.map((item, index) => {
            return (
              <div key={`${item.title}-${index}`} className="flex flex-col">
                <div className="flex gap-x-10 ">
                  <span className="h-[102px] xl:h-[162px] min-w-[200px]  xl:min-w-[276px] w-2/5 xl:w-1/5  rounded-full flex items-center ">
                    <img
                      src={item.live_workshop_banner}
                      alt={'live_workshop_banner'}
                      className="rounded-lg h-full w-full "
                    />
                  </span>
                  <div className="flex justify-between w-full lg:pr-10">
                    <div className="flex flex-col justify-between pb-2">
                      <span className=" flex flex-col gap-y-1">
                        <p className="text-[#9D9D9D]  capitalize">
                          {item.author_name}
                        </p>
                        <p className="text-xl">{item.title}</p>
                        <ShouldRender check={item.session_count}>
                          <p className="flex items-center text-sm gap-x-2">
                            <FaLayerGroup className="text-[#9D9D9D] text-sm" />{' '}
                            {item.session_count} Sessions
                          </p>
                        </ShouldRender>
                      </span>
                      <span>
                        <Tag
                          className="text-sm py-1 px-4 font-medium rounded-lg border-none"
                          color={
                            item.status === 'Pending'
                              ? 'default'
                              : item.status === 'Completed'
                              ? 'processing'
                              : 'success'
                          }
                        >
                          {item.status === 'Pending' ? 'Draft' : item.status}
                        </Tag>
                      </span>
                    </div>
                  </div>
                </div>
                <ShouldRender check={index !== workShopData.length - 1}>
                  <span className="border-b  border-[#f2f0f0] py-4 w-full" />
                </ShouldRender>
              </div>
            );
          })}
        </div>
      </ShouldRender>
      <ShouldRender check={!loading && !workShopData.length}>
        <div className="py-40">
          <Empty description={'No Live workshop found !'} />
        </div>
      </ShouldRender>

      <ShouldRender check={loading}>
        <Loader />
      </ShouldRender>
    </>
  );
};

export default CompletedTab;
