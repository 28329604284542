import { useEffect, useState } from 'react';
import { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import PageNotFound from './components/page-not-found/PageNotFound';
import Login from './pages/Auth/Login';
import SignUp from './pages/Auth/signup/SignUp';
import PrivateWrapper from './routes/PrivateWrapper';
import Loader from './components/ui-elements/Loader/Loader';
import { ConfigProvider, ThemeConfig } from 'antd';
import { RecoilRoot } from 'recoil';
import ForgotPassword from './pages/Auth/ForgotPassword';
import DefaultLayout from './layout/DefaultLayout';
import ResetPasswordPage from './pages/reset-password';
import Dashboard from './pages/Dashboard/Dashboard';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const config: ThemeConfig = {
    token: {
      colorPrimary: '#FF5612',
    },
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <ToastContainer />
      <HashRouter>
        <RecoilRoot>
          <ConfigProvider theme={config}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/auth/reset-password/"
                element={<ResetPasswordPage />}
              />
              <Route element={<DefaultLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="*" element={<PageNotFound />} />
                {routes.map(({ path, component: Component }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <PrivateWrapper>
                        <Suspense fallback={<Loader />}>
                          <Component />
                        </Suspense>
                      </PrivateWrapper>
                    }
                  />
                ))}
              </Route>
            </Routes>
          </ConfigProvider>
        </RecoilRoot>
      </HashRouter>
    </>
  );
}

export default App;
