import {
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Button, Collapse, Form, Input, Modal, Select, Spin } from 'antd';

import { useEffect, useMemo, useState } from 'react';
import { ShouldRender } from '../../../../components/basic/ShouldRender';
import CardWithCheckbox, {
  IData,
} from '../../../../components/ChatBox/CardWithCheckbox';

import { Endpoints } from '../../../../services/httpendpoints';

import { user } from '../../../../atoms/user';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getAllCourse } from '../../../../services/courses';
import { notify } from '../../../../components/basic/notity';
import {
  getAllLongBookListApi,
  getAllShortBookListApi,
} from '../../../../services/readers';
import {
  getAllAuthorsListApi,
  getAllSchoolListApi,
} from '../../../../services/apiservices';
import {
  CategoriesType,
  CategoryKey,
  EventType,
  ICondition,
  ProductType,
  Source,
} from '../../../../utills/interfaces/BrodcastMessages';
import { triggerSendMessage } from '../../../../atoms/triggerSendMessage';
import ButtonDefault, {
  ButtonVariants,
} from '../../../../components/basic/button';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
interface Props {
  visible: boolean;
  closeModal: () => void;
  formData: ICondition[];
  setFormData: any;
  handleCreateCampaigns?: any;
  modalLabel: string;
  setAuthorId: (value: number | undefined) => void;
}

function customExpandIcon(props: any) {
  if (props.isActive) {
    return (
      <span className="pt-5 ">
        <ChevronUpIcon className="w-5 text-gray-700" />
      </span>
    );
  } else {
    return (
      <span className="pt-5 ">
        <ChevronDownIcon className="w-5 text-gray-700" />
      </span>
    );
  }
}

const TargetAudienceModal: React.FC<Props> = ({
  closeModal,
  visible,
  formData,
  setFormData,
  modalLabel,
  setAuthorId,
}) => {
  //   const [form] = Form.useForm();
  const [courseLoading, setCourseLoading] = useState(false);
  const [shortBookLoading, setShortBookLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [, setTrigger] = useRecoilState(triggerSendMessage);

  const [courses, setCourses] = useState<IData[]>([]);
  const [school, setSchool] = useState<IData[]>([]);
  const [isLoadingSchool, setIsLoadingSchool] = useState(false);
  const [shortBooks, setShortBooks] = useState<IData[]>([]);
  const [longBooks, setBooks] = useState<IData[]>([]);
  const [search, setSearch] = useState<{ [key in CategoryKey]?: string }>({});
  const [author, setAuthor] = useState<string | undefined>(undefined);
  const [authorList, setAuthorList] = useState<
    {
      name: string;
      zl_uid: string;
      id: number;
    }[]
  >();

  const userData = useRecoilValue<any>(user);
  const [value, setValue] = useState('1');
  const pageLimit = 40;
  const getAbilitiesData = localStorage.getItem('abilitiesData');
  const isAdmin = (
    getAbilitiesData ? (JSON.parse(getAbilitiesData) as string[]) : []
  ).includes('view-all-users');

  const dataCategories = [
    {
      key: 1,
      title: 'Select Book',
      description: 'Select Book which you’re targeting',
      data: longBooks,
      isScrollable: true,
      loading: bookLoading,
      type: 'longBook',
    },
    {
      key: 2,
      title: 'Select Course',
      description: 'Select Course which you’re targeting',
      data: courses,
      isScrollable: true,
      loading: courseLoading,
      type: 'course',
    },
    {
      key: 3,
      title: 'Select Short Books',
      description: 'Select Short Book which you’re targeting',
      data: shortBooks,
      isScrollable: true,
      loading: shortBookLoading,
      type: 'shortBook',
    },
    {
      key: 4,
      title: 'Select School',
      description: 'Select School which you’re targeting',
      data: school,
      isScrollable: true,
      loading: isLoadingSchool,
      type: 'schools',
    },
  ];

  const renderPanelContent = (
    data: any,
    isScrollable: boolean,
    loading: boolean,
    type: string
  ) => {
    return (
      <>
        <ShouldRender check={data.length && !loading}>
          <div
            className={clsx(
              isScrollable && 'overflow-y-auto px-2 max-h-56 no-scrollbar',
              `flex flex-col gap-y-2 `
            )}
          >
            {data.map((item: any) => (
              <CardWithCheckbox
                key={item.id}
                data={item}
                isChecked={
                  formData?.some(
                    (check) => check.product_id === item?.id?.toString()
                  ) || false
                }
                onChange={(data) => handleCheckboxChange(data)}
              />
            ))}
          </div>
        </ShouldRender>
        <ShouldRender check={!data.length && !loading}>
          <div className="text-center font-medium text-gray-200">
            No {data?.title?.toLowerCase()} found.
          </div>
        </ShouldRender>
        <ShouldRender check={loading}>
          <div className="flex items-center justify-center py-5">
            <Spin size="large" />
          </div>
        </ShouldRender>
      </>
    );
  };

  const handleClose = () => {
    closeModal();
    setValue('1');
    setSearch({
      shortBook: '',
      longBook: '',
      course: '',
      schools: '',
    });
    setFormData([]);
    setAuthorId(undefined);
    setAuthor(undefined);
  };

  const handleGetAllCourse = async () => {
    try {
      setCourseLoading(true);
      const response = await getAllCourse({
        author: (isAdmin ? author?.toString() : userData?.zl_uid) as string,
        limit: pageLimit,
        search: search.course as string,
        isAdmin: isAdmin,
      });
      const { data } = response;

      const formattedCourses = (data ?? response)?.map((course: any) => ({
        id: course?.ecom_product_id,
        image: course?.course_image_url,
        name: course?.name,
        source: Source.ECOMMERCE,
        product_type: ProductType.COURSES,
        event: EventType.PURCHASED,
        operator: '=',
        value: '1',
      }));
      setCourses(formattedCourses);
    } catch (error: any) {
      notify(error, 'error');
      setCourses([]);
    } finally {
      setCourseLoading(false);
    }
  };

  const getCategoryValue = (
    category: CategoryKey
  ): (typeof CategoriesType)[CategoryKey] => {
    return CategoriesType[category];
  };

  const generateUrlForImage = (
    baseUrl: string | undefined,
    endpoint: string,
    id: string
  ): string => {
    const encodedId = encodeURIComponent(id);
    if (baseUrl) {
      const url = `${baseUrl}${endpoint}${encodedId}`;
      return url;
    } else {
      const url = `${endpoint}`;
      return url;
    }
  };

  const handleGetAllLongBooks = async () => {
    try {
      setBookLoading(true);

      const response = await getAllLongBookListApi({
        author: (isAdmin ? author?.toString() : userData?.zl_uid) as string,
        limit: pageLimit,
        search: search.longBook,
        isAdmin: isAdmin,
      });
      const { data } = response;

      const formattedBooks = data
        ?.map((book: any) => ({
          id: book?.hardcoverProductId,
          image: generateUrlForImage(
            `${process.env.REACT_APP_API_READER}/`,
            `${Endpoints.getCoverImagesForBooks}/`,
            `${book?._id}`
          ),
          name: book?.title,
          type: book.type,
          source: Source.ECOMMERCE,
          product_type: ProductType.BOOK,
          event: EventType.PURCHASED,
          operator: '=',
          value: '1',
        }))
        .filter((book: any) => book.type === 'long');

      setBooks(formattedBooks);
    } catch (error: any) {
      notify(error, 'error');
      setBooks([]);
    } finally {
      setBookLoading(false);
    }
  };

  const handleGetAllShortBooks = async () => {
    try {
      setShortBookLoading(true);
      const response = await getAllShortBookListApi({
        author: (isAdmin ? author?.toString() : userData?.zl_uid) as string,
        limit: pageLimit,
        search: search.shortBook,
        isAdmin: isAdmin,
      });
      const { data } = response;

      const formattedBooks = data?.map((book: any) => ({
        id: book?.digitalProductId,
        image: generateUrlForImage(
          `${process.env.REACT_APP_API_READER}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${book?._id}`
        ),
        name: book?.title,
        source: Source.SHORT_BOOKS,
        product_type: ProductType.SHORT_BOOK,
        event: EventType.VIEWED,
        operator: '=',
        value: '1',
      }));

      setShortBooks(formattedBooks);
    } catch (error: any) {
      notify(error, 'error');
      setShortBooks([]);
    } finally {
      setShortBookLoading(false);
    }
  };

  const getAllSchoolListData = async () => {
    try {
      setIsLoadingSchool(true);
      const response = await getAllSchoolListApi({
        author: (isAdmin ? author?.toString() : userData?.zl_uid) as string,
        name: search.shortBook,
        isAdmin: isAdmin,
      });
      setSchool(
        response?.data?.data?.map((school: any) => ({
          id: school?.id,
          image: generateUrlForImage(
            undefined,
            `${Endpoints.getSchoolImagesByS3Bucket}/${school?.documents?.file_path}`,
            `${school?._id}`
          ),
          name: school?.name,
          source: Source.SCHOOLS,
          product_type: ProductType.SCHOOL,
          event: EventType.VIEWED,
          operator: '=',
          value: '1',
        }))
      );
    } catch (error) {
      console.error('API call error:', error);
      setSchool([]);
    } finally {
      setIsLoadingSchool(false);
    }
  };

  const getAllAuthors = async () => {
    try {
      const response = await getAllAuthorsListApi();
      setAuthorList(
        response?.data?.data?.map((author: any) => ({
          name: author?.name,
          zl_uid: author?.zl_uid,
          id: author?.id,
        }))
      );
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setIsLoadingSchool(false);
    }
  };

  const handleCheckboxChange = (data: ICondition) => {
    setFormData((prevFormData: ICondition[]) => {
      // Check if the item already exists in the array
      const itemExists = prevFormData.some(
        (item) => item.product_id === data.product_id
      );

      // Toggle the item: if it exists, remove it; if not, add it
      if (itemExists) {
        return prevFormData.filter(
          (item) => item.product_id !== data.product_id
        );
      } else {
        return [...prevFormData, { ...data }];
      }
    });
  };

  const transformObject = (item: any) => {
    return {
      event: item.event,
      operator: item.operator,
      product_id: item.id,
      product_type: item.product_type,
      source: item.source,
      value: item.value,
    };
  };

  const handleSelectAll = (data: any) => {
    setFormData((prevFormData: ICondition[]) => {
      // Create a map to store unique objects by their product_id
      const idToObjectMap: { [key: string]: any } = {};

      // Add all previous form data objects to the map
      prevFormData.forEach((item) => {
        idToObjectMap[item.product_id] = item;
      });

      // Add all new data objects to the map, after transforming them
      data.forEach((item: any) => {
        const transformedItem = transformObject(item);
        idToObjectMap[transformedItem.product_id] = transformedItem;
      });

      // Convert the map back to an array of objects
      return Object.values(idToObjectMap);
    });
  };
  useEffect(() => {
    if (visible) {
      handleGetAllCourse();
    }
  }, [search.course, author]);

  useEffect(() => {
    if (visible) {
      handleGetAllShortBooks();
    }
  }, [search.shortBook, author]);

  useEffect(() => {
    if (visible) {
      getAllSchoolListData();
    }
  }, [search.schools, author]);

  useEffect(() => {
    if (visible) {
      handleGetAllLongBooks();
    }
  }, [search.longBook, author]);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      handleGetAllCourse();
      handleGetAllShortBooks();
      handleGetAllLongBooks();
      getAllSchoolListData();
      getAllAuthors();
      setLoading(false);
    }
  }, [userData, visible]);

  const handleSearchChange = (name: CategoryKey, value: string) => {
    setSearch((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const debouncedHandleSearchChange = useMemo(
    () => debounce(handleSearchChange, 500),
    []
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    debouncedHandleSearchChange(name as CategoryKey, value);
  };

  const handleChange = (value: string) => {
    setAuthor(value);
    const authorId = authorList?.filter((author) => author.zl_uid === value);
    if (authorId?.length && authorId[0]?.id) {
      setAuthorId(authorId[0].id);
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string | number }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      open={visible}
      closable={false}
      footer={null}
      centered
      onCancel={handleClose}
      className="custom-modal"
    >
      <>
        <ShouldRender check={!loading}>
          <div className="flex flex-col ">
            <span className="flex justify-between px-7 rounded-t-xl bg-[#F6F6F6]  py-4">
              <span>
                <p className="text-lg  font-medium">
                  Select Your Target Audience
                </p>
                <p className="text-[#737373]">
                  Trading Mastermind - Become a Trading Exp...
                </p>
              </span>
              <div className="flex items-center">
                <div
                  onClick={() => handleClose()}
                  className="border border-borderGrayColor cursor-pointer p-1 w-7 h-7 rounded-full flex justify-center items-center"
                >
                  <XIcon className="w-4 h-4" />
                </div>
              </div>
            </span>

            <Form layout="vertical" className="flex  p-5 gap-y-4 flex-col ">
              <ShouldRender check={isAdmin}>
                <Form.Item>
                  <div className="p-4 border rounded-md border-[#bfbdbd]">
                    <span>
                      <span>
                        <span className="flex gap-x-4">
                          <p className="text-lg font-medium whitespace-nowrap">
                            Select Author
                          </p>
                        </span>
                      </span>

                      <p className="text-[#737373] mb-1">
                        Select author which you're targeting
                      </p>
                    </span>
                    <Select
                      placeholder="Search Author"
                      suffixIcon={
                        <SearchOutlined className="w-3 mr-2 h-3 text-gray-400 hover:text-gray-800 cursor-pointer" />
                      }
                      allowClear
                      showSearch
                      value={author}
                      onChange={handleChange}
                      filterOption={filterOption}
                      options={authorList
                        ?.filter((author) => author.zl_uid !== null)
                        .map((author) => ({
                          label: author.name,
                          value: author.zl_uid,
                        }))}
                    />
                  </div>
                </Form.Item>
              </ShouldRender>

              <ShouldRender check={!isAdmin ? true : isAdmin && author}>
                <Form.Item>
                  <Collapse
                    expandIconPosition="end"
                    expandIcon={(props) => customExpandIcon(props)}
                    className="antdBorder"
                    accordion
                    onChange={(value) => setValue(value as string)}
                    defaultActiveKey={value}
                    activeKey={value}
                  >
                    {dataCategories.map((category) => (
                      <Panel
                        className="bg-white border-none antDBorderBottom py-1"
                        header={
                          <span>
                            <span>
                              <span className="flex gap-x-4">
                                <p className="text-lg font-medium whitespace-nowrap">
                                  {category.title}
                                </p>
                              </span>
                            </span>

                            <p className="text-[#737373]">
                              {category.description}
                            </p>
                          </span>
                        }
                        key={category.key}
                        style={{ padding: '0px' }}
                      >
                        <div className="flex gap-x-2 justify-between pb-2">
                          <Input
                            size="small"
                            name={category.type}
                            allowClear
                            // value={search[category.type as CategoryKey]}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onChange={onInputChange}
                            placeholder={`Search ${getCategoryValue(
                              category.type as CategoryKey
                            )}`}
                            className="border-gray-400 w-3/4"
                            prefix={
                              <SearchIcon className="w-3 mr-2 h-3 text-gray-400  hover:text:gray-800 cursor-pointer" />
                            }
                          />

                          <Button
                            onClick={() => handleSelectAll(category.data)}
                            size="middle"
                          >
                            Select All
                          </Button>
                        </div>
                        {renderPanelContent(
                          category.data,
                          category?.isScrollable,
                          category?.loading,
                          category.type
                        )}
                      </Panel>
                    ))}
                  </Collapse>
                </Form.Item>
              </ShouldRender>
              <span className="flex gap-x-4 w-full ">
                <button
                  onClick={handleClose}
                  className="rounded-xl w-1/3 border border-borderGrayColor font-medium   hover:bg-lightgray px-6  py-3   "
                >
                  Cancel
                </button>
                <ButtonDefault
                  variant={ButtonVariants.UNSTYLED}
                  size={3}
                  disabled={!formData.length}
                  onClick={() =>
                    setTrigger({
                      value: true,
                      label: modalLabel,
                    })
                  }
                  className="rounded-xl w-2/3 font-medium disabled:cursor-not-allowed bg-black text-white hover:bg-[#2b2b2b] px-6  py-3 flex items-center  "
                >
                  Send
                </ButtonDefault>
              </span>
            </Form>
          </div>
        </ShouldRender>
        <ShouldRender check={loading}>
          <div className="h-[80vh] flex justify-center items-center">
            <Spin size="large" />
          </div>
        </ShouldRender>
      </>
    </Modal>
  );
};

export default TargetAudienceModal;
