/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import clsx from 'clsx';
// import '../../../../../../pages/quillEditorCss.css';

interface DataItem {
  id: number;
  type: string;
  value: string | File | {};
  color?: string;
  altText?: string;
  link?: string;
  url?: string;
}

interface RendererProps {
  data: DataItem[];
}

const MessageRender: React.FC<RendererProps> = ({ data }) => {
  const formatStringToList = (string: string) => {
    const points = string.split('•').filter((point) => point.trim() !== '');

    const listItems = points.map((point) => {
      const formattedPoint = point.trim();
      return (
        <li
          className="text-[16px] font-normal mt-[0.5em] text-gray-100   leading-7 md:leading-8 tracking-tight list-disc whitespace-pre-line"
          key={formattedPoint}
        >
          {formattedPoint}
        </li>
      );
    });

    return <ul className="list-disc pl-4 md:pl-8 ">{listItems}</ul>;
  };
  return (
    <div className="flex justify-center  w-fit mb-2">
      <div className="flex flex-col w-fit">
        {data.map((item, index) => {
          let element: React.ReactNode;
          let marginBottomClass = ''; // Initialize the class for bottom margin

          // Check if the next item is a title and add bottom margin if true
          if (index < data.length - 1 && data[index + 1]?.type === 'title') {
            marginBottomClass = ' ';
          }

          switch (item.type) {
            case 'paragraph':
              element = (
                <p
                  key={item.id}
                  className={`text-[16px] font-normal text-gray-100   leading-7 md:leading-8 tracking-tight  whitespace-pre-line mt-[0.94em] ${marginBottomClass}`}
                  dangerouslySetInnerHTML={{
                    __html: (item.value as string).replace(
                      /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>(.*?)<\/a>/g,
                      '<a href="$1" target="_blank" style="color: #FF5612; text-decoration: underline;"$2>$3</a>'
                    ),
                  }}
                ></p>
              );
              break;
            case 'title':
              element = (
                <h2
                  key={`${item.id}-title`}
                  style={{
                    color:
                      item.type === 'title' && item.color ? item.color : '',
                    lineHeight: '1',
                  }}
                  className={clsx(
                    index !== 0 && 'mt-5 md:mt-8',
                    'text-[20px] md:text-[24px]  w-auto leading-6 md:leading-8  tracking-normal md:tracking-tighter font-semibold whitespace-pre-line pt-2 xl:pt-5'
                  )}
                >
                  {item.value as string}
                </h2>
              );
              break;
            case 'html':
              element = (
                <div className="customCkeditor" key={`CKEditor-${item.id}`}>
                  <CKEditor
                    disabled={true}
                    className="customCkeditor"
                    config={{
                      toolbar: [],
                      readOnly: true,
                    }}
                    data={item.value}
                    editor={ClassicEditor}
                  />
                </div>
              );
              break;
            case 'image':
              element = (
                <div
                  className="pt-2 flex justify-center"
                  key={`image-${item.id}`}
                >
                  {item.link ? (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${
                          process.env.NEXT_PUBLIC_API_URL_IMAGE_BASE_URL_SCHOOLS
                        }/${item.value as string}`}
                        alt={item?.altText}
                        className="w-fit rounded-md"
                      />
                    </a>
                  ) : (
                    <img
                      src={`${
                        process.env.NEXT_PUBLIC_API_URL_IMAGE_BASE_URL_SCHOOLS
                      }/${item.value as string}`}
                      alt={item?.altText}
                      className="w-fit rounded-md"
                    />
                  )}
                </div>
              );
              break;
            case 'anchor':
              const imageUrl = `${
                process.env.NEXT_PUBLIC_API_URL_IMAGE_BASE_URL_SCHOOLS
              }/${item.value as string}`;
              element =
                item.value !== undefined ? (
                  <a
                    key={`${item.id}-anchor`}
                    href={item.url || ''}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-full relative">
                      <img
                        src={imageUrl}
                        alt="Image"
                        className="object-contain w-full   h-full rounded-md z-10"
                      />
                      <div className="bg-gradient-to-t to-black px-4 pb-4 pt-8 rounded-b-md from-[#3D3D3D] text-xl absolute bottom-0 z-20 w-full">
                        <div className="flex justify-between">
                          <span>Know More</span>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                ) : null;
              break;
            case 'bullet':
              element = (
                <div key={`${item.id}-bullet`} className=" ">
                  {formatStringToList(item.value as string)}
                </div>
              );
              break;
            default:
              element = null;
          }
          return element;
        })}
      </div>
    </div>
  );
};

export default MessageRender;
