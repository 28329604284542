import { Modal, Select, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { useUsers } from '../../../hooks/useUsers';
import {
  UserData,
  userRolesEnumMapping,
} from '../../../utills/interfaces/user';
import ButtonDefault, { ButtonVariants } from '../../basic/button';

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  editId?: number | undefined;
}

const UserModal: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  editId,
}) => {
  const { roles, getAllRoles, getUser, user, updateUser, loadingEdit } =
    useUsers();

  useEffect(() => {
    getAllRoles();
  }, []);
  const formattedObject = useMemo(() => {
    return {
      name: user?.name || '',
      role: user?.role_id,
    };
  }, [user]);

  const [formData, setFormData] = useState<UserData>({
    name: '',

    role: undefined,
  });

  const [errors, setErrors] = useState({
    name: '',
    role: '',
  });

  useEffect(() => {
    if (editId) {
      getUser(editId);
    }
  }, [editId]);

  useEffect(() => {
    if (editId) {
      setFormData(formattedObject);
    }
  }, [user]);

  const selectOptions = useMemo(() => {
    return roles?.map((item) => ({
      label: userRolesEnumMapping[item.name] || item.name,
      value: item.id,
    }));
  }, [roles]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      name: '',
      email: '',
      gender: '',
      password: '',
      phoneNumber: '',
      role: '',
    };

    if (!formData.name) {
      validationErrors.name = 'Name is required';
    }

    if (formData.role === undefined) {
      validationErrors.role = 'Role is required';
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      await updateUser(formData, editId);
      handleOk();
      handleClose();
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',

      role: undefined,
    });
    setErrors({
      name: '',

      role: '',
    });
    handleCancel();
  };

  return (
    <Modal
      title={editId ? 'Update User Role' : 'Add new user'}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={
        <div className="flex gap-x-3 justify-end mt-4">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleClose}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            onClick={handleSubmit}
          >
            <span className="px-6 py-1">{editId ? 'Update' : 'Create'}</span>
          </ButtonDefault>
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        {loadingEdit ? (
          <div
            className="h-40 flex items-center justify-center"
            style={{ textAlign: 'center' }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 border-t pt-4">
            {/* <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Name</span>
              <Input
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                size="large"
                className=" "
              />
              {errors.name && (
                <span className="text-red-500">{errors.name}</span>
              )}
            </div> */}

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Role</span>
              <Select
                size="large"
                placeholder="Select role"
                value={formData.role}
                onChange={(value) => setFormData({ ...formData, role: value })}
                style={{ width: '100%' }}
                options={selectOptions}
              />
              {errors.role && (
                <span className="text-red-500">{errors.role}</span>
              )}
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default UserModal;
