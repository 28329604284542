import { FC } from 'react';
import clsx from 'clsx';

import { Avatar, Tooltip } from 'antd';
import { ShouldRender } from '../../basic/ShouldRender';
import { UserInfoInterface } from '../../../utills/interfaces/interface';
import MessageRender from '../../../pages/schools/schools-blog/blogs/Editor/Render/MessageRender';

interface MessageProps {
  isSender: boolean;
  isTyping?: boolean;
  showSenderName: boolean;
  text?: any;
  user: UserInfoInterface | undefined;
}

export function getUserInitials(fullName: string): string {
  if (!fullName) return '';
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

export const Message: FC<MessageProps> = ({
  isSender,
  isTyping = false,
  showSenderName,
  text,
  user: userData,
}) => {
  const messageClassName = clsx(
    'px-4 py-2.5 rounded-xl text-sm break-words w-fit flex flex-col w-11/12 lg:w-4/5',
    isSender ? 'bg-[#E9E8E8] self-end' : 'bg-white'
  );
  const authorName = `${userData?.name}`;

  return (
    <div className={clsx('flex ', isSender ? 'justify-end' : 'justify-start')}>
      <div className="px-4">
        <div
          className={clsx(
            'flex items-end h-full gap-2',
            isSender ? 'flex-row' : 'flex-row-reverse'
          )}
        >
          <div className="flex flex-col text-sm font-medium  text-gray-800">
            <span className={messageClassName}>
              <ShouldRender check={showSenderName && !isSender}>
                <span
                  className={clsx(' text-[#716FE3]  text-xs', {
                    'text-left': !isSender,
                    'text-right': isSender,
                  })}
                >
                  {authorName}
                </span>
              </ShouldRender>
              <ShouldRender check={text && text?.length > 0}>
                <MessageRender data={text} />
              </ShouldRender>
              <ShouldRender check={isTyping}>Typing...</ShouldRender>
            </span>
          </div>
          <div className="flex items-end h-full pb-1">
            <Tooltip title={userData?.name} placement="top">
              <Avatar
                className="flex-shrink-0 select-none"
                draggable={false}
                // src={contact?.avatar}
                shape={isSender ? 'circle' : 'square'}
              >
                {getUserInitials(`${userData?.name}`)}
              </Avatar>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
