import React from 'react';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import ChatBox from '../../../components/ChatBox';

const BroadCastMessages = () => {
  const navigate = useNavigate();
  // const [reload,setReload] = useState(false)

  // useEffect(() => {
  //   if (typeof window !== undefined && reload.) {
  //     setReload(true)
  //     window.location.reload();
  //   }
  // }, []);

  return (
    <>
      <div className="w-full h-full overflow-hidden   z-10 ">
        <div className="w-full relative mt-5  z-10 overflow-hidden ">
          <div className=" flex pb-6 flex-col gap-y-4 w-full h-[85vh]">
            <div className="border-b pb-4  border-[#f2f0f0] -top-2 z-20 w-full">
              <div className="flex justify-between items-center w-full">
                <span
                  onClick={() => {
                    navigate(`/broadcast`);
                  }}
                  className="font-bold text-base flex gap-x-3 cursor-pointer hover:text-[#595858] items-center"
                >
                  <MdOutlineKeyboardArrowLeft className="text-2xl" /> Broadcast
                  Message
                </span>
              </div>
            </div>

            <div
              className=" flex  no-scrollbar w-full "
              style={{ overflowY: 'hidden' }}
            >
              <ChatBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BroadCastMessages;
