import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import DASHBOARD_ICON from '../../assets/images/sidebar-icons/Dashboard.png';
import BLOGS_ICON from '../../assets/images/sidebar-icons/school.png';
import Cookies from 'js-cookie';
import { LIVE_SESSION, NEWSLETTER } from '../../assets';
import { TbBroadcast } from 'react-icons/tb';
import { HiUsers } from 'react-icons/hi';
import { CheckPermission } from '../basic/CheckPermission';
import appwriteService from '../../config/appwrite';
import { tokenUpdate } from '../../atoms/authModalControl';
import { useRecoilState } from 'recoil';

interface MenuItem {
  path: string;
  icon: string | Element | JSX.Element;
  label: string;
  disabled?: boolean;
  permissionCheck: string;
}

const menuItems: MenuItem[] = [
  {
    path: '/dashboard',
    icon: DASHBOARD_ICON,
    label: 'Dashboard',
    permissionCheck: '',
  },
  {
    path: '/school',
    icon: BLOGS_ICON,
    label: 'School',
    permissionCheck: 'manage-schools',
  },
  {
    path: '/broadcast',
    icon: <TbBroadcast className="text-xl" />,
    label: 'Broadcast',
    permissionCheck: 'manage-chat-campaigns',
  },
  // {
  //   path: '/newsLetters',
  //   icon: NEWSLETTER,
  //   label: 'Newsletter',
  //   permissionCheck: '',
  // },
  {
    path: '/live-session',
    icon: LIVE_SESSION,
    label: 'Live Session',
    permissionCheck: 'manage-live-workshops',
  },
  {
    path: '/users',
    icon: <HiUsers className="text-2xl text-graydark" />,
    label: 'Users',
    permissionCheck: 'manage-users',
  },
  // { path: '/affliate', icon: AFFLIATE, label: 'Affiliate' },
  // { path: '/create-ads', icon: CREATE_ADS, label: 'Create Ads' },
  // { path: '/bulk-email', icon: BULK_EMAIL, label: 'Bulk Email' },
];
interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

function Sidemenu({ sidebarOpen, setSidebarOpen }: SidebarProps) {
  const location = useLocation();
  const [abilitiesData, setAbilitiesData] = useState<
    string[] | string | null
  >();
  const [, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const { pathname } = location;
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);
  const navigate = useNavigate();

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const getAbilitiesData = localStorage.getItem('abilitiesData');

  const [sidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [sidebarOpen]);

  useEffect(() => {
    setAbilitiesData(JSON.parse(getAbilitiesData as string));
  }, [getAbilitiesData]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [sidebarOpen]);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const handleLogout = async () => {
    await appwriteService.logout();
    setIsTokenChanged(true);

    Cookies.remove('auth_token');
    localStorage.removeItem('abilitiesData');
    navigate('/');
  };

  const isSchoolSectionEnabled = abilitiesData?.includes('manage-schools');
  // Check if 'manage-live-workshop-sessions' is present in the array
  const isLiveWorkshopEnabled = abilitiesData?.includes(
    'manage-live-workshops'
  );
  const isBroadcastEnabled = abilitiesData?.includes('manage-chat-campaigns');
  const isManageUser = abilitiesData?.includes('manage-users');

  // Update the menu items array
  const updatedMenuItems = menuItems.map((item) => {
    if (item.path === '/dashboard' || item.path === '/newsLetters') {
      return { ...item, disabled: false };
    } else if (item.path === '/school') {
      return { ...item, disabled: !isSchoolSectionEnabled };
    } else if (item.path === '/users') {
      return { ...item, disabled: !isManageUser };
    } else if (item.path === '/live-session') {
      return { ...item, disabled: !isLiveWorkshopEnabled };
    } else if (item.path === '/broadcast') {
      return { ...item, disabled: !isBroadcastEnabled };
    } else {
      return item;
    }
  });

  return (
    <div
      ref={sidebar}
      className={`w-52.5 h-[100%] border-r border-stroke shadow-md bg-white flex flex-col justify-between p-4 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full absolute left-0'
      }`}
    >
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav>
          <div>
            <ul className="flex flex-col items-center gap-4">
              {updatedMenuItems.map((item) => (
                <li key={item.path}>
                  <CheckPermission check={item?.permissionCheck}>
                    <NavLink
                      to={item.path}
                      className={`group relative flex items-center justify-center hover:text-primary bg-[#F6F6F9] w-20 h-16 rounded-xl font-medium text-black duration-300 ease-in-out ${
                        pathname.includes(item.path) &&
                        ' border-primary border-2 bg-[#FFF3F0]'
                      }`}
                    >
                      {typeof item.icon === 'string' ? (
                        <img src={item.icon} alt="" />
                      ) : (
                        <>{item.icon}</>
                      )}
                    </NavLink>

                    <div
                      className={`group relative flex items-center justify-center gap-2.5 text-lg rounded-sm duration-300 ease-in-out ${
                        pathname.includes(item.path) && 'text-primary'
                      }`}
                    >
                      <b className="text-sm">{item.label}</b>
                    </div>
                  </CheckPermission>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
      <div className="flex items-center justify-center">
        <button
          className="inline-flex items-center justify-center gap-2.5 py-2 px-10 rounded-md bg-[#FE5512] text-center font-bold text-white hover:bg-opacity-90"
          onClick={handleLogout}
        >
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
}

export default Sidemenu;
