import { useState } from 'react';
import {
  DataSourceItem,
  RolesItems,
  UserData,
} from '../utills/interfaces/user';
import apiClient from '../services/httpservices';
import { Endpoints } from '../services/httpendpoints';
import { formateErrorObject } from '../components/basic/formateError';
import { notify } from '../components/basic/notity';
import { countryList } from '../utills/data';

export interface PaginationProps {
  current_page: number;
  per_page: number;
  total: number;
}

export const useUsers = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [allUsers, setUsers] = useState<DataSourceItem[]>();
  const [roles, setRoles] = useState<RolesItems[]>();
  const [user, setUser] = useState<DataSourceItem>();
  const [paginationData, setPaginationData] = useState<PaginationProps>();

  const getAllUsers = async (page?: number) => {
    try {
      setLoading(true);
      const response = await apiClient().get(
        `${Endpoints.allUsers}?page=${page}`
      );

      const { data } = response;

      setUsers(data?.data?.data);
      setPaginationData({
        current_page: data?.data?.current_page,
        per_page: data?.data?.per_page,
        total: data?.data?.total,
      });
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await apiClient().get(Endpoints.roles);

      const { data } = response;

      setRoles(data?.data);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const getUser = async (id: number) => {
    try {
      setLoadingEdit(true);
      const response = await apiClient().get(`${Endpoints.allUsers}/${id}`);

      const { data } = response;

      setUser(data?.data);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoadingEdit(false);
    }
  };

  const updateUser = async (userData: UserData, id: number | undefined) => {
    const { role } = userData;

    try {
      const requestData = {
        role_id: role,
      };

      const response = await apiClient().post(
        `${Endpoints.updateUserRole}/${id}`,
        requestData
      );

      if (response) {
        getAllUsers();
        notify('User Edited successfully!', 'success');
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const updateStatus = async (
    id: number | null,
    status: number | null
  ): Promise<any> => {
    const response = await apiClient().post(`${Endpoints.updateUser}/${id}`, {
      is_verified: status,
    });

    return response;
  };

  const getCountryName = (countryCode: string) => {
    const country = countryList.find((c) => c.code === countryCode);
    return country ? country.name : 'Unknown Country';
  };

  return {
    loading,
    allUsers,
    getAllUsers,
    getAllRoles,
    roles,
    getUser,
    user,
    updateStatus,
    updateUser,
    loadingEdit,
    getCountryName,
    paginationData,
  };
};
