import { UploadIcon, XIcon } from '@heroicons/react/outline';
import React, { DragEvent, useEffect, useRef, useState } from 'react';
import { Button, Input, Popover, Spin } from 'antd';
import { FaCheck } from 'react-icons/fa6';

interface FileUploadProps {
  imageUrl?: string;
  fileKey?: number;
  onFileChange: (file: File) => void;
  handleInputChange: (
    id: number,
    name: string,
    value: string | File | null
  ) => void;
  handleRemove: (idToRemove: number) => void;
  loading: boolean;
  id: number;
  altText?: string;
  visibleLinkText?: string;
}

const UploadImage: React.FC<FileUploadProps> = ({
  imageUrl,
  fileKey,
  onFileChange,
  loading,
  handleRemove,
  id,
  handleInputChange,
  altText,
  visibleLinkText,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [visible, setVisible] = useState(false);
  const [visibleLink, setVisibleLink] = useState(false);

  const popoverRef = useRef<HTMLDivElement>(null);
  const popoverLinkRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        popoverRef.current.contains &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverLinkRef.current &&
        popoverLinkRef.current.contains &&
        !popoverLinkRef.current.contains(event.target as Node)
      ) {
        setVisibleLink(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleVisibleChangeLink = (newVisible: boolean) => {
    setVisibleLink(newVisible);
  };

  const handleFileChange = (selectedFile: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        onFileChange(selectedFile);
      }
    };

    const mimeType = selectedFile?.type || 'application/octet-stream';
    const blob = new Blob([selectedFile], { type: mimeType });
    reader.readAsDataURL(blob);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const selectedFile = e.dataTransfer.files[0];

    if (selectedFile) {
      handleFileChange(selectedFile);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      {imageUrl ? (
        <div className="flex flex-col relative gap-y-4 px-2 py-4 justify-center items-center ">
          {loading ? (
            <div className="flex justify-center items-center w-full h-40">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="absolute right-6 top-6">
                <div className="flex justify-center flex-col gap-y-2">
                  <span className="flex justify-center">
                    <span className=" cursor-pointer w-min bg-grayBg  bg-opacity-40    transition-transform transform hover:scale-110 rounded-full p-1">
                      <XIcon
                        onClick={() => handleRemove(id)}
                        className="w-5 h-5"
                      />
                    </span>
                  </span>

                  <span>
                    <Popover
                      trigger="click"
                      content={
                        <Input
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="w-48"
                          value={altText ?? ''}
                          onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            handleInputChange(id, 'altText', e.target.value);
                          }}
                          suffix={
                            <FaCheck
                              onClick={(e) => {
                                e.stopPropagation();
                                setVisible(!visible);
                              }}
                              className={`${
                                !altText?.length
                                  ? 'text-textLiteGray'
                                  : 'text-green-400'
                              }  cursor-pointer hover:text-green-600`}
                            />
                          }
                          placeholder="Add alt text"
                        />
                      }
                      open={visible}
                      onOpenChange={handleVisibleChange}
                      getPopupContainer={(triggerNode) => triggerNode}
                      ref={popoverRef}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisible(!visible);
                        }}
                        type="text"
                        className="text-sm font-medium"
                      >
                        Alt Text
                      </Button>
                    </Popover>
                  </span>
                  <span className="flex w-full">
                    <Popover
                      trigger="click"
                      content={
                        <Input
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          value={visibleLinkText ?? ''}
                          className="w-48"
                          onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            handleInputChange(id, 'link', e.target.value);
                          }}
                          suffix={
                            <FaCheck
                              onClick={(e) => {
                                e.stopPropagation();
                                setVisibleLink(!visibleLink);
                              }}
                              className={`${
                                !visibleLinkText?.length
                                  ? 'text-textLiteGray'
                                  : 'text-green-400'
                              }  cursor-pointer hover:text-green-600`}
                            />
                          }
                          placeholder="Add Link"
                        />
                      }
                      open={visibleLink}
                      onOpenChange={handleVisibleChangeLink}
                      getPopupContainer={(triggerNode) => triggerNode}
                      ref={popoverLinkRef}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleLink(!visibleLink);
                        }}
                        type="text"
                        className="text-sm font-medium"
                      >
                        Link
                      </Button>
                    </Popover>
                  </span>
                </div>
              </div>
              <div>
                <img
                  src={`${process.env.REACT_APP_URL_IMAGE_BASE_URL}/${imageUrl}`}
                  className="min-h-40 rounded-md"
                  alt={altText ?? ''}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className="flex relative justify-center items-center cursor-pointer group text-gray-700 transition-all duration-400 ease-in-out bg-[#F6F6F9] hover:bg-[#eeeef1] py-20 rounded-2xl"
          onClick={handleButtonClick}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label className="cursor-pointer gap-y-2 flex flex-col ">
            <div className="absolute right-4 top-4">
              <div className="flex justify-center flex-col gap-y-2">
                <span className="flex justify-center cursor-pointer bg-grayBg  bg-opacity-40    transition-transform transform hover:scale-110 rounded-full p-1">
                  <XIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleRemove(id);
                    }}
                    className="w-5 h-5"
                  />
                </span>
                <span>
                  <Popover
                    trigger="click"
                    content={
                      <Input
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="w-48"
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          handleInputChange(id, 'altText', e.target.value);
                        }}
                        suffix={
                          <FaCheck
                            onClick={(e) => {
                              e.stopPropagation();
                              setVisible(!visible);
                            }}
                            className={`${
                              !altText?.length
                                ? 'text-textLiteGray'
                                : 'text-green-400'
                            }  cursor-pointer hover:text-green-600`}
                          />
                        }
                        placeholder="Add alt text"
                      />
                    }
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    getPopupContainer={(triggerNode) => triggerNode}
                    ref={popoverRef}
                  >
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisible(!visible);
                      }}
                      type="text"
                      className="text-sm font-medium"
                    >
                      Alt Text
                    </Button>
                  </Popover>
                </span>
                <span className="flex w-full justify-end">
                  <Popover
                    trigger="click"
                    content={
                      <Input
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        value={visibleLinkText ?? ''}
                        className="w-48"
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          handleInputChange(id, 'link', e.target.value);
                        }}
                        suffix={
                          <FaCheck
                            onClick={(e) => {
                              e.stopPropagation();
                              setVisibleLink(!visibleLink);
                            }}
                            className={`${
                              !visibleLinkText?.length
                                ? 'text-textLiteGray'
                                : 'text-green-400'
                            }  cursor-pointer hover:text-green-600`}
                          />
                        }
                        placeholder="Add Link"
                      />
                    }
                    open={visibleLink}
                    onOpenChange={handleVisibleChangeLink}
                    getPopupContainer={(triggerNode) => triggerNode}
                    ref={popoverLinkRef}
                  >
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisibleLink(!visibleLink);
                      }}
                      type="text"
                      className="text-sm font-medium"
                    >
                      Link
                    </Button>
                  </Popover>
                </span>
              </div>
            </div>

            <div className="flex justify-center ">
              <p className="ant-upload-drag-icon flex bg-white rounded-full p-3 justify-center cursor-pointer">
                <UploadIcon className="w-7 h-7 text-[#C3C3C3]" />
              </p>
            </div>
            <p className="ant-upload-text cursor-pointer text-[#C3C3C3] group-hover:text-[#b7b7b7]">
              Upload cover image
            </p>
          </label>
          <input
            key={fileKey}
            type="file"
            id="fileInput"
            ref={fileInputRef}
            className="cursor-pointer"
            accept=".jpg, .png, .jpeg"
            style={{ display: 'none' }}
            onChange={(e) =>
              e.target.files && handleFileChange(e.target.files[0] as File)
            }
          />
        </div>
      )}
    </>
  );
};

export default UploadImage;
