import baseForPdf from './baseForPdf';
import { Endpoints } from './httpendpoints';

export const getAllShortBookListApi = async ({
  author,
  limit,
  search,
  isAdmin,
}: {
  author: string;
  limit: number;
  search?: string;
  isAdmin?: boolean;
}) => {
  const queryParams = new URLSearchParams({
    ...(!isAdmin ? { limit: limit?.toString() } : { limit: '500' }),
    ...(search && { title: search }),
    ...(author && isAdmin && { author: author }),
  });
  let urlWithQuery: string;
  if (!isAdmin) {
    urlWithQuery = `${Endpoints.getAllShortBooks}/author?${queryParams}`;
  } else {
    urlWithQuery = `${Endpoints.getAllShortBooks}?${queryParams}`;
  }

  const response = await baseForPdf.get(urlWithQuery);
  return response?.data?.data;
};

export const getAllLongBookListApi = async ({
  author,
  limit,
  search,
  isAdmin,
}: {
  author: string;
  limit: number;
  search?: string;
  isAdmin?: boolean;
}) => {
  const queryParams = new URLSearchParams({
    ...(!isAdmin ? { limit: limit?.toString() } : { limit: '500' }),
    ...(search && { title: search }),
    ...(author && isAdmin && { author: author }),
  });
  let urlWithQuery: string;
  if (!isAdmin) {
    urlWithQuery = `${Endpoints.getAllBooks}/author?${queryParams}`;
  } else {
    urlWithQuery = `${Endpoints.getAllBooks}?${queryParams}`;
  }

  const response = await baseForPdf.get(urlWithQuery);
  return response?.data?.data;
};
