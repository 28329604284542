import React, { useEffect, useState } from 'react';
import Loader from '../../../components/ui-elements/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSingleWorkShopsApi,
  syncSessionApi,
} from '../../../services/liveServices';

import { FormDataI } from '../../../components/Live/CreateLiveForm';
import { Button, Dropdown, Empty, MenuProps, Spin } from 'antd';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { PiDotsThreeOutlineFill } from 'react-icons/pi';
import { TbPencilMinus } from 'react-icons/tb';
import { MdDeleteOutline } from 'react-icons/md';
import DeleteModal from '../DeleteModal';
import moment from 'moment';
import { ShouldRender } from '../../../components/basic/ShouldRender';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { LuClock2 } from 'react-icons/lu';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LiveAddModal from '../../../components/Live/LiveAddModal';
import { RiAdvertisementLine } from 'react-icons/ri';
import { CheckPermission } from '../../../components/basic/CheckPermission';
import { notify } from '../../../components/basic/notity';

dayjs.extend(customParseFormat);

const LiveDetailsView = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState({ id: '', name: '' });
  const [advertisementId, setAdvertisementId] = useState('');
  const [syncLoading, setSyncLoading] = useState({ id: '', value: false });

  const navigate = useNavigate();
  const [workShopData, setWorkShopData] = useState<FormDataI>();
  const [visibleSession, setVisibleSession] = useState({
    name: '',
    value: false,
    id: '',
  });
  const [visibleAdd, setVisibleAdd] = useState({
    name: '',
    value: false,
    sessionId: '',
    advertisementId: '',
  });
  const [visible, setVisible] = useState({ name: '', value: false, id: '' });
  const formatData = (obj: any) => {
    return {
      authorname: obj?.author_name,
      livetitle: obj?.title,
      livedescription: obj?.desc,
      learningtopic: obj?.learn_topics || [''],
      livebanner: obj?.live_workshop_banner
        ? obj?.live_workshop_banner?.original_url
        : null,
      channelname: obj?.channel,
      banner_phone: obj?.banner_phone ? obj?.banner_phone?.original_url : null,
      liveIncludes: obj?.key_points || [''],
      sessions: obj?.sessions.map((item: any) => {
        return {
          desc: item?.desc,
          end_date: item?.end_date,
          end_time: item?.end_time,
          id: item?.id,
          start_date: item?.start_date,
          start_time: item?.start_time,
          status: item?.status,
          thumbnail: item?.live_workshop_session_thumbnails?.original_url,
          title: item?.title,
          advertisementId:
            item?.advertisements?.[item?.advertisements?.length - 1]
              ?.advertisement_id,
        };
      }),
    };
  };

  const getSingleWorkShopsData = async (id: string) => {
    try {
      setLoading(true);
      const response = await getSingleWorkShopsApi(id);
      const formattedData = formatData(response?.data?.data);
      setWorkShopData(formattedData);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSync = async (id: string) => {
    try {
      setSyncLoading({ id: id, value: true });
      await syncSessionApi(id);
      notify('Session synced successfully!');
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setSyncLoading({ id: '', value: false });
    }
  };

  useEffect(() => {
    if (id) {
      getSingleWorkShopsData(id);
      if (!visibleAdd?.value) {
        getSingleWorkShopsData(id);
      }
    }
  }, [id, visibleAdd?.value]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <CheckPermission check="edit-live-workshops">
          <div className="flex relative gap-x-2 items-center text-sm font-medium py-2 ">
            <TbPencilMinus className="text-black text-lg" />
            Edit Live
            <span className="border-b absolute -bottom-1.5 border-[#f2f0f0] py-4 w-full" />
          </div>
        </CheckPermission>
      ),
      onClick: () => navigate(`/live-session/edit-session/${id}`),
    },
    {
      key: '2',
      label: (
        <CheckPermission check="delete-live-workshops">
          <div className="flex gap-x-2 relative items-center text-sm font-medium py-2">
            <MdDeleteOutline className="text-black  text-lg" />
            Delete Live
          </div>
        </CheckPermission>
      ),
      onClick: () =>
        setVisible({
          value: true,
          id: id as string,
          name: workShopData?.livetitle as string,
        }),
    },
  ];

  const itemSession: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <CheckPermission check="edit-live-workshop-sessions">
          <div className="flex gap-x-2 relative items-center text-sm font-medium py-2">
            <RiAdvertisementLine className="text-black  text-lg" />
            Activate Ad
          </div>
        </CheckPermission>
      ),
      onClick: () => {
        setVisibleAdd({
          value: true,
          sessionId: session?.id as string,
          name: session?.name as string,
          advertisementId: advertisementId as string,
        });
      },
    },
    {
      key: '2',
      label: (
        <CheckPermission check="delete-live-workshop-sessions">
          <div className="flex gap-x-2 relative items-center text-sm font-medium py-2">
            <MdDeleteOutline className="text-black  text-lg" />
            Delete Session
          </div>
        </CheckPermission>
      ),
      onClick: () =>
        setVisibleSession({
          value: true,
          id: session?.id as string,
          name: session?.name as string,
        }),
    },
  ];

  return (
    <>
      <CheckPermission check="manage-live-workshops">
        <div className="flex flex-col ">
          <div className="flex sticky top-0 w-full bg-white z-20  flex-row justify-between gap-4 pl-9 pb-3 pt-3 border-y border-[#e2e2e2]">
            <div className="flex flex-row items-center gap-6 z-">
              <Button
                onClick={() => navigate(`/live-session`)}
                size="large"
                className="rounded-xl cursor-pointer group"
              >
                <p className="flex gap-2 items-center">
                  <ChevronLeftIcon className="w-4 text-[#afaeae] group-hover:text-primary font-bold" />
                  Back
                </p>
              </Button>
              <div className="border-l  h-10 text-[#e2e2e2]  " />
            </div>
            <div className="flex items-center pr-10 z-40">
              <button
                type="button"
                onClick={() => navigate(`/create-session/${id}`)}
                className="rounded-xl text-base font-bold  bg-[#FFF3F0] px-8 py-3 text-primary "
              >
                <b className="font-medium text-sm">Create Session</b>
              </button>
            </div>
          </div>
          <ShouldRender check={workShopData && !loading}>
            <div className="flex justify-between px-12  gap-2 py-5 ">
              <div className="flex gap-x-8 w-full">
                <div className="w-2/5 xl:w-1/5 mt-2">
                  <img
                    className="w-[250px] h-[120px]  xl:w-[316px] xl:h-[176px]  rounded-lg "
                    src={workShopData?.livebanner}
                    alt="livebanner"
                  />
                </div>
                <div className="flex w-3/5 justify-between xl:w-4/5 pr-5 xl:pr-10 flex-col gap-4">
                  <div className="py-2">
                    <div className="text-2xl font-medium capitalize">
                      {workShopData?.livetitle}
                    </div>

                    <span className="w-full text-base">
                      {workShopData?.livedescription}{' '}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex mt-4 mr-10">
                  <Dropdown
                    trigger={['click']}
                    menu={{ items }}
                    placement="bottomLeft"
                  >
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className="border border-lightgray rounded-full p-2"
                    >
                      <PiDotsThreeOutlineFill className="text-sm" />
                    </button>
                  </Dropdown>
                </div>
              </div>
            </div>
          </ShouldRender>

          <div className=" flex flex-col mt-8">
            <ShouldRender check={!workShopData?.sessions?.length && !loading}>
              <span className="text-xl font-medium px-12">Sessions</span>
              <span className="border-b   border-[#f2f0f0] py-2 w-full" />
              <span className="py-20">
                <Empty
                  description={
                    <div className="flex flex-col gap-y-2">
                      <span className="text-xl font-medium">
                        Create your Live curriculum
                      </span>
                      <span className="font-medium text-base text-[#9c9b9b]">
                        Create Sessions of your live{' '}
                      </span>
                      <span className="mt-2 ">
                        <button
                          type="button"
                          onClick={() => navigate(`/create-session/${id}`)}
                          className="rounded-xl text-base font-bold  bg-[#FFF3F0] px-12 py-3 text-primary "
                        >
                          <b className="font-medium text-sm">Create Session</b>
                        </button>
                      </span>
                    </div>
                  }
                />
              </span>
            </ShouldRender>
          </div>
          <CheckPermission check="manage-live-workshop-sessions">
            <ShouldRender check={workShopData?.sessions?.length && !loading}>
              <span className="text-xl font-medium px-12">Sessions</span>
              <span className="border-b   border-[#f2f0f0] py-2 w-full" />
              <div>
                {workShopData?.sessions?.map((item, index: number) => (
                  <div
                    key={index}
                    className="group px-12 border-b border-[#EAEAEA] hover:bg-[#FFF3F0] py-2 active-btn"
                  >
                    <div className="flex flex-row items-center ">
                      <div className="w-full p-4">
                        <div className="flex flex-row gap-10 items-center">
                          <div>
                            {' '}
                            <div className="w-[100px] h-[55px]   mt-2">
                              <img
                                className="w-full h-full  rounded-lg "
                                src={item?.thumbnail}
                                alt="livebanner"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-x-5 text-[#B4B4B4]">
                              <span className=" font-medium flex items-center gap-x-2">
                                <LuClock2 />{' '}
                                {moment(item?.start_time, 'HH:mm:ss').format(
                                  'h:mm A'
                                )}{' '}
                                -{' '}
                                {moment(item?.end_time, 'HH:mm:ss').format(
                                  'h:mm A'
                                )}
                              </span>
                              <span className=" font-medium flex items-center gap-x-2">
                                <IoCalendarClearOutline />{' '}
                                {moment(item?.start_date).format('DD MMM')} -{' '}
                                {moment(item?.end_date).format('DD MMM')}
                              </span>
                              <ShouldRender check={item?.advertisementId}>
                                <span>
                                  <RiAdvertisementLine className=" text-lg" />
                                </span>
                              </ShouldRender>
                            </div>
                            <div className="hover:cursor-pointer text-lg font-medium ">
                              {index + 1}. {item.title}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex justify-end p-4 gap-10 items-center">
                        <div className="group flex gap-x-2">
                          <CheckPermission check="edit-live-workshop-sessions">
                            <button
                              disabled={
                                item?.id.toString() === syncLoading?.id &&
                                syncLoading.value
                              }
                              onClick={() => handleSync(item?.id.toString())}
                              className="px-12 disabled:bg-grayBg border-primary border font-medium rounded-full py-2 text-black opacity-0 group-hover:opacity-100 transition-opacity"
                            >
                              <span className="flex justify-center items-center gap-x-2">
                                {item?.id.toString() === syncLoading?.id &&
                                  syncLoading.value && (
                                    <Spin size="small" />
                                  )}{' '}
                                Sync
                              </span>
                            </button>
                            <button
                              onClick={() =>
                                navigate(`/create-session/${id}/${item?.id}`)
                              }
                              className="px-12 bg-primary rounded-full py-2 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                            >
                              Edit
                            </button>
                          </CheckPermission>
                        </div>

                        <div className="flex mr-5 ">
                          <Dropdown
                            trigger={['click']}
                            menu={{ items: itemSession }}
                            placement="bottomLeft"
                          >
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSession({
                                  id: String(item?.id),
                                  name: item?.title,
                                });
                                setAdvertisementId(
                                  item?.advertisementId as string
                                );
                              }}
                              className="border border-lightgray rounded-full p-2"
                            >
                              <PiDotsThreeOutlineFill className="text-sm" />
                            </button>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </ShouldRender>
          </CheckPermission>
        </div>
      </CheckPermission>

      <DeleteModal
        visible={visible}
        closeModal={() => setVisible({ name: '', value: false, id: '' })}
      />

      <DeleteModal
        visible={visibleSession}
        closeModal={() => setVisibleSession({ name: '', value: false, id: '' })}
        isSession={true}
      />
      <LiveAddModal
        visible={visibleAdd}
        closeModal={() =>
          setVisibleAdd({
            value: false,
            sessionId: '',
            name: '',
            advertisementId: '',
          })
        }
      />

      <ShouldRender check={loading}>
        <Loader />
      </ShouldRender>
    </>
  );
};

export default LiveDetailsView;
