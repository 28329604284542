import { Endpoints } from './httpendpoints';
import apiClient from './httpservices';

interface createWorkShopI {
  author_name: string;
  title: string;
  desc: string;
  learningtopic: string[];
  banner: any;
  banner_phone: any;
  channelname: string;
  liveIncludes: string[];
}

interface createSessionI {
  title: string;
  desc: string;
  yt_video_id: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  live_workshop_id: string;
  thumbnail: string;
  is_active: number;
  completed: number;
  _method?: string;
}

export interface advertisementsI {
  category: string | null;
  title: string;
  url: string;
  thumbnail: any;
  _method?: string;
}

export interface assignAdvertisementsI {
  module_id: string;
  advertisement_id: string;
  active: number;
}

export const getAllWorkShopsApi = async (
  status?: 'Approved' | 'Pending' | 'Completed'
): Promise<any> => {
  let endpoint = Endpoints.getAllWorkShops;
  if (status) {
    endpoint += `?status=${status}`;
  }
  const response = await apiClient().get(endpoint);
  return response;
};

export const getSingleWorkShopsApi = async (id: string): Promise<any> => {
  const response = await apiClient().get(
    `${Endpoints.getSingleWorkShops}/${id}`
  );
  return response;
};

export const deleteWorkShopsApi = async (id: string): Promise<any> => {
  const response = await apiClient().delete(
    `${Endpoints.getSingleWorkShops}/${id}`
  );
  return response;
};

export const deleteSessionApi = async (id: string): Promise<any> => {
  const response = await apiClient().delete(`${Endpoints.createSession}/${id}`);
  return response;
};

export const updateWorkShopStatus = async (
  id: number,
  status: string
): Promise<any> => {
  const response = await apiClient().post(
    `${Endpoints.updateWorkShopStatus}/${id}`,
    { status }
  );
  return response;
};

export const createWorkShop = async (body: createWorkShopI): Promise<any> => {
  const parsedBody = {
    author_name: body.author_name || '', // Assuming author_name is a required field
    banner: body.banner || {},
    banner_phone: body.banner_phone,
    channel: body.channelname || '',
    desc: body.desc || '',
    'learn_topics[]': body.learningtopic || [],
    'key_points[]': body.liveIncludes || [],
    title: body.title || '',
  };

  const response = await apiClient().post(
    `${Endpoints.addWorkShop}`,
    parsedBody
  );
  return response;
};

export const updateWorkShop = async (
  body: createWorkShopI,
  id: string
): Promise<any> => {
  const parsedBody: any = {
    author_name: body.author_name || '',
    channel: body.channelname || '',
    desc: body.desc || '',
    'learn_topics[]': body.learningtopic || [],
    'key_points[]': body.liveIncludes || [],
    title: body.title || '',
    _method: 'patch',
  };

  if (typeof body.banner !== 'string') {
    parsedBody.banner = body.banner || {};
  }

  if (typeof body.banner_phone !== 'string') {
    parsedBody.banner_phone = body.banner_phone || {};
  }

  const response = await apiClient().post(
    `${Endpoints.updateWorkShop}/${id}`,
    parsedBody
  );
  return response;
};

export const createSession = async (body: createSessionI): Promise<any> => {
  const response = await apiClient().post(`${Endpoints.createSession}`, body);
  return response;
};

export const updateSession = async (
  body: createSessionI,
  id: string
): Promise<any> => {
  const response = await apiClient().post(
    `${Endpoints.createSession}/${id}`,
    body
  );
  return response;
};

export const getSingleSessionApi = async (id: string): Promise<any> => {
  const response = await apiClient().get(`${Endpoints.createSession}/${id}`);
  return response;
};

export const createSessionAdvertisementsApi = async (
  body: advertisementsI
): Promise<any> => {
  const response = await apiClient().post(
    `${Endpoints.createSessionAdvertisements}`,
    body
  );
  return response;
};

export const updateSessionAdvertisementsApi = async (
  body: advertisementsI,
  id: string
): Promise<any> => {
  const endpoint = `${Endpoints.createSessionAdvertisements}/${id}`;

  const requestBody = { ...body };

  if (typeof requestBody.thumbnail === 'string') {
    delete requestBody.thumbnail;
  }

  const response = await apiClient().post(endpoint, requestBody);

  return response;
};

export const assignAdvertisementsApi = async (
  body: assignAdvertisementsI
): Promise<any> => {
  const response = await apiClient().post(
    `${Endpoints.assignAdvertisements}`,
    body
  );
  return response;
};

export const getSingleAdvertisementApi = async (id: string): Promise<any> => {
  const response = await apiClient().get(
    `${Endpoints.createSessionAdvertisements}/${id}`
  );
  return response;
};

export const syncSessionApi = async (id: string): Promise<any> => {
  const response = await apiClient().get(`${Endpoints.syncSession}/${id}`);
  return response;
};
