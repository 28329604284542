// dashboard card images

import { BLOG_ICON_1, BLOG_ICON_2, BLOG_ICON_3, BLOG_ICON_4 } from '../assets';

//dashboard card data

export const dashboardCardData = [
  {
    id: 1,
    image: BLOG_ICON_1,
    cardTitle: 'Bulk Email',
    cardSubTitle: 'Send 1 million mail in one go.',
  },
  {
    id: 2,
    image: BLOG_ICON_4,
    cardTitle: 'School',
    cardSubTitle: 'Send 1 million mail in one go.',
  },
  {
    id: 3,
    image: BLOG_ICON_3,
    cardTitle: 'Newsletter',
    cardSubTitle: 'Send 1 million mail in one go.',
  },
  {
    id: 4,
    image: BLOG_ICON_2,
    cardTitle: 'Live Session',
    cardSubTitle: 'Send 1 million mail in one go.',
  },
];

//blog list data
//   export const cardData = [
//     {
//       id: 1,
//       card_image: CARDIMAGE_1,
//       blog_name: 'Finance 101',
//       blog_count: 20,
//     },
//     {
//       id: 2,
//       card_image: CARDIMAGE_2,
//       blog_name: 'Finance 101',
//       blog_count: 20,
//     },
//     {
//       id: 3,
//       card_image: CARDIMAGE_3,
//       blog_name: 'Finance 101',
//       blog_count: 20,
//     },
//     {
//       id: 4,
//       card_image: CARDIMAGE_4,
//       blog_name: 'Finance 101',
//       blog_count: 20,
//     },
//     {
//       id: 5,
//       card_image: CARDIMAGE_5,
//       blog_name: 'Finance 101',
//       blog_count: 20,
//     },
//   ];

// modal circle data

interface modalTagCicleDataProp {
  id: any;
  name: any;
}
export const modalTagCicleData: modalTagCicleDataProp[] = [
  {
    id: 1,
    name: 'Finance',
  },
  {
    id: 2,
    name: 'Marketing',
  },
  {
    id: 3,
    name: 'Trading',
  },
  {
    id: 4,
    name: 'Investment',
  },
  {
    id: 5,
    name: 'school',
  },
  {
    id: 6,
    name: 'Sales',
  },
  {
    id: 7,
    name: 'Field Sales',
  },
  {
    id: 8,
    name: '',
  },
  {
    id: 9,
    name: 'Accounts and',
  },
  {
    id: 10,
    name: 'Digital',
  },
];

export const blogToolsData = [
  {
    id: 1,
    title: 'Start Advertisements',
    description:
      "Start Advertisements The Beginning of Infinity: Deutsch's central philosophical theory is encapsulated in his book that there is no limit to human knowledge, and that through a process Activated Activate",
  },
  {
    id: 2,
    title: 'Another Advertisement',
    description:
      "The Beginning of Infinity: Deutsch's central philosophical theory is encapsulated in his book that there is no limit to human knowledge, and that through a process Activated",
  },
  {
    id: 3,
    title: 'Affiliate marketing links',
    description:
      "The Beginning of Infinity: Deutsch's central philosophical theory is encapsulated in his book that there is no limit to human knowledge, and that through a process Activated",
  },
  {
    id: 4,
    title: 'Sponsored posts',
    description:
      "The Beginning of Infinity: Deutsch's central philosophical theory is encapsulated in his book that there is no limit to human knowledge, and that through a process Activated",
  },
  {
    id: 5,
    title: 'Affiliate marketing link',
    description:
      "The Beginning of Infinity: Deutsch's central philosophical theory is encapsulated in his book that there is no limit to human knowledge, and that through a process Activated",
  },
  {
    id: 6,
    title: 'Sponsored posts',
    description:
      "The Beginning of Infinity: Deutsch's central philosophical theory is encapsulated in his book that there is no limit to human knowledge, and that through a process Activated",
  },

  // Add more data entries as needed
];

export const letterColors = {
  A: '#FF5733',
  B: '#33FF57',
  C: '#5733FF',
  D: '#FF33F6',
  E: '#33F6FF',
  F: '#FF5733',
  G: '#33FF57',
  H: '#5733FF',
  I: '#FF33F6',
  J: '#33F6FF',
  K: '#FF5733',
  L: '#33FF57',
  M: '#5733FF',
  N: '#FF33F6',
  O: '#33F6FF',
  P: '#FF5733',
  Q: '#33FF57',
  R: '#5733FF',
  S: '#FF33F6',
  T: '#33F6FF',
  U: '#FF5733',
  V: '#33FF57',
  W: '#5733FF',
  X: '#FF33F6',
  Y: '#33F6FF',
  Z: '#FF5733',
};

export const countryList = [
  { name: 'United States', code: '+1' },
  { name: 'United Kingdom', code: '+44' },
  { name: 'Canada', code: '+1' },
  { name: 'Australia', code: '+61' },
  { name: 'India', code: '+91' },
  { name: 'Germany', code: '+49' },
  { name: 'France', code: '+33' },
  { name: 'Japan', code: '+81' },
  { name: 'Brazil', code: '+55' },
  { name: 'China', code: '+86' },
  { name: 'Russia', code: '+7' },
  { name: 'South Korea', code: '+82' },
  { name: 'Mexico', code: '+52' },
  { name: 'South Africa', code: '+27' },
  { name: 'Nigeria', code: '+234' },
  { name: 'Spain', code: '+34' },
  { name: 'Italy', code: '+39' },
  { name: 'Sweden', code: '+46' },
  { name: 'Norway', code: '+47' },
  { name: 'Switzerland', code: '+41' },
  { name: 'New Zealand', code: '+64' },
  { name: 'Greece', code: '+30' },
  { name: 'Argentina', code: '+54' },
  { name: 'Turkey', code: '+90' },
  { name: 'Egypt', code: '+20' },
  { name: 'Singapore', code: '+65' },
  { name: 'Malaysia', code: '+60' },
  { name: 'Thailand', code: '+66' },
  { name: 'Vietnam', code: '+84' },
  { name: 'Philippines', code: '+63' },
];

//  Static Data for messaging
export const schoolByAuthor = [
  {
    id: 1,
    created_at: '2023-12-15T09:34:16.000000Z',
    updated_at: '2024-04-22T12:12:42.000000Z',
    deleted_at: null,
    created_by: 5,
    name: 'How to Choose the Right Life Insurance Policy',
    description:
      "Life insurance policies provide financial protection to beneficiaries in case of the insured's death. They offer peace of mind by ensuring loved ones are financially secure. Policies can also include investment features, tax benefits, and options for coverage customization, making them a crucial aspect of long-term financial planning.",
    is_monetized: 0,
    title: 'Seo Test uuu',
    meta_description: 'null',
    keywords: null,
    author: null,
    viewport: null,
    robots: null,
    canonical: null,
    slug: 'how-to-choose-the-right-life-insurance-policy',
    posts_count: 10,
    documents: {
      id: 1,
      parent_id: 1,
      type: 'SCHOOL',
      sub_type: null,
      file_path:
        'schools/cover_image/1/uoYjFjYhPDIFCv9UrtrxpS3vOz6Bl4iIsNWLuT7f.png',
      created_at: '2023-12-15T09:34:17.000000Z',
      updated_at: '2024-04-02T13:22:34.000000Z',
      deleted_at: null,
      name: null,
    },
    advertisements: [
      {
        id: 2,
        name: 'Be money smart',
        school_id: 1,
        url: 'https://go.zebralearn.com/money-smart?_gl=1*5rgpb7*_ga*MTA0NzkxMzQyLjE2OTI0Mjc4MDE.*_ga_21G196HESG*MTcwNjI1NzAyMS4zNy4xLjE3MDYyNTcwMjIuNTkuMC4w',
        type: 'SCHOOL_ADVERTISEMENT',
        desc: null,
        active: true,
        created_at: '2023-12-27T07:40:30.000000Z',
        updated_at: '2024-04-02T10:42:42.000000Z',
        deleted_at: null,
        documents: [
          {
            id: 15,
            parent_id: 2,
            type: 'SCHOOL_ADVERTISEMENT',
            sub_type: 'LARGE',
            file_path: 'schools/2/ads_image/1 (2).png',
            created_at: '2023-12-27T07:40:31.000000Z',
            updated_at: '2024-04-02T10:42:42.000000Z',
            deleted_at: null,
            name: null,
          },
          {
            id: 16,
            parent_id: 2,
            type: 'SCHOOL_ADVERTISEMENT',
            sub_type: 'SMALL',
            file_path:
              'schools/ads_image/2/xrItLx5TdtN6cOIvl3cxBw552cp9ZXPxXWWKhtUA.png',
            created_at: '2023-12-27T07:40:31.000000Z',
            updated_at: '2023-12-27T07:40:31.000000Z',
            deleted_at: null,
            name: null,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    created_at: '2023-12-21T10:40:21.000000Z',
    updated_at: '2024-04-11T11:36:46.000000Z',
    deleted_at: null,
    created_by: 5,
    name: 'The ABCs of Mutual Fund Investing',
    description:
      'In this school, we will embark on a journey through the diverse landscape of mutual funds. Our exploration will encompass various types and offer a comprehensive understanding of the subject. The curriculum will cover essential factors to consider when engaging in mutual fund investments, insights into the mutual fund redemption process, and a detailed examination of related concepts. Join us in navigating the intricacies of mutual fund investments and empowering your financial decisions.',
    is_monetized: 0,
    title: 'Mutual Funds Investment Guide | ZebraLearn Test',
    meta_description:
      'Discover expert insights into mutual funds investment, fund management, and investment strategies at ZebraLearn. Start building your wealth today!',
    keywords:
      'Mutual funds, Investment funds, Fund management, Mutual fund performance, Types of mutual funds',
    author: 'ZebraLearn',
    viewport: null,
    robots: null,
    canonical: null,
    slug: 'the-abcs-of-mutual-fund-investing-1',
    posts_count: 9,
    documents: {
      id: 14,
      parent_id: 12,
      type: 'SCHOOL',
      sub_type: null,
      file_path:
        'schools/cover_image/12/52k1yLDoQtAgg8uuOrWniufvGeMfQ0caYfzgvtEL.png',
      created_at: '2023-12-21T10:40:21.000000Z',
      updated_at: '2024-04-02T13:23:04.000000Z',
      deleted_at: null,
      name: null,
    },
    advertisements: [
      {
        id: 4,
        name: 'MONEY SMART',
        school_id: 12,
        url: 'https://go.zebralearn.com/money-smart?_gl=1*5rgpb7*_ga*MTA0NzkxMzQyLjE2OTI0Mjc4MDE.*_ga_21G196HESG*MTcwNjI1NzAyMS4zNy4xLjE3MDYyNTcwMjIuNTkuMC4w',
        type: 'SCHOOL_ADVERTISEMENT',
        desc: null,
        active: true,
        created_at: '2024-02-22T06:34:24.000000Z',
        updated_at: '2024-04-02T10:34:29.000000Z',
        deleted_at: null,
        documents: [
          {
            id: 40,
            parent_id: 4,
            type: 'SCHOOL_ADVERTISEMENT',
            sub_type: 'LARGE',
            file_path: 'schools/4/ads_image/2.png',
            created_at: '2024-02-22T06:34:25.000000Z',
            updated_at: '2024-04-02T10:34:29.000000Z',
            deleted_at: null,
            name: null,
          },
          {
            id: 41,
            parent_id: 4,
            type: 'SCHOOL_ADVERTISEMENT',
            sub_type: 'SMALL',
            file_path:
              'schools/ads_image/4/fp3iUeToj2OgJxaFtfBplcmDeuj3Aa9Nf3HeM4C7.jpg',
            created_at: '2024-02-22T06:34:25.000000Z',
            updated_at: '2024-02-22T06:34:25.000000Z',
            deleted_at: null,
            name: null,
          },
        ],
      },
    ],
  },
];
